"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CIS2Contract = void 0;
const types_1 = require("../types");
const signHelpers_1 = require("../signHelpers");
const util_1 = require("./util");
const accountAddress_1 = require("../types/accountAddress");
const ccdAmount_1 = require("../types/ccdAmount");
const transactionExpiry_1 = require("../types/transactionExpiry");
const json_bigint_1 = require("json-bigint");
const cis0_1 = require("../cis0");
const contractHelpers_1 = require("../contractHelpers");
const util_2 = require("../util");
const schemas = {
    /** Base64 encoded schema for CIS-2.transfer parameter */
    transfer: 'EAEUAAUAAAAIAAAAdG9rZW5faWQdAAYAAABhbW91bnQbJQAAAAQAAABmcm9tFQIAAAAHAAAAQWNjb3VudAEBAAAACwgAAABDb250cmFjdAEBAAAADAIAAAB0bxUCAAAABwAAAEFjY291bnQBAQAAAAsIAAAAQ29udHJhY3QBAgAAAAwWAQQAAABkYXRhHQE',
    /** Base64 encoded schema for CIS-2.updateOperator parameter */
    updateOperator: 'EAEUAAIAAAAGAAAAdXBkYXRlFQIAAAAGAAAAUmVtb3ZlAgMAAABBZGQCCAAAAG9wZXJhdG9yFQIAAAAHAAAAQWNjb3VudAEBAAAACwgAAABDb250cmFjdAEBAAAADA',
};
const getInvoker = (address) => (0, util_1.isContractAddress)(address) ? address : new accountAddress_1.AccountAddress(address);
const getDefaultExpiryDate = () => {
    const future5Minutes = Date.now() + 5 * 60 * 1000;
    return new Date(future5Minutes);
};
/**
 * Contains methods for performing dry-run invocations of update instructions on CIS-2 smart contracts.
 */
class CIS2DryRun {
    constructor(grpcClient, contractAddress, contractName) {
        this.grpcClient = grpcClient;
        this.contractAddress = contractAddress;
        this.contractName = contractName;
    }
    transfer(sender, transfers, blockHash) {
        return this.invokeMethod('transfer', getInvoker(sender), util_1.serializeCIS2Transfers, transfers, blockHash);
    }
    updateOperator(owner, updates, blockHash) {
        return this.invokeMethod('updateOperator', getInvoker(owner), util_1.serializeCIS2UpdateOperators, updates, blockHash);
    }
    /**
     * Helper function for invoking a contract function.
     *
     * @param {string} entrypoint - The name of the receive function to invoke.
     * @param {ContractAddress | AccountAddress} invoker - The address of the invoker.
     * @param {Function} serializer - A function for serializing the input to bytes.
     * @param {T | T[]} input - Input for for contract function.
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    invokeMethod(entrypoint, invoker, serializer, input, blockHash) {
        const parameter = (0, util_2.makeDynamicFunction)(serializer)(input);
        (0, contractHelpers_1.checkParameterLength)(parameter);
        return this.grpcClient.invokeContract({
            contract: this.contractAddress,
            parameter,
            invoker,
            method: `${this.contractName}.${entrypoint}`,
        }, blockHash);
    }
}
/**
 * Contains methods for performing operations on CIS-2 smart contracts.
 */
class CIS2Contract {
    constructor(grpcClient, contractAddress, contractName) {
        this.grpcClient = grpcClient;
        this.contractAddress = contractAddress;
        this.contractName = contractName;
        this.dryRunInstance = new CIS2DryRun(grpcClient, contractAddress, contractName);
    }
    /**
     * Creates a new `CIS2Contract` instance by querying the node for the necessary information through the supplied `grpcClient`.
     *
     * @param {ConcordiumNodeClient} grpcClient - The client used for contract invocations and updates.
     * @param {ContractAddress} contractAddress - Address of the contract instance.
     *
     * @throws If `InstanceInfo` could not be received for the contract, if the contract does not support the CIS-2 standard,
     * or if the contract name could not be parsed from the information received from the node.
     */
    static async create(grpcClient, contractAddress) {
        const instanceInfo = await grpcClient.getInstanceInfo(contractAddress);
        if (instanceInfo === undefined) {
            throw new Error(`Could not get contract instance info for contract at address ${(0, json_bigint_1.stringify)(contractAddress)}`);
        }
        const result = await (0, cis0_1.cis0Supports)(grpcClient, contractAddress, 'CIS-2');
        if (result?.type !== cis0_1.CIS0.SupportType.Support) {
            throw new Error(`The CIS-2 standard is not supported by the contract at address ${(0, json_bigint_1.stringify)(contractAddress)}`);
        }
        const contractName = (0, contractHelpers_1.getContractName)(instanceInfo);
        return new CIS2Contract(grpcClient, contractAddress, contractName);
    }
    createTransfer(metadata, transfers) {
        return this.createUpdateTransaction('transfer', util_1.serializeCIS2Transfers, util_1.formatCIS2Transfer, metadata, transfers);
    }
    transfer(metadata, transfers, signer) {
        const transaction = this.createTransfer(metadata, transfers);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    createUpdateOperator(metadata, updates) {
        return this.createUpdateTransaction('updateOperator', util_1.serializeCIS2UpdateOperators, util_1.formatCIS2UpdateOperator, metadata, updates);
    }
    updateOperator(metadata, updates, signer) {
        const transaction = this.createUpdateOperator(metadata, updates);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    async balanceOf(queries, blockHash) {
        return this.invokeView('balanceOf', util_1.serializeCIS2BalanceOfQueries, util_1.deserializeCIS2BalanceOfResponse, queries, blockHash);
    }
    operatorOf(queries, blockHash) {
        return this.invokeView('operatorOf', util_1.serializeCIS2OperatorOfQueries, util_1.deserializeCIS2OperatorOfResponse, queries, blockHash);
    }
    tokenMetadata(tokenIds, blockHash) {
        return this.invokeView('tokenMetadata', util_1.serializeCIS2TokenIds, util_1.deserializeCIS2TokenMetadataResponse, tokenIds, blockHash);
    }
    /**
     * A dry-run instance, providing access to methods for performing dry-run invocations of update instructions.
     */
    get dryRun() {
        return this.dryRunInstance;
    }
    /**
     * Helper function for sending update transactions.
     *
     * @param {string} entrypoint - The name of the receive function to invoke.
     * @param {Function} serializer - A function to serialize the `input` to bytes.
     * @param {Function} jsonFormatter - A function to format the `input` as JSON format serializable by the contract schema.
     * @param {CIS2.CreateTransactionMetadata} metadata - Metadata to be used for the transaction creation (with defaults).
     * @param {T | T[]} input - Input for for contract function.
     *
     * @throws If the query could not be invoked successfully.
     *
     * @returns {HexString} The transaction hash of the update transaction
     */
    createUpdateTransaction(entrypoint, serializer, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jsonFormatter, { amount = 0n, energy }, input) {
        const parameter = (0, util_2.makeDynamicFunction)(serializer)(input);
        (0, contractHelpers_1.checkParameterLength)(parameter);
        const jsonParameter = Array.isArray(input)
            ? input.map(jsonFormatter)
            : [jsonFormatter(input)];
        const payload = {
            amount: new ccdAmount_1.CcdAmount(amount),
            address: this.contractAddress,
            receiveName: `${this.contractName}.${entrypoint}`,
            maxContractExecutionEnergy: energy,
            message: parameter,
        };
        return {
            type: types_1.AccountTransactionType.Update,
            payload,
            parameter: {
                hex: parameter.toString('hex'),
                json: jsonParameter,
            },
            schema: {
                value: schemas[entrypoint],
                type: 'parameter',
            },
        };
    }
    /**
     * Helper function for sending update transactions.
     *
     * @param {CIS2.UpdateTransaction} transaction - The transaction to send.
     * @param {CIS2.TransactionMetadata} metadata - Metadata to be used for the transaction (with defaults).
     * @param {AccountSigner} signer - An object to use for signing the transaction.
     *
     * @throws If the query could not be invoked successfully.
     *
     * @returns {HexString} The transaction hash of the update transaction
     */
    async sendUpdateTransaction({ type, payload }, { senderAddress, expiry = getDefaultExpiryDate(), }, signer) {
        const sender = new accountAddress_1.AccountAddress(senderAddress);
        const { nonce } = await this.grpcClient.getNextAccountNonce(sender);
        const header = {
            expiry: new transactionExpiry_1.TransactionExpiry(expiry),
            nonce: nonce,
            sender,
        };
        const transaction = {
            type,
            header,
            payload,
        };
        const signature = await (0, signHelpers_1.signTransaction)(transaction, signer);
        return this.grpcClient.sendAccountTransaction(transaction, signature);
    }
    /**
     * Helper function for invoking a contract view function.
     *
     * @param {string} entrypoint - The name of the view function to invoke.
     * @param {Function} serializer - A function to serialize the `input` to bytes.
     * @param {Function} deserializer - A function to deserialize the value returned from the view invocation.
     * @param {T | T[]} input - Input for for contract function.
     *
     * @throws If the query could not be invoked successfully.
     *
     * @returns {HexString} The transaction hash of the update transaction
     */
    async invokeView(entrypoint, serializer, deserializer, input, blockHash) {
        const parameter = (0, util_2.makeDynamicFunction)(serializer)(input);
        (0, contractHelpers_1.checkParameterLength)(parameter);
        const response = await this.grpcClient.invokeContract({
            contract: this.contractAddress,
            parameter,
            method: `${this.contractName}.${entrypoint}`,
        }, blockHash);
        if (response === undefined ||
            response.tag === 'failure' ||
            response.returnValue === undefined) {
            throw new Error(`Failed to invoke view ${entrypoint} for contract at ${(0, json_bigint_1.stringify)(this.contractAddress)}${response.tag === 'failure' &&
                ` with error ${(0, json_bigint_1.stringify)(response.reason)}`}`);
        }
        const values = deserializer(response.returnValue);
        const isListInput = Array.isArray(input);
        const expectedValuesLength = isListInput ? input.length : 1;
        if (values.length !== expectedValuesLength) {
            throw new Error('Mismatch between length of queries in request and values in response.');
        }
        if (isListInput) {
            return values;
        }
        else {
            return values[0];
        }
    }
}
exports.CIS2Contract = CIS2Contract;

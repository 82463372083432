import detectEthereumProvider from "@metamask/detect-provider";
import MetamaskService from "../services/metamask.service";
import AlertUser from "../Alerts/userAlerts";
import Abi from "../assets/contracts/ManaPassportContractBuild.json";
import { ethers } from 'ethers';

const zkContractAddress = "0x44f3812d6b28145c7a6ad6e23df8bef3c039df28";

const getAssets = async (walletAddress) => {
    try {
        const provider = new ethers.providers.JsonRpcProvider(
            'https://mainnet.era.zksync.io' // Public RPC endpoint ZkSync
        );
        const contract = new ethers.Contract(zkContractAddress, Abi.abi, provider);
        const balance = await contract.balanceOf(walletAddress);
        const nftPromises = [];
        for (let i = 0; i < balance; i++) {
            nftPromises.push(contract.tokenOfOwnerByIndex(walletAddress, i));
        }
        const tokenIds = await Promise.all(nftPromises);
        // Fetch metadata for each token
        const metadataPromises = tokenIds.map(async (tokenId) => {
            try {
                const tokenURI = await contract.tokenURI(tokenId);
                const metadataResponse = await fetch(tokenURI);
                if (!metadataResponse.ok) {
                    throw new Error(`Failed to fetch metadata for token ${tokenId}: ${metadataResponse.statusText}`);
                }
                const rawContent = await metadataResponse.text();
                const metadata = JSON.parse(rawContent);
                metadata.id = parseInt(tokenId);
                return metadata;
            } catch (error) {
                console.error(`Error fetching metadata for token ${tokenId}:`, error);
                // Return a default metadata object in case of an error
                return {
                    name: `Token ${tokenId}`,
                    description: 'Metadata unavailable',
                };
            }
        });
        const metadata = await Promise.all(metadataPromises);
        return metadata;
    } catch (error) {
        console.error('Error fetching NFTs:', error);
        return [];
    }
};

const connectMM = async (isZk = false) => {
    disconnectMM();
    try {
        const providerMetamask = await detectEthereumProvider();
        if (providerMetamask) {
            const metamaskWallet = await MetamaskService.getWalletData(
                providerMetamask
            );
            if (metamaskWallet.chainId !== 324) {
                await MetamaskService.changeChainIdToZKSYNC(
                    providerMetamask
                );
            }
            return metamaskWallet.address
        } else {
            AlertUser.walletExtentionNotFound(
                "Metamask",
                "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=es"
            );
        }
    } catch (error) {
        console.log("Error connecting to MetaMask:", error);
    }
};

const disconnectMM = async () => {
    try {
        try {
            await window.ethereum.request({
                method: "wallet_revokePermissions",
                params: [
                    {
                        eth_accounts: {},
                    },
                ],
            });
        } catch (error) {
            console.error("Error revoking wallet permissions:", error);
        }
    } catch (error) {
        console.error("Error disconnecting from MetaMask:", error);
    }
};

const zkServices = {
    getAssets,
    connectMM,
}

export default zkServices;

import React from "react";
import "../../assets/styles/Settings-Chatpage.css";

const Active = ({
  allMyFriends,
  selectChat,
  currentChatSelected,
  showList,
  username,
}) => {
  const getImage = (user) => {
    let userToShow = "",
      image = "";
    //  convert object to array
    let arry = Object.keys(user.state.members);
    // get member different to the login one
    userToShow = arry.filter((val) => val !== username)[0];
    if (arry.length > 0) {
      image = user.state.members[userToShow].user.image;
      return image
        ? image
        : `https://getstream.io/random_svg/?name=${userToShow}`;
    } else {
      return "";
    }
  };
  const getName = (item) => {
    if (item.data.created_by) {
      if (item.data.created_by.id === username) {
        return item.data.name;
      } else {
        return item.data.created_by.id;
      }
    } else {
      return item.data.name;
    }
  };
  const isOnline = (item) => {
    let arry = Object.keys(item.state.members);
    // get member different to the login one
    if (arry.length > 0) {
      let userToShow = arry.filter((val) => val !== username)[0];
      return item.state.members[userToShow].user.online;
    } else return false;
  };
  const getMessage = (item) => {
    let lastMessage = item.state.messageSets[0].messages;
    if (lastMessage.length > 0) {
      return lastMessage[lastMessage.length - 1].message;
    } else {
      return "";
    }
  };
  const myFriends = () => {
    return (
      <>
        {allMyFriends.length > 0 ? (
          allMyFriends.map((MyFriends, index) => (
            <div
              tabIndex="1"
              className={`C-P-list-Item ${
                currentChatSelected === index ? "active" : ""
              }`}
              key={index}
              onClick={() => {
                selectChat(index, getImage(MyFriends));
              }}
            >
              <div className="C-P-list-Item-image-container  ">
                <img
                  alt="user"
                  className="C-P-list-Item-Image"
                  src={getImage(MyFriends)}
                />
              </div>

              <div className="C-P-list-Item-txt-container">
                <div className="C-P-list-Item-txt">
                  <div className="C-P-list-Item-tv">
                    {MyFriends.state?.unreadCount > 0 ? (
                      <div className="C-P-list-Item-circle">
                        {MyFriends.state?.unreadCount}
                      </div>
                    ) : null}

                    <div className="C-P-list-txt-title">
                      <div className="C-P-list-username">
                        {getName(MyFriends)}
                      </div>
                      {isOnline(MyFriends) ? (
                        <div className="C-P-list-oneline-txt">online</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="C-P-list-Item-description">
                    {getMessage(MyFriends)}
                  </div>
                </div>
              </div>
              <i className="far fa-comments H-custom-i"></i>
            </div>
          ))
        ) : (
          <div className="dont-have-friends">
            you don't have conversations yet
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="H-items-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="C-custom-left-title">Active Chat</div>
          <a
            data-bs-toggle="modal"
            href="#exampleModalToggle"
            role="button"
            onClick={showList}
          >
            <i
              className="fa fa-plus-square"
              style={{ color: "#f2f2f2", cursor: "pointer" }}
            />
          </a>
        </div>

        {/* <div className="  C-custom-left-container   ">
          <div className=" ST-rigt--input-container  ">
            <i className="fas fa-search  ST-i"></i>
            <input
              type="text"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  if (searchVal !== "") {
                    search(searchVal);
                  }
                }
              }}
              onChange={({ target }) => setSearchVal(target.value)}
              className="ST-rigth-input C-input"
              placeholder="Find a Friends..."
            />
          </div>
        </div> */}

        <div className="  C-custom-left-container-List">{myFriends()}</div>
      </div>
    </>
  );
};
export default Active;

import {
  CHALLENGES,
  GAMES,
  GAMES_JOIN,
  SEARCH_CHALLENGES,
  CHALLENGE_CATEGORIES,
  GAMERTAGS,
} from "../actions/types";

/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  games: [],
  games_join: [],
  games_join_pagination: { limit: 0, page: 0, totalPages: 0, totalResults: 0 },
  challenges: [],
  challengeCategories: [],
  gamerTags: [],
  challenges_pagination: { page: 0, limit: 0, totalPages: 0, totalResults: 0 },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GAMES:
      return {
        ...state,
        games: action.payload,
      };
    case GAMERTAGS:
      return {
        ...state,
        gamerTags: action.payload,
      };
    case GAMES_JOIN:
      return {
        ...state,
        games_join: action.payload,//action.payload.results
        games_join_pagination: {
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalResults,
        },
      };
    case CHALLENGES:
      return {
        ...state,
        challenges: action.payload.results,
        challenges_pagination: {
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalPages,
        },
      };
    case CHALLENGE_CATEGORIES:
      return { ...state, challengeCategories: action.payload };
    case SEARCH_CHALLENGES:
      return { ...state, challenges: action.payload };
    default:
      return state;
  }
};

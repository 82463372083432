import React from "react";
import "../assets/styles/Auth-css.css";

const Header1 = () => {
  return (
    <div className="Auth-header1">
      <div className="logo"></div>
    </div>
  );
};

export default Header1;

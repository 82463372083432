import React, { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import { useManaWallet } from "./custom-providers/useWallet"

function App({ concordiumProps }) {
  const { setCDCWalletProps } = useManaWallet()
  useEffect(() => {
    setCDCWalletProps(concordiumProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concordiumProps]);
  return (
    <AppRouter />
  )
}

export default App;

import React from "react";
import { useDispatch } from "react-redux";
import { setError, setSuccess } from "../actions";

const Alerts = ({ type, desc }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="swal-overlay swal-overlay--show-modal"
      onClick={() =>
        dispatch(type === "success" ? setSuccess(null) : setError(null))
      }
    >
      <div className="swal-modal">
        {type === "success" ? (
          <div className="swal-icon swal-icon--success">
            <span className="swal-icon--success__line swal-icon--success__line--long"></span>
            <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

            <div className="swal-icon--success__ring"></div>
            <div className="swal-icon--success__hide-corners"></div>
          </div>
        ) : (
          <div className="swal-icon swal-icon--error">
            <div className="swal-icon--error__x-mark">
              <span className="swal-icon--error__line swal-icon--error__line--left"></span>
              <span className="swal-icon--error__line swal-icon--error__line--right"></span>
            </div>
          </div>
        )}
        <div className="swal-text">{desc}</div>
        <div className="swal-footer">
          <div className="swal-button-container">
            <button
              className="swal-button swal-button--confirm"
              onClick={() =>
                dispatch(type === "success" ? setSuccess(null) : setError(null))
              }
            >
              OK
            </button>

            <div className="swal-button__loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alerts;

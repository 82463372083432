import "../assets/styles/Auth-css.css";
import Header1 from "./Header1";
import AlertUser from "../Alerts/userAlerts";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import useWindowSize from "../custom-Hooks/screen-width";
import { useDispatch, connect } from "react-redux";
import { setLoading } from "../actions";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const history = useHistory();
  const size = useWindowSize();
  const dispatch = useDispatch();

  async function signIn() {
    try {
      if (username === "" || password === "") {
        AlertUser.allFieldsRequired();
      } else {
        dispatch(setLoading(true));
      }
    } catch (error) {
      if (error.message === '2 validation errors detected: Value at \'userAlias\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at \'userName\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+') {
        AlertUser.insertEmail();
      }
    }
  }

  return (
    <div className="Auth-pages-container Auth">
      <Header1 />
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 dark-bg">
            <div className="title-signup">Sign In</div>
            <div className="subtitle">
              Don’t have an account? Creat your account, it takes less than a
              minute.
            </div>
            <div className="form-content">
              <div className="fields">
                <input
                  className="form-field"
                  type="text"
                  placeholder="Email"
                  value={username}
                  onChange={({ target }) => setUsername(target.value)}
                />
                <i className="fas fa-user" />
              </div>
              <div className="fields">
                <input
                  className="form-field"
                  type={showPass ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
                <i
                  className="fas fa-eye"
                  onClick={() => setShowPass(!showPass)}
                />
              </div>
              <div className="terms">
                <Link to="/Forgot-Password" className="terms-label">
                  Forgot Password?
                </Link>
              </div>
              <button className="btn--primary" onClick={signIn}>
                Sign In
              </button>
            </div>
          </div>
          {size.width > 767 ? (
            <div className="col-md-6 bg-signin d-flex align-items-center justify-content-center">
              <div>
                <div className="title">Hey There!</div>
                <hr className="line" />
                <div className="description">
                  Welcome Back.
                  <br /> You are just one step away to your <br />
                  feed.
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    className="btn-transparent"
                    onClick={() => history.push("signup")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default connect(null)(SignIn);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorizationKeysUpdateType = exports.HigherLevelKeyUpdateType = exports.KeyUpdateEntryStatus = exports.UpdateType = void 0;
var UpdateType;
(function (UpdateType) {
    UpdateType["Root"] = "root";
    UpdateType["Level1"] = "level1";
    UpdateType["HigherLevelKeyUpdate"] = "higherLevelKeyUpdate";
    UpdateType["AuthorizationKeysUpdate"] = "AuthorizationKeysUpdate";
    UpdateType["Protocol"] = "protocol";
    UpdateType["ElectionDifficulty"] = "electionDifficulty";
    UpdateType["EuroPerEnergy"] = "euroPerEnergy";
    UpdateType["MicroGtuPerEuro"] = "microGtuPerEuro";
    UpdateType["FoundationAccount"] = "foundationAccount";
    UpdateType["MintDistribution"] = "mintDistribution";
    UpdateType["TransactionFeeDistribution"] = "transactionFeeDistribution";
    UpdateType["GasRewards"] = "gasRewards";
    UpdateType["PoolParameters"] = "poolParameters";
    UpdateType["AddAnonymityRevoker"] = "addAnonymityRevoker";
    UpdateType["AddIdentityProvider"] = "addIdentityProvider";
    UpdateType["CooldownParameters"] = "cooldownParameters";
    UpdateType["TimeParameters"] = "timeParameters";
    UpdateType["ProtocolUpdate"] = "protocolUpdate";
    UpdateType["BakerStakeThreshold"] = "bakerStakeThreshold";
    UpdateType["Emergency"] = "emergency";
})(UpdateType = exports.UpdateType || (exports.UpdateType = {}));
var KeyUpdateEntryStatus;
(function (KeyUpdateEntryStatus) {
    KeyUpdateEntryStatus[KeyUpdateEntryStatus["Added"] = 0] = "Added";
    KeyUpdateEntryStatus[KeyUpdateEntryStatus["Removed"] = 1] = "Removed";
    KeyUpdateEntryStatus[KeyUpdateEntryStatus["Unchanged"] = 2] = "Unchanged";
})(KeyUpdateEntryStatus = exports.KeyUpdateEntryStatus || (exports.KeyUpdateEntryStatus = {}));
var HigherLevelKeyUpdateType;
(function (HigherLevelKeyUpdateType) {
    HigherLevelKeyUpdateType["RootKeysUpdate"] = "rootKeysUpdate";
    HigherLevelKeyUpdateType["Level1KeysUpdate"] = "level1KeysUpdate";
})(HigherLevelKeyUpdateType = exports.HigherLevelKeyUpdateType || (exports.HigherLevelKeyUpdateType = {}));
var AuthorizationKeysUpdateType;
(function (AuthorizationKeysUpdateType) {
    AuthorizationKeysUpdateType["Level2KeysUpdate"] = "level2KeysUpdate";
    AuthorizationKeysUpdateType["Level2KeysUpdateV1"] = "level2KeysUpdateV1";
})(AuthorizationKeysUpdateType = exports.AuthorizationKeysUpdateType || (exports.AuthorizationKeysUpdateType = {}));

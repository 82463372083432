import { getData, pathData, postData, deleteData } from "../apis"
import { ERROR, GAMES, GAMES_JOIN, LOADING } from "../actions/types";

const getGamesJoin = (page) => {
  return async (dispatch) => {
    const response = await getData(`/v1/games/get/all?page=${page}`); //`/v1/games-join?page=${page}&size=${size}`
    dispatch({ type: LOADING, payload: false });
    if (response.success) {
      dispatch({ type: GAMES_JOIN, payload: response.data });
    } else {
      dispatch({ type: ERROR, payload: response.data });
    }
  };
};

const getAllGames = () => {
  return async (dispatch) => {
    const response = await getData("/v1/games/get/all");
    dispatch({ type: LOADING, payload: false });
    if (response.success) {
      dispatch({ type: GAMES, payload: response.data.results });
    } else {
      dispatch({ type: ERROR, payload: response.data });
    }
  };
};

const getGamesPlayed = async (id) => {
  return getData(`/v1/user/get/by/${id}`);
};

const updateGamesPlayed = (gameTagId, gamerId, tag) => {
  return pathData(`/v1/games/update/gamer-tags/${gameTagId}`, {
    gameId: gamerId,
    tag,
  });
};

const addNewGamerTag = async (gameId, tag) => {
  return postData(`/v1/games/get/gamer-tags`, { gameId, tag });
};

const deleteGamerTag = (gameId) => {
  return deleteData(`/v1/games/update/gamer-tags/${gameId}`);
};

const checkGamerTag = (player, game, platform) => {
  return getData(`/v1/games/${player}/${game}/${platform}`);
};

const gameService = {
    getGamesJoin,
    getAllGames,
    getGamesPlayed,
    updateGamesPlayed,
    addNewGamerTag,
    deleteGamerTag,
    checkGamerTag,
}

export default gameService
import React, { useEffect, useRef, useState } from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import HomeItems from "../components/Home-items";
import moment from "moment";
import friendService from "../services/friend.service";
import { StreamChat } from "stream-chat";
import { connect, useDispatch } from "react-redux";
import { setLoading } from "../actions";
import { Notification } from "../hooks/NotificationStream";
const client = StreamChat.getInstance("td6s7tbp7n74");
const HomePage = ({ user, navbarSize }) => {
  const [liveStream, setLiveStream] = useState({
    channel: null,
    messages: null,
  });
  const dispatch = useDispatch();
  const liveStreamRef = useRef(liveStream);
  useEffect(() => {
    connect();
    // return () => client.disconnectUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const connect = async () => {
    let token = await localStorage.getItem("streamtoken");
    let expired = await localStorage.getItem("streamtokenExpired");
    // check if stream token expired
    if (token != null) {
      // if already expired
      if (moment(expired).isBefore(moment())) {
        token = await (await friendService.getStreamToken()).data.token;
      }
    } else {
      let newtoken = await (await friendService.getStreamToken()).data;
      token = newtoken.token;
    }
    await client
      .connectUser({ id: user.userName, name: user.userName }, token)
      .then(async () => {
        let channel = await client.channel("livestream", "games", {});
        channel.watch();
        const result = await channel.query({
          messages: { limit: 20 },
        });
        setLiveStream({
          channel,
          messages: result.messages,
        });
        const obj = document.getElementById("messages-container");
        setTimeout(() => {
          obj.scrollTop = obj?.scrollHeight;
        }, 300);
        liveStreamRef.current = {
          channel,
          messages: result.messages,
        };
        channel.on(async (event) => {
          if (
            event.type === "message.new" ||
            event.type === "notification.message_new"
          ) {
            // check if user has active the channel with the new message then show the new message
            if (
              liveStreamRef.current &&
              liveStreamRef.current.channel !== null
            ) {
              let messages = liveStreamRef.current.messages.slice();
              messages.push(event.message);
              liveStreamRef.current.messages = messages;
              setLiveStream({
                channel: liveStreamRef.current.channel,
                messages: liveStreamRef.current.messages,
              });
              const obj = document.getElementById("messages-container");
              setTimeout(() => {
                obj.scrollTop = obj?.scrollHeight;
              }, 300);
            }
          }
          // check if user delete a message
          if (event.type === "message.deleted") {
            let index = liveStreamRef.current.messages.findIndex(
              (i) => i.id === event.message.id
            );
            if (index !== -1) {
              // remove message from array
              liveStreamRef.current.messages.splice(index, 1);
              setLiveStream({
                channel: liveStreamRef.current.channel,
                messages: liveStreamRef.current.messages,
              });
            }
          }
          // listen new reaction or check if user reply a message or delete reaction
          if (
            event.type === "reaction.new" ||
            event.type === "member.updated" ||
            event.type === "reaction.deleted"
          ) {
            // update messages
            const messages = await channel.query({
              messages: { limit: liveStreamRef.current.messages.length },
            });
            liveStreamRef.current.messages = messages.messages;
            setLiveStream({
              channel: liveStreamRef.current.channel,
              messages: liveStreamRef.current.messages,
            });
          }
        });
        client.on(async (event) => {
          if (
            event.type === "notification.added_to_channel" ||
            event.type === "message.new" ||
            event.type === "notification.message_new" ||
            event.type === "reaction.new" ||
            event.type === "member.updated" ||
            event.type === "reaction.deleted"
          ) {
            Notification.set(true);
          }
        });
      });
  };
  const sendMessage = async (message, image) => {
    dispatch(setLoading(true));
    let fileURL = "";
    if (image !== null) {
      let res = await liveStream.channel.sendImage(image);
      fileURL = res.file;
    }
    let payload = { message };
    if (fileURL !== "") {
      payload.image = fileURL;
    }
    await liveStream.channel.sendMessage(payload);
    dispatch(setLoading(false));
  };
  const deleteMessage = async (id) => {
    dispatch(setLoading(true));
    let res = await client.deleteMessage(id, true);
    // check if message has a image then deleted
    if (res.message.image) {
      await liveStream.channel.deleteImage(res.message.image);
    }
    dispatch(setLoading(false));
  };
  const addReaction = async (id, type, deleteOption) => {
    dispatch(setLoading(true));
    // delete previous reaction if have any
    if (deleteOption && deleteOption.length > 0) {
      await liveStream.channel.deleteReaction(id, deleteOption[0].type);
    }
    await liveStream.channel.sendReaction(id, {
      type: type,
      enforce_unique: true,
    });
    dispatch(setLoading(false));
  };
  const deleteReaction = async (id, type) => {
    dispatch(setLoading(true));
    await liveStream.channel.deleteReaction(id, type);
    dispatch(setLoading(false));
  };
  const replyMessage = async (content, message, image) => {
    dispatch(setLoading(true));
    let fileURL = "";
    if (image !== null) {
      let res = await liveStream.channel.sendImage(image);
      fileURL = res.file;
    }
    let payload = {
      message,
      quoted_message_id: content.id,
    };
    if (fileURL !== "") {
      payload.image = fileURL;
    }
    await liveStream.channel.sendMessage(payload);
    dispatch(setLoading(false));
  };
  const LoadMore = async () => {
    const result = await liveStream.channel.query({
      messages: { limit: 10, id_lt: liveStream.messages[0].id },
    });
    // check if there are messages on the result
    if (result.messages.length > 0) {
      let messages = liveStreamRef.current.messages.slice();
      // add old message to top of the list
      messages.unshift(...result.messages);
      liveStreamRef.current.messages = messages;
      setLiveStream({
        ...liveStream,
        messages: liveStreamRef.current.messages,
      });
    }
  };

  return (
    <>
      <NavBar />
      <SideBar />
      <div
        className={
          navbarSize === "expand"
            ? "custom-containerHome"
            : "custom-container2Home"
        }
      >
        <HomeItems
          sendMessage={sendMessage}
          messages={liveStream.messages}
          loadMore={LoadMore}
          deleteMessage={deleteMessage}
          addReaction={addReaction}
          replyMessage={replyMessage}
          deleteReaction={deleteReaction}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return { user: state.auth.user, navbarSize: state.alerts.navbarSize };
};
export default connect(mapStateToProps)(HomePage);

import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, isSignedIn, user, ...rest }) => {
  const location = useLocation();
  if (!isSignedIn) {
    return <Redirect to="/" />;
  }
  if (user.firstName === "" || user.lastName === "") {
    return <Redirect to="/CompleteProfile" />;
  }
  if (user.urlPhoto === "") {
    return <Redirect to="/CompleteProfile2" />;
  }

  return <Route location={location} {...rest} component={Component} />;
};
const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn, user: state.auth.user };
};
export default connect(mapStateToProps)(PrivateRoute);

import * as React from "react";

const Send = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={46}
    {...props}
    viewBox="0 0  46 46"
  >
    <g data-name="Send Button" transform="translate(-.228)">
      <rect
        data-name="Rect\xE1ngulo 18"
        width={46}
        height={46}
        rx={23}
        transform="translate(.228)"
        fill="#d20056"
      />
      <path
        d="m30.328 19.491-8.6-4.304c-5.786-2.897-8.159-.523-5.263 5.259l.875 1.75a1.832 1.832 0 0 1 0 1.619l-.875 1.736c-2.896 5.786-.537 8.159 5.263 5.263l8.608-4.3c3.858-1.935 3.858-5.093-.008-7.023Zm-3.243 4.26h-5.431a.754.754 0 1 1 0-1.508h5.43a.754.754 0 1 1 0 1.508Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Send;

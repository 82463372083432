"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionEventTag = void 0;
var TransactionEventTag;
(function (TransactionEventTag) {
    TransactionEventTag["ModuleDeployed"] = "ModuleDeployed";
    TransactionEventTag["ContractInitialized"] = "ContractInitialized";
    TransactionEventTag["AccountCreated"] = "AccountCreated";
    TransactionEventTag["CredentialDeployed"] = "CredentialDeployed";
    TransactionEventTag["BakerAdded"] = "BakerAdded";
    TransactionEventTag["BakerRemoved"] = "BakerRemoved";
    TransactionEventTag["BakerStakeIncreased"] = "BakerStakeIncreased";
    TransactionEventTag["BakerStakeDecreased"] = "BakerStakeDecreased";
    TransactionEventTag["BakerSetRestakeEarnings"] = "BakerSetRestakeEarnings";
    TransactionEventTag["BakerKeysUpdated"] = "BakerKeysUpdated";
    TransactionEventTag["CredentialKeysUpdated"] = "CredentialKeysUpdated";
    TransactionEventTag["NewEncryptedAmount"] = "NewEncryptedAmount";
    TransactionEventTag["EncryptedAmountsRemoved"] = "EncryptedAmountsRemoved";
    TransactionEventTag["AmountAddedByDecryption"] = "AmountAddedByDecryption";
    TransactionEventTag["EncryptedSelfAmountAdded"] = "EncryptedSelfAmountAdded";
    TransactionEventTag["UpdateEnqueued"] = "UpdateEnqueued";
    TransactionEventTag["TransferredWithSchedule"] = "TransferredWithSchedule";
    TransactionEventTag["CredentialsUpdated"] = "CredentialsUpdated";
    TransactionEventTag["DataRegistered"] = "DataRegistered";
    TransactionEventTag["BakerSetOpenStatus"] = "BakerSetOpenStatus";
    TransactionEventTag["BakerSetMetadataURL"] = "BakerSetMetadataURL";
    TransactionEventTag["BakerSetTransactionFeeCommission"] = "BakerSetTransactionFeeCommission";
    TransactionEventTag["BakerSetBakingRewardCommission"] = "BakerSetBakingRewardCommission";
    TransactionEventTag["BakerSetFinalizationRewardCommission"] = "BakerSetFinalizationRewardCommission";
    TransactionEventTag["DelegationStakeIncreased"] = "DelegationStakeIncreased";
    TransactionEventTag["DelegationStakeDecreased"] = "DelegationStakeDecreased";
    TransactionEventTag["DelegationSetRestakeEarnings"] = "DelegationSetRestakeEarnings";
    TransactionEventTag["DelegationSetDelegationTarget"] = "DelegationSetDelegationTarget";
    TransactionEventTag["DelegationAdded"] = "DelegationAdded";
    TransactionEventTag["DelegationRemoved"] = "DelegationRemoved";
    TransactionEventTag["TransferMemo"] = "TransferMemo";
    TransactionEventTag["Transferred"] = "Transferred";
    TransactionEventTag["Interrupted"] = "Interrupted";
    TransactionEventTag["Resumed"] = "Resumed";
    TransactionEventTag["Updated"] = "Updated";
    TransactionEventTag["Upgraded"] = "Upgraded";
})(TransactionEventTag = exports.TransactionEventTag || (exports.TransactionEventTag = {}));

import React, { useState, useEffect, useRef } from "react";
import "../assets/styles/Auth-css.css";
import Header1 from "./Header1";
import { connect, useDispatch } from "react-redux";
import { setLoading, setError } from "../actions";
import { postData } from "../apis";
import AlertUser from "../Alerts/userAlerts";
import { client } from "../client";
import { inAppWallet } from "thirdweb/wallets";
import { ConnectButton, useActiveAccount, useDisconnect, useActiveWallet } from "thirdweb/react";
import { getUserEmail } from "thirdweb/wallets/in-app";
import userService from "../services/users";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [wallet, setWallet] = useState("");
    const [username, setUsername] = useState("");

    const formRef = useRef(null);

    const activeAccount = useActiveAccount();
    const { disconnect } = useDisconnect();
    const thirdWebWallet = useActiveWallet();
    const [shown, setShown] = useState(false)
    const thirdWebLogins = [
        inAppWallet({
            auth: {
                options: ["google", "email"],
            },
        }),
    ];

    const dispatch = useDispatch();

    async function validateFields(e) {
        e.preventDefault();
        if (username !== "") {
            if (username.length > 3) {
                const userExists = await userService.searchUserByNickName(
                    username
                );
                if (userExists.data.length > 0 && userExists.data[0].userName === username) {
                    dispatch(setError("Username already exists"));
                } else {
                    signUp();
                }
            } else {
                AlertUser.userNameLengthPolicy();
            }
        } else if (username === "") {
            dispatch(setError("Username required!"));
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            validateFields();
        }
    };

    const logoutThirdWeb = () => {
        setWallet("");
        setEmail("");
        disconnect(thirdWebWallet);
    }

    async function signUp() {
        dispatch(setLoading(true));
        try {
            const SendApi = await postData("/v1/user/auth", {
                idCognito: wallet,
                email,
                userName: username,
            });

            if (!SendApi.success || SendApi.data.message !== "user saved") {
                if (SendApi.data.message === '"idCognito" is required') {
                    dispatch(setError("Wallet is required"));
                } else {
                    dispatch(setError(SendApi.data.message));
                }
            } else {
                console.log('Here');
                dispatch({ type: "SIGN_UP", payload: true });
                dispatch(setLoading(false));
            }
        } catch (error) {
            switch (error.message) {
                case `1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$`:
                    AlertUser.passwordPolicy();
                    dispatch(setLoading(false));
                    break;
                case "Password did not conform with policy: Password not long enough":
                    AlertUser.passwordLengthPolicy();
                    dispatch(setLoading(false));
                    break;
                default:
                    AlertUser.personalError(error.message);
                    dispatch(setLoading(false));
                    break;
            }
        }
    }

    useEffect(() => {
        const getThirdWebData = async () => {
            const emailThirdWeb = await getUserEmail({ client });
            setEmail(emailThirdWeb);
            setWallet(activeAccount?.address);
        }
        if (!email || !wallet) {
            getThirdWebData()
        }

    }, [activeAccount, email, wallet]);

    if(email && wallet){
        const timer =  setTimeout(()=>{
            setShown(true)
        }, 500)
    }
    return (
        <div className="Auth-pages-container Auth">
            <Header1 />
            <div className="container">
                <div className="row d-flex">
                    {
                        (email && wallet && shown) &&
                        <div className="form-overlay">
                            <form ref={formRef} onSubmit={validateFields} onKeyDown={handleKeyPress} autoComplete="off">
                                <h2 className="form-title">Let's Start The Journey</h2>
                                <p className="form-description">Please select a username to finish your account setup.</p>
                                <div className="fields">
                                    <input
                                        className="form-field"
                                        type="text"
                                        placeholder="Username"
                                        value={username}
                                        onChange={({ target }) => setUsername(target.value)}
                                        autoComplete="off"
                                    />
                                    <i className="fas fa-user" />
                                </div>
                                <div className="responsiveLoginBtns">
                                    <button className="btn--secondary" type="submit">
                                        Create User
                                    </button>
                                    <button className="btn--secondary" onClick={logoutThirdWeb} type="button">
                                        Cancel Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    }

                    <div className=" bg-signup align-items-center justify-content-center">
                        <div>
                            <div className="title">Hey There, Welcome Back To Mana Games!!!.</div>
                            <hr className="line" />
                            <div className="description">
                                You are just one step away to your{" "}
                                <br />
                                feed.
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <ConnectButton
                                    client={client}
                                    wallets={thirdWebLogins}
                                    theme={"dark"}
                                    connectModal={{ size: "wide" }}
                                    connectButton={{ label: "Sign in" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default connect(null)(SignUp);

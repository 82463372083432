import * as React from "react";

const Cancel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 20.344}
    height={props.height ? props.height : 20.344}
    viewBox="0 0 130.344 130.344"
    {...props}
  >
    <path
      d="M92.325 38.016a38.4 38.4 0 1 0 0 54.306 38.466 38.466 0 0 0 0-54.306ZM73.997 78.07l-8.825-8.825-8.826 8.825a2.88 2.88 0 1 1-4.073-4.073l8.826-8.825-8.826-8.825a2.88 2.88 0 1 1 4.073-4.073l8.826 8.825 8.825-8.825a2.88 2.88 0 1 1 4.073 4.073l-8.825 8.825 8.825 8.825a2.88 2.88 0 1 1-4.073 4.073Z"
      fill={props.color ? props.color : "#d20056"}
      data-name="vuesax/bold/add-circle"
    />
  </svg>
);

export default Cancel;

import React from "react";
import SideBar from "../components/SideBar";
import NavBar from "../components/NavBar";
import New_Release_NFT from "./New_Release_NFT";

export default function NftPage_New_release() {
  const NewReleaseNFT = New_Release_NFT;
  return (
    <>
      <NavBar />
      <SideBar />
      <NewReleaseNFT />
    </>
  );
}

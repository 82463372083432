import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../assets/styles/Auth-css.css";
import user2 from "../../src/assets/user2.jpg";
import S3 from "../services/Aws/s3";
import userService from "../services/users";
import Header1 from "./Header1";
import { connect, useDispatch } from "react-redux";
import { setError, setLoading, updateProfile } from "../actions";

const CompleteProfilePage2 = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState(null);
  const [FormUpdateUser, setFormUpdateUser] = useState({
    urlPhoto: "",
    imgS3: "",
  });

  const sendPictures3 = async () => {
    dispatch(setLoading(true));
    try {
      if (
        (FormUpdateUser.urlPhoto.length !== 0) &
        (FormUpdateUser.urlPhoto !== undefined)
      ) {
        await S3.sendImage(
          FormUpdateUser.urlPhoto,
          function (data) {
            if (data) {
              setFormUpdateUser({
                ...FormUpdateUser,
                imgS3: data.Location,
                urlPhoto: "",
              });
              updateImgUser(data.Location);
              dispatch(setLoading(false));
            }
          },
          "profiles"
        );
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
      console.log("error con s3", error);
    }
  };

  const updateImgUser = async (urlImages3) => {
    try {
      const respupdateImgUser = await userService.CompleteProfile({
        userId: user._id,
        photoURL: urlImages3,
      });
      if (respupdateImgUser.success) {
        dispatch(setLoading(false));
        dispatch(updateProfile(respupdateImgUser.data.data));
        history.push("/shop");
      } else {
        dispatch(setError(respupdateImgUser.data.message));
      }
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  const nextLogin = () => {
    if (FormUpdateUser.urlPhoto !== "") {
      sendPictures3();
    } else {
      dispatch(setLoading(true));
      updateImgUser(
        "https://bucket-mana.s3.amazonaws.com/challenges/1638456751114-user2.jpg"
      );
    }
  };

  function processImage(event) {
    const imageFile = event.target.files[0];
    if (imageFile !== undefined) {
      setFormUpdateUser({
        ...FormUpdateUser,
        urlPhoto: event.target.files[0],
      });

      const imageUrl = URL.createObjectURL(imageFile);
      setFileUrl(imageUrl);
    }
  }

  return (
    <div className="Auth-pages-container Auth">
      <Header1 />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="containerTop">
              <div className="content1Page2"></div>
              <div className="content2Page2"></div>
            </div>
            <div
              className="title-signup"
              style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}
            >
              COMPLETE PROFILE
            </div>
          </div>
        </div>
        <div className="row dark-bg">
          <div className="col-12">
            <div className="containerImg">
              <img
                className="imgStyles"
                alt="user img"
                src={fileUrl !== null ? fileUrl : user2}
              />
              <div className="pluscircle">
                <i className="fas fa-plus"></i>
              </div>
              <div
                className="file-upload-wrapper2"
                data-text={
                  FormUpdateUser.urlPhoto ? FormUpdateUser.urlPhoto.name : ""
                }
              >
                <input
                  id="Scrat"
                  name="file-upload-field"
                  type="file"
                  className="file-upload-wrapper2"
                  onChange={processImage}
                  accept="image/*"
                />
              </div>

              <div className="chooseimg">Choose Image</div>
            </div>
          </div>
          <div className="col-6" style={{ textAlign: "right", marginTop: 20 }}>
            <button
              className="btn-transparent"
              onClick={() => history.push("/CompleteProfile")}
            >
              Back
            </button>
          </div>
          <div className="col-6" style={{ marginTop: 20 }}>
            <button className="btn--primary2" onClick={nextLogin}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log(
    "🚀 ~ file: CompleteProfilePage2.js ~ line 206 ~ mapStateToProps ~ state",
    state
  );
  return { user: state.auth.user };
};
export default connect(mapStateToProps)(CompleteProfilePage2);

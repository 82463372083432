import { getData } from "../apis";

const getCountry = async () => {
  return getData("/v1/countries/get/all/%7BcountrieId%7D");
};

const getCity = async (idCountry) => {
  return getData(`/v1/countries/get/all/{countrieId}?countrieId=${idCountry}`);
};

const countryAndCity = {
  getCountry,
  getCity,
};

export default countryAndCity;

import React from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { convertHash } from "./convertHash";

const TransfersTable = ({ transfers, handleCopy }) => {
  return (
    <Table className="table-wallet" bordered>
      <thead className="pb-4">
        <tr style={{ position: "sticky", marginTop: "0" }}>
          <th style={{ width: "150px" }} className="text-center title-txt font-weight-bold">Date</th>
          <th style={{ width: "150px" }} className="text-center title-txt font-weight-bold">Hash</th>
          <th style={{ width: "100px" }} className="text-center title-txt font-weight-bold">From</th>
          <th style={{ width: "100px" }} className="text-center title-txt font-weight-bold">To</th>
          <th style={{ width: "100px" }} className="text-center title-txt font-weight-bold">Token</th>
          <th style={{ width: "100px" }} className="text-center title-txt font-weight-bold">Type</th>
        </tr>
      </thead>
      <tbody className="mt-4 tableBody">
        {transfers?.length > 0 ? (
          transfers.map((item, index) => (
            <tr className="tableRow" key={index}>
              <td className="text-center m-4 paddingTable" style={{ width: "150px" }}>
                {moment(item.timestamp).format("DD MM YYYY")}
              </td>
              <td className="text-center m-4 paddingTable">
                <p style={{ cursor: "pointer" }} onClick={() => handleCopy(item.transactionHash)}>
                  {convertHash(item.transactionHash)}
                </p>
              </td>
              <td className="text-center m-4 paddingTable">
                <p style={{ cursor: "pointer" }} onClick={() => handleCopy(item.from)}>
                  {item.from.slice(0, 6) + "..." + item.from.slice(-4)}
                </p>
              </td>
              <td className="text-center m-4 paddingTable">
                <p style={{ cursor: "pointer" }} onClick={() => handleCopy(item.to)}>
                  {item.to.slice(0, 6) + "..." + item.to.slice(-4)}
                </p>
              </td>
              <td className="text-center m-4 paddingTable">{item.fields.tokenId}</td>
              <td className="text-center m-4 paddingTable">{item.type}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" className="text-center">No transfers found</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default TransfersTable;

import * as React from "react";

const Clock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 16.651}
    height={props.height ? props.height : 16.651}
    viewBox="0 0 86.651 86.651"
    {...props}
  >
    <path
      d="M43.321 7.221a36.1 36.1 0 1 0 36.1 36.1 36.164 36.164 0 0 0-36.1-36.1Zm15.71 48.994a2.694 2.694 0 0 1-2.347 1.336 2.418 2.418 0 0 1-1.372-.4l-11.191-6.677a10.648 10.648 0 0 1-4.838-8.521V27.151a2.708 2.708 0 1 1 5.416 0v14.8a5.336 5.336 0 0 0 2.2 3.863l11.192 6.679a2.673 2.673 0 0 1 .94 3.722Z"
      fill={props.color ? props.color : "#286efa"}
      data-name="vuesax/bold/clock"
    />
  </svg>
);

export default Clock;

import swal from "sweetalert";

function success() {
  swal({
    text: "welcome!",
    icon: "success",
    timer: "1000",
  });
}

function Error(error) {
  swal("Error!", error.message, "error");
}

function challengeCreated() {
  swal({
    text: "challenge created!",
    icon: "success",
    timer: "2000",
  });
}
function challengeNotFound() {
  swal({
    text: "Challenge Not Found!",
    icon: "error",
    timer: "2000",
  });
}

function requestSent() {
  swal({
    text: "Friend Request Sent",
    icon: "success",
    timer: "2000",
  });
}
function acceptRequest() {
  swal({
    text: "Friend Request Accepted",
    icon: "success",
    timer: "2000",
  });
}
function deleteRequest() {
  swal({
    text: "Friend Request Deleted",
    icon: "error",
    timer: "2000",
  });
}

function requesthasAlreadyBeenSent() {
  swal({
    text: "Request Has Already Been Sent",
    icon: "error",
    timer: "2000",
  });
}
function updatedplayerprofile() {
  swal({
    text: "Updated Player Profile",
    icon: "success",
    timer: "2000",
  });
}
function errorUpdateUser(errorMessage) {
  swal({
    text: errorMessage,
    icon: "error",
  });
}
function allFieldsRequired() {
  swal({
    text: "Please Fill In All Fields",
    icon: "error",
  });
}
function diferentsPaswords() {
  swal({
    text: "passwords must be the same",
    icon: "error",
  });
}
function insertEmail() {
  swal({
    text: "Insert a Valid Email",
    icon: "info",
  });
}

function differentpasswords() {
  swal({
    text: "passwords must be the same",
    icon: "error",
  });
}

function invalidCode(error) {
  swal({
    text: error,
    icon: "error",
  });
}

function UserReported() {
  swal({
    text: "Reported User",
    icon: "success",
    timer: "2000",
  });
}

function badRequest() {
  swal({
    text: "Please Enter A Valid Email",
    icon: "error",
  });
}

function gamesPlayedUpdated() {
  swal({
    text: "Games Updated",
    icon: "success",
    timer: "2000",
  });
}

function gamesPlayedadded() {
  swal({
    text: "Game Saved",
    icon: "success",
    timer: "2000",
  });
}

function insufficientBalance() {
  swal({
    text: "insufficient balance",
    icon: "error",
  });
}

function succesfulTransaction() {
  swal({
    text: "succesful transaction",
    icon: "success",
  });
}

function termsAndConditions() {
  swal({
    text: "Please select the terms and conditions.",
    icon: "warning",
  });
}

function incorrectCode() {
  swal({
    text: "The code entered is not correct. Please try again.",
  });
}

function passwordPolicy() {
  swal({
    // text: "We have detected an issue with your registration. You will need to fill in all fields. Your password must have an uppercase and a number. ",
    text: "Password did not conform with policy: Password must have an uppercase letter or a number.",
  });
}

function passwordLengthPolicy() {
  swal({
    text: "Password did not conform with policy: Password must be at least 8 characters long",
  });
}

function personalError(message) {
  swal({
    text: message,
  });
}

function userNameLengthPolicy() {
  swal({
    text: "Username must be at least 4 characters long",
  });
}

function insertMoreCharacters() {
  swal({
    text: "Insert at least three characters to search",
    icon: "warning",
  });
}

function fillFields() {
  swal({
    text: "Please fill in all the fields",
  });
}

function error(error) {
  swal({
    text: error,
    icon: "error",
  });
}


function playerNotFound(error) {
  swal({
    text: error,
    icon: "error",
  });
}

function walletExtentionNotFound(wallet, link) {
  swal({
    text: `You Need to Install The ${wallet} Extention`,
    buttons: 'Go To Install',
  }).then((value) => {
    if (value) {
      window.open(link, "_blank");
    }
  });
}


const AlertUser = {
  success,
  Error,
  challengeCreated,
  challengeNotFound,
  requestSent,
  acceptRequest,
  deleteRequest,
  requesthasAlreadyBeenSent,
  updatedplayerprofile,
  userNameLengthPolicy,
  errorUpdateUser,
  allFieldsRequired,
  diferentsPaswords,
  insertEmail,
  differentpasswords,
  invalidCode,
  UserReported,
  badRequest,
  gamesPlayedUpdated,
  gamesPlayedadded,
  insufficientBalance,
  succesfulTransaction,
  termsAndConditions,
  incorrectCode,
  passwordPolicy,
  passwordLengthPolicy,
  insertMoreCharacters,
  fillFields,
  error,
  playerNotFound,
  personalError,
  walletExtentionNotFound,
};

export default AlertUser;

/* eslint-disable no-undef */

export const JSON_RPC_URL = 'https://json-rpc.testnet.concordium.com';
export const WALLET_CONNECT_PROJECT_ID = '76324905a70fe5c388bab46d3e0564dc';
export const WALLET_CONNECT_SESSION_NAMESPACE = 'ccd';
export const DEFAULT_CONTRACT_INDEX = BigInt(81);
export const MAX_CONTRACT_EXECUTION_ENERGY = BigInt(30000);
export const CHAIN_ID = 'ccd:testnet';
// export const ZERO_AMOUNT = GtuAmount(BigInt(0));
export const CCDSCAN_URL = 'testnet.ccdscan.io';
export const PING_INTERVAL_MS = 5000;
export const RAW_SCHEMA_BASE64 =
    '//8DAQAAAA0AAABNYW5hQ2hhbGxlbmdlAAoAAAAQAAAAYWNjZXB0X2NoYWxsZW5nZQAUAAEAAAAMAAAAY2hhbGxlbmdlX2lkFgINAAAAYWRkX2NoYWxsZW5nZQAUAAIAAAAMAAAAY2hhbGxlbmdlX2lkFgIJAAAAY2hhbGxlbmdlFAAHAAAABgAAAGFtb3VudAoEAAAAZ2FtZRYCCgAAAGNoYWxsZW5nZXILCQAAAG9wcG9uZW50cxUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAABECCwoAAABleHBpcmF0aW9uDQkAAABpc19wdWJsaWMBDQAAAG1heF9vcHBvbmVudHMVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAEEAAAAGNhbmNlbF9jaGFsbGVuZ2UAFAABAAAADAAAAGNoYWxsZW5nZV9pZBYCEwAAAHNldF9wZXJjZW50YWdlX2ZlZXMAFAABAAAADwAAAHBlcmNlbnRhZ2VfZmVlcwMOAAAAc2V0X3dpbm5lcl9hcGkAFAAEAAAADAAAAGNoYWxsZW5nZV9pZBYCBgAAAHdpbm5lcgsRAAAAdmFsaWRhdG9yX2FkZHJlc3MVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAWAhAAAAB2YWxpZGF0b3JfYW1vdW50FQIAAAAEAAAATm9uZQIEAAAAU29tZQEBAAAAChEAAABzZXRfd2lubmVyX3BsYXllcgAUAAQAAAAMAAAAY2hhbGxlbmdlX2lkFgIGAAAAd2lubmVyCxEAAAB2YWxpZGF0b3JfYWRkcmVzcxUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAABYCEAAAAHZhbGlkYXRvcl9hbW91bnQVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAKFAAAAHNldF93aW5uZXJfdmFsaWRhdG9yABQABAAAAAwAAABjaGFsbGVuZ2VfaWQWAgYAAAB3aW5uZXILEQAAAHZhbGlkYXRvcl9hZGRyZXNzFQIAAAAEAAAATm9uZQIEAAAAU29tZQEBAAAAFgIQAAAAdmFsaWRhdG9yX2Ftb3VudBUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAAAoOAAAAdmlld19jaGFsbGVuZ2UCFAABAAAADAAAAGNoYWxsZW5nZV9pZBYCFAAIAAAABgAAAGFtb3VudAoEAAAAZ2FtZRYCCgAAAGNoYWxsZW5nZXILCQAAAG9wcG9uZW50cxUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAABICCwEKAAAAZXhwaXJhdGlvbg0GAAAAd2lubmVyFQIAAAAEAAAATm9uZQIEAAAAU29tZQEBAAAACwkAAABpc19wdWJsaWMBDQAAAG1heF9vcHBvbmVudHMVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAEFAAAAHZpZXdfcGVyY2VudGFnZV9mZWVzAQMPAAAAdmlld192YWxpZGF0aW9uAhQAAQAAAAwAAABjaGFsbGVuZ2VfaWQWAhQABAAAAAcAAABwbGF5ZXJzFQIAAAAEAAAATm9uZQIEAAAAU29tZQEBAAAAEgILCwwAAABtYWluX2FkZHJlc3MVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAALEQAAAHZhbGlkYXRvcl9hZGRyZXNzFQIAAAAEAAAATm9uZQIEAAAAU29tZQEBAAAAFgIQAAAAdmFsaWRhdG9yX2Ftb3VudBUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAAAoA';

export const TESTNET_GENESIS_BLOCK_HASH =
    "4221332d34e1694168c2a0c0b3fd0f273809612cb13d000d5c2e00e85f50f796";

export const TESTNET = {
    name: "testnet",
    genesisHash: TESTNET_GENESIS_BLOCK_HASH,
    jsonRpcUrl: JSON_RPC_URL, // "http://54.185.97.109:10001", // JSON_RPC_URL,
    ccdScanBaseUrl: "https://testnet.ccdscan.io",
};

export const WALLET_CONNECT_OPTIONS_TESTNET = {
    projectId: WALLET_CONNECT_PROJECT_ID,
    metadata: {
        name: "Mana Games",
        description: "Mana Games",
        url: "#",
        icons: ["/wallet-concordium.png"],
    },
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConcordiumClient = void 0;
const grpcweb_transport_1 = require("@protobuf-ts/grpcweb-transport");
const common_sdk_1 = require("@concordium/common-sdk");
/**
 * Initialize a gRPC client for a specific concordium node.
 * @param address the ip address of the node, e.g. http://127.0.0.1
 * @param port the port to use when econnecting to the node
 * @param options optional options for the grpc-web transport
 */
function createConcordiumClient(address, port, options) {
    const transport = new grpcweb_transport_1.GrpcWebFetchTransport({
        baseUrl: `${address}:${port}`,
        ...options,
    });
    return new common_sdk_1.ConcordiumGRPCClient(transport);
}
exports.createConcordiumClient = createConcordiumClient;

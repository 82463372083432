"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCIS2Transfer = exports.formatCIS2UpdateOperator = exports.deserializeCIS2OperatorOfResponse = exports.serializeCIS2OperatorOfQueries = exports.deserializeCIS2TokenMetadataResponse = exports.serializeCIS2TokenIds = exports.deserializeCIS2BalanceOfResponse = exports.serializeCIS2BalanceOfQueries = exports.serializeCIS2UpdateOperators = exports.serializeCIS2Transfers = exports.isContractAddress = void 0;
const serializationHelpers_1 = require("../serializationHelpers");
const buffer_1 = require("buffer/");
const accountAddress_1 = require("../types/accountAddress");
const uleb128_1 = require("../uleb128");
const TOKEN_ID_MAX_LENGTH = 256;
const TOKEN_AMOUNT_MAX_LENGTH = 37;
const TOKEN_RECEIVE_HOOK_MAX_LENGTH = 100;
/**
 * Checks whether an `Address` is a `ContractAddress`
 */
const isContractAddress = (address) => typeof address !== 'string';
exports.isContractAddress = isContractAddress;
function serializeCIS2TokenId(tokenId) {
    const serialized = buffer_1.Buffer.from(tokenId, 'hex');
    if (serialized.length > TOKEN_ID_MAX_LENGTH) {
        throw new Error(`Token ID exceeds maximum size of ${TOKEN_ID_MAX_LENGTH} bytes`);
    }
    return (0, serializationHelpers_1.packBufferWithWord8Length)(serialized);
}
function serializeTokenAmount(amount) {
    if (amount < 0) {
        throw new Error('Negative token amount is not allowed');
    }
    const serialized = (0, uleb128_1.uleb128Encode)(amount);
    if (serialized.length > TOKEN_AMOUNT_MAX_LENGTH) {
        throw new Error(`Token amount exceeds maximum size of ${TOKEN_AMOUNT_MAX_LENGTH} bytes`);
    }
    return serialized;
}
function serializeAccountAddress(address) {
    return new accountAddress_1.AccountAddress(address).decodedAddress;
}
function serializeContractAddress(address) {
    const index = (0, serializationHelpers_1.encodeWord64)(address.index, true);
    const subindex = (0, serializationHelpers_1.encodeWord64)(address.subindex, true);
    return buffer_1.Buffer.concat([index, subindex]);
}
function serializeAddress(address) {
    const isContract = (0, exports.isContractAddress)(address);
    const type = (0, serializationHelpers_1.encodeWord8)(isContract ? 1 : 0);
    const serializedAddress = !isContract
        ? serializeAccountAddress(address)
        : serializeContractAddress(address);
    return buffer_1.Buffer.concat([type, serializedAddress]);
}
function serializeReceiveHookName(hook) {
    const serialized = buffer_1.Buffer.from(hook, 'ascii');
    if (serialized.length > TOKEN_RECEIVE_HOOK_MAX_LENGTH) {
        throw new Error(`Token receive hook name exceeds maximum size of ${TOKEN_RECEIVE_HOOK_MAX_LENGTH} bytes`);
    }
    return (0, serializationHelpers_1.packBufferWithWord16Length)(serialized, true);
}
function serializeContractReceiver(receiver) {
    const address = serializeContractAddress(receiver.address);
    const hook = serializeReceiveHookName(receiver.hookName);
    return buffer_1.Buffer.concat([address, hook]);
}
function serializeReceiver(receiver) {
    const isAccount = typeof receiver === 'string';
    const type = (0, serializationHelpers_1.encodeWord8)(isAccount ? 0 : 1);
    const serializedAddress = isAccount
        ? serializeAccountAddress(receiver)
        : serializeContractReceiver(receiver);
    return buffer_1.Buffer.concat([type, serializedAddress]);
}
function serializeAdditionalData(data) {
    const serialized = buffer_1.Buffer.from(data, 'hex');
    return (0, serializationHelpers_1.packBufferWithWord16Length)(serialized, true);
}
const makeSerializeList = (serialize) => (input) => {
    const n = (0, serializationHelpers_1.encodeWord16)(input.length, true);
    return buffer_1.Buffer.concat([n, ...input.map(serialize)]);
};
function serializeCIS2Transfer(transfer) {
    const id = serializeCIS2TokenId(transfer.tokenId);
    const amount = serializeTokenAmount(transfer.tokenAmount);
    const from = serializeAddress(transfer.from);
    const to = serializeReceiver(transfer.to);
    const data = serializeAdditionalData(transfer.data ?? '');
    return buffer_1.Buffer.concat([id, amount, from, to, data]);
}
/**
 * Serializes a list of {@link CIS2.Transfer} data objects according to the CIS-2 standard.
 *
 * @param {CIS2.Transfer[]} transfers - A list of {@link CIS2.Transfer} objects
 *
 * @example
 * const transfers = [{
    tokenId: '';
    tokenAmount: 100n;
    from: '3nsRkrtQVMRtD2Wvm88gEDi6UtqdUVvRN3oGZ1RqNJ3eto8owi',
    to: '3nsRkrtQVMRtD2Wvm88gEDi6UtqdUVvRN3oGZ1RqNJ3eto8owi',
    data: '48656c6c6f20776f726c6421';
}];
 * const bytes = serializeCIS2Transfers(transfers);
 */
exports.serializeCIS2Transfers = makeSerializeList(serializeCIS2Transfer);
function serializeCIS2UpdateOperator(update) {
    const type = (0, serializationHelpers_1.encodeWord8)(update.type === 'add' ? 1 : 0);
    const address = serializeAddress(update.address);
    return buffer_1.Buffer.concat([type, address]);
}
/**
 * Serializes a list of {@link CIS2.UpdateOperator} data objects according to the CIS-2 standard.
 *
 * @param {CIS2.UpdateOperator[]} updates - A list of {@link CIS2.UpdateOperator} objects
 *
 * @example
 * const updates = [{
*       type: 'add',
*       address: '3nsRkrtQVMRtD2Wvm88gEDi6UtqdUVvRN3oGZ1RqNJ3eto8owi'
    }];
 * const bytes = serializeCIS2UpdateOperators(updates);
 */
exports.serializeCIS2UpdateOperators = makeSerializeList(serializeCIS2UpdateOperator);
/**
 * Serializes {@link CIS2BalanceOfQuery} data objects according to CIS-2 standard.
 */
function serializeCIS2BalanceOfQuery(query) {
    const token = serializeCIS2TokenId(query.tokenId);
    const address = serializeAddress(query.address);
    return buffer_1.Buffer.concat([token, address]);
}
/**
 * Serializes a list of {@link CIS2.BalanceOfQuery} data objects according to the CIS-2 standard.
 *
 * @param {CIS2.BalanceOfQuery[]} queries - A list of {@link CIS2.BalanceOfQuery} objects
 *
 * @example
 * const queries = [{tokenId: '', address: '3nsRkrtQVMRtD2Wvm88gEDi6UtqdUVvRN3oGZ1RqNJ3eto8owi'}];
 * const bytes = serializeCIS2BalanceOfQueries(queries);
 */
exports.serializeCIS2BalanceOfQueries = makeSerializeList(serializeCIS2BalanceOfQuery);
/**
 * Deserializes response of CIS-2 balanceOf query according to CIS-2 standard.
 *
 * @param {HexString} value - The hex string value to deserialize
 *
 * @returns {bigint[]} A list of token balances.
 */
exports.deserializeCIS2BalanceOfResponse = (0, serializationHelpers_1.makeDeserializeListResponse)((buf) => {
    const end = buf.findIndex((b) => b < 2 ** 7) + 1; // Find the first byte with most significant bit not set, signaling the last byte in the leb128 slice.
    if (end === 0) {
        throw new Error('Could not find leb128 end');
    }
    const leb128Slice = buf.subarray(0, end);
    if (leb128Slice.length > TOKEN_AMOUNT_MAX_LENGTH) {
        throw new Error(`Found token amount with size exceeding the maximum allowed of ${TOKEN_AMOUNT_MAX_LENGTH}`);
    }
    const value = (0, uleb128_1.uleb128Decode)(buffer_1.Buffer.from(leb128Slice));
    return { value, bytesRead: end };
});
/**
 * Serializes a list of {@link HexString} token ID's according to the CIS-2 standard.
 *
 * @param {HexString[]} tokenIds - A list of {@link HexString} values
 *
 * @example
 * const tokenIds = ['', '01', 'e2'];
 * const bytes = serializeCIS2TokenIds(tokenIds);
 */
exports.serializeCIS2TokenIds = makeSerializeList(serializeCIS2TokenId);
/**
 * Deserializes response of CIS-2 tokenMetadata query according to CIS-2 standard.
 *
 * @param {HexString} value - The hex string value to deserialize
 *
 * @returns {CIS2MetadataUrl[]} A list of metadata URL objects.
 */
exports.deserializeCIS2TokenMetadataResponse = (0, serializationHelpers_1.makeDeserializeListResponse)((buf) => {
    const length = buf.readUInt16LE(0);
    const urlStart = 2;
    const urlEnd = urlStart + length;
    const url = buffer_1.Buffer.from(buf.subarray(urlStart, urlEnd)).toString('utf8');
    let cursor = urlEnd;
    const hasChecksum = buf.readUInt8(cursor);
    cursor += 1;
    let metadataUrl;
    if (hasChecksum === 1) {
        const hash = buffer_1.Buffer.from(buf.subarray(cursor, cursor + 32)).toString('hex');
        cursor += 32;
        metadataUrl = { url, hash };
    }
    else if (hasChecksum === 0) {
        metadataUrl = { url };
    }
    else {
        throw new Error('Deserialization failed: boolean value had an unexpected value');
    }
    return { value: metadataUrl, bytesRead: cursor };
});
function serializeCIS2OperatorOfQuery(query) {
    const owner = serializeAddress(query.owner);
    const address = serializeAddress(query.address);
    return buffer_1.Buffer.concat([owner, address]);
}
/**
 * Serializes a list of {@link CIS2.OperatorOfQuery} queries according to the CIS-2 standard.
 *
 * @param {CIS2.OperatorOfQuery[]} queries - A list of {@link CIS2.OperatorOfQuery} objects
 *
 * @example
 * const queries = [{owner: "3nsRkrtQVMRtD2Wvm88gEDi6UtqdUVvRN3oGZ1RqNJ3eto8owi", address: {index: 123n, subindex: 0n}}];
 * const bytes = serializeCIS2OperatorOfQueries(tokenIds);
 */
exports.serializeCIS2OperatorOfQueries = makeSerializeList(serializeCIS2OperatorOfQuery);
/**
 * Deserializes response of CIS-2 operatorOf query according to CIS-2 standard.
 *
 * @param {HexString} value - The hex string value to deserialize
 *
 * @returns {boolean[]} A list of boolean values.
 */
exports.deserializeCIS2OperatorOfResponse = (0, serializationHelpers_1.makeDeserializeListResponse)((buf) => {
    const value = Boolean(buf.readUInt8(0));
    return { value, bytesRead: 1 };
});
/**
 * Format {@link CIS2.UpdateOperator} as JSON compatible with serialization wtih corresponding schema.
 */
function formatCIS2UpdateOperator(input) {
    return {
        update: input.type === 'add' ? { Add: {} } : { Remove: {} },
        operator: (0, exports.isContractAddress)(input.address)
            ? {
                Contract: [
                    {
                        index: Number(input.address.index),
                        subindex: Number(input.address.subindex),
                    },
                ],
            }
            : { Account: [input.address] },
    };
}
exports.formatCIS2UpdateOperator = formatCIS2UpdateOperator;
/**
 * Format {@link CIS2.Transfer} as JSON compatible with serialization wtih corresponding schema.
 */
function formatCIS2Transfer(input) {
    const from = (0, exports.isContractAddress)(input.from)
        ? {
            Contract: [
                {
                    index: Number(input.from.index),
                    subindex: Number(input.from.subindex),
                },
            ],
        }
        : { Account: [input.from] };
    let to;
    if (typeof input.to === 'string') {
        to = { Account: [input.to] };
    }
    else {
        to = {
            Contract: [
                {
                    index: Number(input.to.address.index),
                    subindex: Number(input.to.address.subindex),
                },
                input.to.hookName,
            ],
        };
    }
    return {
        token_id: input.tokenId,
        amount: input.tokenAmount.toString(),
        from,
        to,
        data: input.data ?? '',
    };
}
exports.formatCIS2Transfer = formatCIS2Transfer;

import React from "react";
import StylesNotification from "../assets/styles/Notifications.css";
import user from "./../assets/user2.jpg";
import friendService from "../services/friend.service";
import { useHistory } from "react-router-dom";

const Notifications = (props) => {
  const AcceptFriendRequest = async (requesstId) => {
    const respAcceptFriendRequest = await friendService.acceptRequest(requesstId);
    if (respAcceptFriendRequest.success) {
      props.update();
    } else {
      props.error();
    }
  };

  const deleteFriendRequest = async (deleteRequest) => {
    const respdeleteFriendRequest = await friendService.rejectRequest(deleteRequest);
    if (respdeleteFriendRequest.success) {
      props.update();
    } else {
      props.error();
    }
  };

  function closeModal() {
    props.close(false);
  }
  const history = useHistory();

  return (
    <div className="modal-dialog containerModal">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="titleNotifications">Notifications</h1>
        </div>
        <div className="modal-body bodyScroll">
          {props.data?.length > 0
            ? props.data.map((notificationss, index) =>
                notificationss.view === false  ? (
                  <div
                    className="containerNotification"
                    key={index}
                    onClick={() =>
                      notificationss.model
                        ? history.push(`/challenge/${notificationss.data._id}`)
                        : null
                    }
                  >
                    <div
                      className="Container-img-email"
                      onClick={() =>
                        notificationss.status === "Sent"
                          ? history.push("/Friending")
                          : null
                      }
                    >
                      <img
                        className="photoUser"
                        src={
                          notificationss.model
                            ? notificationss.data.urlImage
                            : notificationss.user.urlPhoto
                            ? notificationss.user.urlPhoto
                            : user
                        }
                        alt="user"
                      />
                      <div className="Container-email-stylesp">
                        <div className="email">
                          {notificationss.model
                            ? notificationss.from.userName
                            : notificationss.user.userName}
                        </div>
                        <p className="stylesp">
                          {notificationss.model
                            ? notificationss.message
                            : notificationss.iSent === false
                            ? "sent you a friend request"
                            : notificationss.status === "Accepted"
                            ? "Friend Request Accepted"
                            : "Friend Request pending"}
                        </p>
                      </div>
                    </div>
                    {notificationss.iSent === false &&
                    notificationss.status !== "Accepted" ? (
                      <div className="Container-Buttons">
                        <div className="container-bt-confirm">
                          <button
                            className="btn btn-primary Confirm"
                            onClick={() =>
                              AcceptFriendRequest(notificationss._id)
                            }
                          >
                            Confirm
                          </button>
                        </div>
                        <button
                          className=" btn btn-dark Delete"
                          onClick={() =>
                            deleteFriendRequest(notificationss._id)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : null
              )
            : "No New notifications"}
        </div>
        <div className="modal-footer">
          <button onClick={() => closeModal()} className="btnClose">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
export default Notifications;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateBakerKeys = exports.isRemovalPendingChange = exports.isReduceStakePendingChange = exports.isStakePendingChangeV0 = exports.isStakePendingChangeV1 = exports.isBakerAccountV0 = exports.isBakerAccountV1 = exports.isBakerAccount = exports.isDelegatorAccount = void 0;
const wasm = __importStar(require("@concordium/rust-bindings"));
const isDelegatorAccount = (ai) => ai.accountDelegation !== undefined;
exports.isDelegatorAccount = isDelegatorAccount;
const isBakerAccount = (ai) => ai.accountBaker !== undefined;
exports.isBakerAccount = isBakerAccount;
const isBakerAccountV1 = (ai) => ai.accountBaker?.bakerPoolInfo !== undefined;
exports.isBakerAccountV1 = isBakerAccountV1;
const isBakerAccountV0 = (ai) => ai.accountBaker?.bakerPoolInfo === undefined;
exports.isBakerAccountV0 = isBakerAccountV0;
const isStakePendingChangeV1 = (spc) => spc.effectiveTime !== undefined;
exports.isStakePendingChangeV1 = isStakePendingChangeV1;
const isStakePendingChangeV0 = (spc) => spc.epoch !== undefined;
exports.isStakePendingChangeV0 = isStakePendingChangeV0;
const isReduceStakePendingChange = (spc) => spc.newStake !== undefined;
exports.isReduceStakePendingChange = isReduceStakePendingChange;
const isRemovalPendingChange = (spc) => !(0, exports.isReduceStakePendingChange)(spc);
exports.isRemovalPendingChange = isRemovalPendingChange;
/**
 * Generates random baker keys for the specified account, that can be used with the configureBaker transaction
 * @param account the address of the account that the keys should be added to.
 * @returns an object containing the public baker keys, their associated proofs and their associated private keys.
 */
function generateBakerKeys(account) {
    const rawKeys = wasm.generateBakerKeys(account.address);
    try {
        return JSON.parse(rawKeys);
    }
    catch (e) {
        throw new Error(rawKeys);
    }
}
exports.generateBakerKeys = generateBakerKeys;

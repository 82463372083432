import React, { useRef, useState } from "react";
import "../../assets/styles/Settings-Chatpage.css";
import MessageBox from "../MessageBox";

import Send from "../../assets/Send";
import PaperClip from "../../assets/PaperClip";

const CurrentChat = ({
  currentConversation,
  sendMessage,
  loadMore,
  username,
  deleteMessage,
  addReaction,
  deleteReaction,
  Reply,
  submitReaction,
}) => {
  const [message, setMessage] = useState("");
  const input = useRef();
  const messageInput = useRef();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [reply, setReply] = useState({ user: "", content: "", id: "" });
  const getUrl = async (event) => {
    const imageFile = event.target.files[0];
    if (imageFile !== undefined) {
      setFile(event.target.files[0]);
      const imageUrl = URL.createObjectURL(imageFile);
      setFileUrl(imageUrl);
    }
  };
  const submit = () => {
    if (message !== "" || file !== null) {
      if (reply.id !== "") {
        Reply(reply, message, file, currentConversation.index);
      } else {
        sendMessage(currentConversation.index, message, file);
      }
      setMessage("");
      setReply({ user: "", content: "", id: "" });
      setFile(null);
      setFileUrl(null);
    }
  };
  return (
    <>
      <div className="col-md-9 live-chat" style={{ background: "transparent" }}>
        <div className="H-items-container" style={{ marginTop: 0 }}>
          <div className="just">
            <div className="C-container-title">
              {currentConversation.data !== null
                ? currentConversation.data.channel.created_by.id === username
                  ? currentConversation.data.channel.name
                  : currentConversation.data.channel.created_by.id
                : "Select Chat"}
            </div>

            {currentConversation.image !== null ? (
              <img
                alt="User"
                className="C-P-list-Item-Image-chat"
                src={currentConversation.image}
              />
            ) : null}
          </div>
          {currentConversation.data !== null &&
          currentConversation.data.messages.length > 0 ? (
            <MessageBox
              chats={currentConversation.data.messages}
              currentImage={currentConversation.image}
              loadMore={loadMore}
              username={username}
              deleteMessage={deleteMessage}
              addReaction={addReaction}
              deleteReaction={deleteReaction}
              Reply={(Reply) => {
                messageInput.current.focus();
                setReply(Reply);
              }}
              submitReaction={submitReaction}
            />
          ) : null}
          {/* image preview section */}
          <div
            className="preview"
            style={{ display: fileUrl != null ? "block" : "none" }}
          >
            {fileUrl != null ? <img src={fileUrl} alt="preview" /> : null}
            <i
              className="fas fa-times-circle close"
              onClick={() => {
                setFileUrl(null);
                setFile(null);
              }}
            />
          </div>
          {/* show reply message content*/}
          {reply.user !== "" ? (
            <div className="reply-message">
              <i
                className="fas fa-times-circle close"
                onClick={() => {
                  setReply({ user: "", content: "" });
                }}
              />
              <span className="user">{reply.user}</span>
              <p>{reply.content}</p>
            </div>
          ) : null}
          {currentConversation.data !== null ? (
            <div
              className="C-chat-message-input-container"
              style={
                currentConversation.data.messages.length === 0
                  ? { marginTop: 20 }
                  : null
              }
            >
              <input
                className="C-chat-message-input  "
                type="text"
                ref={messageInput}
                value={message}
                placeholder="Enter your message here…"
                onChange={({ target }) => setMessage(target.value)}
                onKeyPress={(key) => {
                  if (key.key === "Enter") {
                    submit();
                  }
                }}
              />

              <div className="C-chat-message-buttons  ">
                <div className="space" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={getUrl}
                  ref={input}
                  style={{ display: "none" }}
                />
                <button
                  className="C-chat-message-btn"
                  onClick={() => input.current.click()}
                >
                  <PaperClip />
                </button>
                <div className="space" />
                <button
                  className="C-chat-message-btn"
                  onClick={() => {
                    submit();
                  }}
                >
                  <Send width="36" height="36" />
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className="col-md-3   ">
        <div className="H-items-container">
          <div className="  C-custom-left-title    ">
            <Team />
            <div style={{ marginLeft: 30 }}>Team Chat</div>
          </div>

          <div className="  C-custom-left-container team-line   "></div>
        </div>
      </div> */}
    </>
  );
};
export default CurrentChat;

import React, { useEffect, useRef, useState } from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import { StreamChat } from "stream-chat";
import friendService from "../services/friend.service";
import moment from "moment";
import Subnavigation from "../components/Subnavigation";
import Active from "../components/Chats/Active";
import ModalChats from "../components/Chats/ModalChats";
import CurrentChat from "../components/Chats/CurrentChat";
import { connect, useDispatch } from "react-redux";
import HeaderSection from "../components/HeaderSection";
import { useLocation } from "react-router-dom";
import { setLoading } from "../actions";
import { Notification } from "../hooks/NotificationStream";
const client = StreamChat.getInstance("td6s7tbp7n74");
const ChatPage = ({ user, navbarSize }) => {
  const [currentConversation, setCurrentConversation] = useState({
    data: null,
    image: null,
    index: null,
  });
  const [activeChats, setActiveChats] = useState([]);
  const conversationRef = useRef(currentConversation);
  const activeChatsRef = useRef(activeChats);
  const [showModal, setShowModal] = useState(false);
  const params = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    connectUser();
    Notification.set(false);
    // return () => client.disconnectUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const connectUser = async () => {
    const filter = { type: "messaging", members: { $in: [user.userName] } };
    const sort = [{ last_message_at: -1 }];
    let token = await localStorage.getItem("streamtoken");
    let expired = await localStorage.getItem("streamtokenExpired");
    // check if stream token expired
    if (token != null) {
      // if already expired
      if (moment(expired).isBefore(moment())) {
        token = await friendService.getStreamToken();
      }
    } else {
      let newtoken = await friendService.getStreamToken();
      token = newtoken.token;
    }
    await client
      .connectUser(
        {
          id: user.userName,
          name: user.userName,
        },
        token
      )
      .then(async () => {
        // get all channel (chats) from user
        let channelT = await client.queryChannels(filter, sort, {
          watch: true,
        });
        activeChatsRef.current = channelT;
        setActiveChats(channelT);
        // add chat if user is coming rom friend page
        if (params?.state?.user) {
          // check if channel is already created
          let res = channelT.filter(
            (it) =>
              it.id === `${params.state.user._id}--${user._id}` ||
              it.id === `${user._id}--${params.state.user._id}`
          );
          // select chat or create it
          if (res.length === 0) {
            addChat(params.state.user);
          } else {
            // get the index's converation
            let index = channelT.findIndex(
              (it) =>
                it.id === `${user._id}--${params.state.user._id}` ||
                it.id === `${params.state.user._id}--${user._id}`
            );
            selectChat(
              index,
              params.state.user.urlPhoto
                ? params.state.user.urlPhoto
                : `https://getstream.io/random_svg/?name=${params.state.user.userName}`,
              channelT
            );
          }
        }
        // // Listener for all events on client
        client.on(async (event) => {
          // new chat created.
          if (event.type === "notification.added_to_channel") {
            let newchannel = client.channel(
              event.channel_type,
              event.channel_id
            );
            activeChatsRef.current.push(newchannel);
            await client.queryChannels({ id: event.channel_id }, sort, {
              watch: true,
            });
            // select chat once it created
            if (params?.state?.user) {
              setTimeout(() => {
                selectChat(
                  activeChatsRef.current.length - 1,
                  params.state.user.urlPhoto
                    ? params.state.user.urlPhoto
                    : `https://getstream.io/random_svg/?name=${params.state.user.userName}`,
                  activeChatsRef.current
                );
              }, 1000);
            }
          }
          // new message
          if (
            event.type === "message.new" ||
            event.type === "notification.message_new"
          ) {
            // check if user has active the channel with the new message then show the new message
            if (
              conversationRef.current &&
              conversationRef.current.data !== null &&
              conversationRef.current.data.channel.id === event.channel_id
            ) {
              let messages = conversationRef.current.data.messages.slice();
              messages.push(event.message);
              conversationRef.current.data.messages = messages;
              setCurrentConversation({
                data: conversationRef.current.data,
                image: conversationRef.current.image,
                index: conversationRef.current.index,
              });
            } else {
              // if not show new alert message indicator
              setActiveChats(activeChatsRef.current.slice());
            }
            const obj = document.getElementById("messages-container");
            setTimeout(() => {
              obj.scrollTop = obj.scrollHeight;
            }, 300);
          }
          // when a user connect to the chat
          if (event.type === "user.watching.start") {
            let index = activeChatsRef.current.findIndex(
              (res) => res.id === event.channel_id
            );
            activeChatsRef.current[index].state.members[event.user.id].user =
              event.user;
            setActiveChats(activeChatsRef.current.slice());
          }
          if (event.type === "user.watching.stop") {
            let index = activeChatsRef.current.findIndex(
              (res) => res.id === event.channel_id
            );
            activeChatsRef.current[index].state.members[
              event.user.id
            ].user.online = false;
            setActiveChats(activeChatsRef.current.slice());
          }
          // listen new reaction or check if user reply a message or delete reaction
          if (
            event.type === "reaction.new" ||
            event.type === "member.updated" ||
            event.type === "reaction.deleted"
          ) {
            let index = activeChatsRef.current.findIndex(
              (res) => res.id === event.channel_id
            );
            // update messages
            const messages = await activeChatsRef.current[index].query({
              messages: {
                limit: conversationRef.current.data.messages.length,
              },
            });
            conversationRef.current.data.messages = messages.messages;
            setCurrentConversation({
              data: conversationRef.current.data,
              image: conversationRef.current.image,
              index: conversationRef.current.index,
            });
          }
          // check if user delete a message
          if (event.type === "message.deleted") {
            let index = conversationRef.current.data.messages.findIndex(
              (i) => i.id === event.message.id
            );
            if (index !== -1) {
              // remove message from array
              conversationRef.current.data.messages.splice(index, 1);
              setCurrentConversation({
                data: conversationRef.current.data,
                image: conversationRef.current.image,
                index: conversationRef.current.index,
              });
            }
          }
        });
      })
      .catch((e) => console.log(e));
  };
  const sendMessage = async (index, message, image) => {
    let fileURL = "";
    if (image !== null) {
      let res = await activeChats[index].sendImage(image);
      fileURL = res.file;
    }
    let payload = { message };
    if (fileURL !== "") {
      payload.image = fileURL;
    }
    await activeChats[index].sendMessage(payload);
    if (
      currentConversation.data &&
      currentConversation.data.messages?.length > 10
    ) {
      const obj = document.getElementById("messages-container");
      setTimeout(() => {
        obj.scrollTop = obj.scrollHeight;
      }, 300);
    }
  };
  const addChat = async (data) => {
    const channel = client.channel("messaging", `${data._id}--${user._id}`, {
      name: data.userName,
      members: [data.userName, user.userName],
    });
    await channel.create();
  };
  const selectChat = async (index, currentImage, chats = []) => {
    let result;
    // this is for user that come from friend page
    if (chats.length > 0) {
      result = await chats[index].query({
        messages: { limit: 10 },
      });
      // check if user has unread message. then mark as read
      if (chats[index].state.unreadCount > 0) {
        await chats[index].markRead();
      }
    } else {
      // user that select the converstation from the active chat
      result = await activeChats[index].query({
        messages: { limit: 10 },
      });
      // check if user has unread message. then mark as read
      if (activeChats[index].state.unreadCount > 0) {
        await activeChats[index].markRead();
      }
    }
    setCurrentConversation({
      data: result,
      image: currentImage,
      index: index,
    });
    conversationRef.current = {
      data: result,
      image: currentImage,
      index: index,
    };
    if (
      currentConversation.data &&
      currentConversation.data?.messages?.length > 10
    ) {
      const obj = document.getElementById("messages-container");
      setTimeout(() => {
        obj.scrollTop = obj.scrollHeight;
      }, 300);
    }
  };
  const LoadMore = async () => {
    const result = await activeChats[currentConversation.index].query({
      messages: { limit: 10, id_lt: currentConversation.data.messages[0].id },
    });
    // check if there are messages on the result
    if (result.messages.length > 0) {
      let messages = conversationRef.current.data.messages.slice();
      // add old message to top of the list
      messages.unshift(...result.messages);
      conversationRef.current.data.messages = messages;
      setCurrentConversation({
        ...currentConversation,
        data: conversationRef.current.data,
      });
    }
  };
  const replyMessage = async (content, message, image, index) => {
    dispatch(setLoading(true));
    let fileURL = "";
    if (image !== null) {
      let res = await activeChats[index].sendImage(image);
      fileURL = res.file;
    }
    let payload = {
      message,
      quoted_message_id: content.id,
    };
    if (fileURL !== "") {
      payload.image = fileURL;
    }
    await activeChats[index].sendMessage(payload);
    dispatch(setLoading(false));
  };
  const deleteMessage = async (id) => {
    dispatch(setLoading(true));
    let res = await client.deleteMessage(id, true);
    // check if message has a image then deleted
    if (res.message.image) {
      await activeChats[currentConversation.index].deleteImage(
        res.message.image
      );
    }
    dispatch(setLoading(false));
  };
  const addReaction = async (id, type, deleteOption) => {
    dispatch(setLoading(true));
    // delete previous reaction if have any
    if (deleteOption && deleteOption.length > 0) {
      await activeChats[currentConversation.index].deleteReaction(
        id,
        deleteOption[0].type
      );
    }
    await activeChats[currentConversation.index].sendReaction(id, {
      type: type,
      enforce_unique: true,
    });
    dispatch(setLoading(false));
  };
  const deleteReaction = async (id, type) => {
    dispatch(setLoading(true));
    await activeChats[currentConversation.index].deleteReaction(id, type);
    dispatch(setLoading(false));
  };
  return (
    <>
      <NavBar />
      <SideBar />
      <div
        className={
          navbarSize === "small" ? "custom-container2" : "custom-container"
        }
      >
        <div className="container-fluid">
          <HeaderSection
            subtitle="YOUR CHAT GROUPS"
            title="Your friends connected in one place"
          />
          <Subnavigation />
          <div className="row Chat-Page-custom-H">
            <div className="col-md-3">
              <Active
                allMyFriends={activeChats}
                selectChat={selectChat}
                currentChatSelected={currentConversation.index}
                showList={() => setShowModal(true)}
                username={user.userName}
              />
            </div>
            <CurrentChat
              currentConversation={currentConversation}
              loadMore={LoadMore}
              sendMessage={sendMessage}
              username={user.userName}
              Reply={replyMessage}
              deleteMessage={deleteMessage}
              deleteReaction={deleteReaction}
              addReaction={addReaction}
            />
          </div>
        </div>
      </div>
      <ModalChats
        addChat={addChat}
        chatList={activeChats}
        showList={showModal}
        hideList={() => setShowModal(false)}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return { user: state.auth.user, navbarSize: state.alerts.navbarSize };
};
export default connect(mapStateToProps)(ChatPage);

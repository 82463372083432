import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../assets/styles/Auth-css.css";
import countryAndCity from "../services/countries.service";
import userService from "../services/users";
import Header1 from "./Header1";
import { connect, useDispatch } from "react-redux";
import { setError, setLoading, updateProfile } from "../actions";

const CompleteProfile = ({ user }) => {
  const [username, setUsername] = useState(user.userName);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setlastName] = useState(user.lastName);
  const [Countrys, setCountrys] = useState([]);
  const [country, setCountry] = useState("");
  const [Citys, setCitys] = useState([]);
  const [city, setCity] = useState("");
  const [Stateprovince, setStateprovince] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const getAllCountrys = async () => {
    const respgetAllCountrys = await countryAndCity.getCountry();
    if (respgetAllCountrys.success) {
      setCountrys(respgetAllCountrys.data);
    }
  };

  const getAllCitys = async (val) => {
    const respgetAllCitys = await countryAndCity.getCity(val);
    if (respgetAllCitys.success) {
      setCitys(respgetAllCitys.data);
    } else {
      dispatch(
        setError("Something went wrong getting the state/province/city")
      );
    }
  };

  function validateFields() {
    if (username && firstName && lastName && country && Stateprovince && city) {
      updateInformationUser();
    } else {
      dispatch(setError("Fields required!"));
    }
  }

  const updateInformationUser = async () => {
    dispatch(setLoading(true));
    try {
      const respupdateInformationUser = await userService.CompleteProfile({
        userId: user._id,
        username,
        firstName,
        lastName,
        country,
        city,
      });
      if (respupdateInformationUser) {
        if (respupdateInformationUser.success === false) {
          dispatch(setError(respupdateInformationUser.data.message));
        } else {
          dispatch(setLoading(false));
          dispatch(updateProfile(respupdateInformationUser.data.data));
          history.push("/CompleteProfile2");
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(error?.message));
    }
  };

  const getListCities = function (Stateprovince) {
    const state = Citys.states.filter(
      (states) => states.name === Stateprovince
    );
    if (state.length === 0) {
      return [];
    }
    const cities = state[0].cities.map((City) => (
      <option key={City.id} value={City.name}>
        {City.name}
      </option>
    ));
    return cities;
  };

  useEffect(() => {
    getAllCountrys();
  }, []);
  return (
    <div className="Auth-pages-container Auth">
      <Header1 />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="containerTop">
              <div className="content1"></div>
              <div className="content2"></div>
            </div>
            <div
              className="title-signup"
              style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}
            >
              COMPLETE PROFILE
            </div>
          </div>
        </div>
        <div className="row dark-bg">
          <div className="col-md-6">
            <div className="fields">
              <input
                className="form-field"
                type="text"
                placeholder="Username"
                value={username}
                onChange={({ target }) => setUsername(target.value)}
              />
              <i className="fas fa-user" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="fields">
              <input
                className="form-field"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={({ target }) => setFirstName(target.value)}
              />
              <i className="fas fa-user" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="fields">
              <input
                className="form-field"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={({ target }) => setlastName(target.value)}
              />
              <i className="fas fa-user" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="fields">
              <select
                className="form-field"
                id="country"
                type="text"
                defaultValue="country"
                onChange={({ target }) => {
                  setCountry(target.value);
                  getAllCitys(target.value);
                }}
              >
                <option disabled="disabled" value="country">
                  Country
                </option>
                {Countrys.map((Country) => (
                  <option key={Country._id} value={Country._id}>
                    {Country.name}
                  </option>
                ))}
              </select>
              <i className="fas fa-flag" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="fields">
              <select
                className="form-field"
                id="country"
                type="text"
                defaultValue="state"
                onChange={({ target }) => setStateprovince(target.value)}
              >
                <option value="state" disabled="disabled">
                  State/province
                </option>
                {Citys.states !== undefined
                  ? Citys.states.map((Stateprovince, index) => (
                      <option key={index} value={Stateprovince._id}>
                        {Stateprovince.name}
                      </option>
                    ))
                  : null}
              </select>
              <i className="fas fa-flag" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="fields">
              <select
                className="form-field"
                id="city"
                type="text"
                defaultValue="city"
                onChange={({ target }) => setCity(target.value)}
              >
                <option value="city" disabled="disabled">
                  City
                </option>
                {(Citys.states !== undefined) & (Stateprovince !== "")
                  ? getListCities(Stateprovince)
                  : null}
              </select>
              <i className="fas fa-flag" />
            </div>
          </div>
          <div className="col-12" style={{ textAlign: "center" }}>
            <button className="btn--primary" onClick={validateFields}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { user: state.auth.user };
};
export default connect(mapStateToProps)(CompleteProfile);

import axios from "axios";

const getAssets = async (walletAddress) => {
    try {
        const endpoint = "https://api-ccdscan.mainnet.concordium.software/graphql";

        const query = `
            query ($address: String!) {
              accountByAddress(accountAddress: $address) {
                tokens(first: 10) {
                  nodes {
                    tokenId
                    token {
                      metadataUrl
                    }
                  }
                }
              }
            }
          `;

        const variables = {
            address: walletAddress,
            firstAccountReward: 10,
            firstAccountToken: 10,
            firstAccountStatement: 10,
        };

        try {
            const response = await axios.post(endpoint, {
                query,
                variables,
            });
            const fetchedNfts = await response?.data?.data?.accountByAddress?.tokens?.nodes;
            const metadataPromises = fetchedNfts.map(async (nft) => {
                try {
                    const metadataUrl = nft.token.metadataUrl.startsWith("https://d1790zh956hv5i.cloudfront.net")
                        ? nft.token.metadataUrl.replace("https://d1790zh956hv5i.cloudfront.net", "https://bucket-mana.s3.amazonaws.com")
                        : nft.token.metadataUrl;
                    const metadataResponse = await fetch(metadataUrl);
                    if (!metadataResponse.ok) {
                        throw new Error(`Failed to fetch metadata for token ${nft.tokenId}: ${metadataResponse.statusText}`);
                    }
                    const rawContent = await metadataResponse.text();
                    const metadata = JSON.parse(rawContent);
                    metadata.id = parseInt(nft.tokenId);
                    return metadata;
                } catch (error) {
                    console.error(`Error fetching metadata for token ${nft.tokenId}:`, error);
                    // Return a default metadata object in case of an error
                    return {
                        name: `Token ${nft.tokenId}`,
                        description: 'Metadata unavailable',
                    };
                }
            });
            const metadata = await Promise.all(metadataPromises);
            return metadata;
        } catch (error) {
            console.error("Error fetching Concordium NFTs:", error.message);
        };
    } catch (error) {
        console.error('Error fetching NFTs:', error);
        return [];
    }
};

const ccdServices = {
    getAssets,
}

export default ccdServices;

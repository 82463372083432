import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useManaWallet } from "../custom-providers/useWallet"
import { getGamesPlayed, setError, setLoading, setSuccess } from "../actions";
import Accept from "../assets/Accept";
import Cancel from "../assets/Cancel";
import Clock from "../assets/Clock";
import Game from "../assets/Game";
import Price from "../assets/Price";
import moment from "moment";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import challengeService from "../services/challenge.service";

const SingleChallenge = ({ navbarSize, user, gamerTag }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [active, setActive] = useState("invite");
  const [errorMsg, setErrorMsg] = useState(null);
  const [lobbyCode, setLobbyCode] = useState(null);
  const { walletAddress } = useManaWallet();

  const getChallenge = async (withRefreshBlockchain = false) => {
    dispatch(setLoading(true));
    let res = await challengeService.ChallengesById(id, withRefreshBlockchain);
    if (res.success) {
      setData(res.data);
      setLobbyCode(res.data.lobbyCode)
    } else {
      dispatch(setError(res.data.message));
    }
    dispatch(setLoading(false));
    dispatch(getGamesPlayed());
  };
  const getLobbyCode = async () => {
    if (data.challenged.length === 2) {
      try {
        if (data.lobbyCode) {
          await navigator.clipboard.writeText(data.lobbyCode);
          showError("Lobby code copied to clipboard!");
        } else {
          const response = await challengeService.createLobbyForChallenge(data._id);
          if (response?.data?.lobbyCode) {
            setLobbyCode(response.data.lobbyCode);
            showError("New lobby code created");
          } else {
            showError("Lobby code not found in the response");
          }
        }
      } catch (err) {
        showError(err.message || "An unexpected error occurred");
      }
    } else {
      showError('Both players need to be part of the Challenge');
    }
  };

  const showError = (message) => {
    setErrorMsg(message);
    setTimeout(() => setErrorMsg(null), 5000);
  };
  const accept = async () => {
    let hasGame = gamerTag.filter((res) => res.game === data.game._id);
    if (hasGame.length === 0) {
      dispatch(
        setError(
          "You can't join this challenge because you do not have a gamer tag for this game"
        )
      );
      return;
    }
    dispatch(setLoading(true));
    let res = await challengeService.AcceptChallenge(data._id, walletAddress);
    dispatch(setLoading(false));
    if (res.success) {
      dispatch(setSuccess("Challenge accepted"));
      getChallenge();
    } else {
      dispatch(setError(res.data.message));
    }
  };
  const reject = async () => {
    dispatch(setLoading(true));
    let res = await challengeService.RejectChallenge(data._id);
    dispatch(setLoading(false));
    getChallenge();
    if (res.success) {
      dispatch(setSuccess("Challenge rejected"));
    } else {
      dispatch(setError(res.data?.message));
    }
  };
  const checkPublic = () => {
    let res = data.challenged.filter((it) => it._id === user._id);
    if (res.length > 0) {
      return false;
    }
    return true;
  };
  const getUserPosition = () => {
    let res = data.challenged.findIndex((it) => it._id === user._id);
    return res;
  };

  const cancel = async () => {
    dispatch(setLoading(true));
    let res = await challengeService.cancel(id);
    dispatch(setLoading(false));
    if (res.success) {
      dispatch(setSuccess("Challenge has been cancelled"));
      getChallenge();
    } else {
      if (res.data.message === "the challenge can't cancel") {
        dispatch(setError("The challenge already started, you can't cancel it"));
      } else {
        dispatch(setError(res.data.message));
      }
    }
  };
  useEffect(() => {
    getChallenge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWinner = () => {
    const challengerWinner = data.challenged.find((user) => user._id === data?.winner);
    return challengerWinner;
  }
  return (
    <>
      <NavBar />
      <SideBar />
      <div
        className={
          navbarSize === "small" ? "custom-container2" : "custom-container"
        }
      >
        {data !== null ? (
          <div className="container-fluid single-content">
            <div className="row header-tab-title">
              <div className="col-md-8">
                <h4 className="subtitle">Challenge</h4>
                <h2 className="title">
                  {data.name}
                  {typeof data.coinToken === "string"
                    ? " - " + data.coinToken
                    : ""}
                </h2>
                {errorMsg && (
                  <div className="error-prompt">
                    {errorMsg}
                  </div>
                )}
              </div>
              <div className="col-md-4">

                <div>
                  <h6
                    className="text-white"
                    style={{
                      textAlign: "center",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    This Challenge Uses {` ${data.coinToken} `}
                  </h6>
                </div>
                {user._id === data.creator._id && data.status === "pending" ? (
                  <div className="col-md-12 text-center">
                    <button className="btn-pink-op" onClick={cancel}>
                      Cancel Challenge <Cancel />
                    </button>
                  </div>
                ) : null}
              </div>
              {user._id !== data.creator._id &&
                getUserPosition() >= 0 &&
                !data.challenged[getUserPosition()].isChallengeAccepted &&
                (data.status === "pending" || data.status === "accepted") ? (
                <div className="col-md-4">
                  <button className="btn-pink-op" onClick={reject}>
                    Decline Challenge <Cancel />
                  </button>
                  <button className="btn-green-op" onClick={accept}>
                    Accept Challenge <Accept />
                  </button>
                </div>
              ) : null}
              {data.isPublic && checkPublic() ? (
                <>
                  {(data.status !== "finalized") &
                    (data.status !== "expired") ? (
                    <div className="col-md-4">
                      <button className="btn-green-op" onClick={accept}>
                        Accept Challenge <Accept />
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </div>
            </div>
            <div className="row bg-gray">
              <div className="col-md-12 d-flex">
                <div className="challenge-info orange">
                  <Price color="#FF8547" />
                  {"  "}{data.amount*1.8}
                  <span>Total Prize Pool</span>
                </div>
                <div className="challenge-info blue">
                  <Clock />
                  {moment(data.startDate).format(
                    "DD MMM hh:mm a"
                  )}{" "}
                  (EST)
                  <span>Challenge Start</span>
                </div>
                <div className="challenge-info green" onClick={getLobbyCode}>
                  <Game /> Get Lobby Code
                  <span>{lobbyCode ? lobbyCode : "******"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              {getWinner() ? (
                <div className="mt-4 mb-4 col-md-12">
                  <div className="winner">
                    <div className="winner-title">
                      <h4 className="subtitle">Winner</h4>
                      <h2 className="title">
                        {getWinner()?.userName}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="col-md-6">
                <div className="tab">
                  <div className="tab-header">
                    <span
                      className={active === "invite" ? "active" : null}
                      onClick={() => setActive("invite")}
                    >
                      Invited People
                    </span>
                  </div>
                  <div
                    className={`tab-body ${active !== "invite" ? "hide" : ""}`}
                  >
                    {data.challenged.map((item, index) => (
                      <div className="C-P-list-Item" key={index}>
                        <div>
                          <img
                            alt="user"
                            className="C-P-list-Item-Image"
                            src={
                              item.urlPhoto
                                ? item.urlPhoto
                                : `https://getstream.io/random_svg/?name=${item.userName}`
                            }
                          />
                        </div>
                        <div className="C-P-list-Item-txt-container">
                          <div className="C-P-list-Item-txt">
                            <div className="C-P-list-Item-tv">
                              <div className="C-P-list-txt-title">
                                <div className="C-P-list-username">
                                  {item.gamerTag}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {data.creator._id === item._id ? (
                          <div
                            className="btn-green-op"
                            style={{ fontSize: 12 }}
                          >
                            Accepted
                          </div>
                        ) : (
                          <div
                            className={`${item.isRejected
                              ? "btn-pink-op"
                              : item.isChallengeAccepted
                                ? "btn-green-op"
                                : "btn-orange-op"
                              }`}
                            style={{ fontSize: 12 }}
                          >
                            {item.isRejected
                              ? "Rejected"
                              : item.isChallengeAccepted
                                ? "Accepted"
                                : "Invited"}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div
                    className={`tab-body ${active !== "match" ? "hide" : ""}`}
                  >
                    <div className="containerlabel">
                      <div className="ST-inputs-title" style={{ fontSize: 14 }}>
                        Results
                      </div>
                    </div>
                    <ul
                      style={{
                        color: "white",
                        paddingLeft: 0,
                        listStyle: "none",
                      }}
                    >
                      {data.challenged.map((item, indx) => (
                        <li key={indx} style={{ marginBottom: 10 }}>
                          <img
                            alt="user"
                            className="C-P-list-Item-Image"
                            src={
                              item.urlPhoto
                                ? item.urlPhoto
                                : `https://getstream.io/random_svg/?name=${item.userName}`
                            }
                          />{" "}
                          {item.gamerTag} <b>{item.myScore}</b>{" "}
                          {item.winner === item._id ? (
                            <i
                              className="fa fa-trophy"
                              style={{ color: "#7bed9f" }}
                            />
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 img-content">
                <img
                  src={data.urlImage}
                  className="img imgChallenge"
                  alt="pict"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    navbarSize: state.alerts.navbarSize,
    challenges: state.data.challenges,
    gamerTag: state.data.gamerTags,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(SingleChallenge);
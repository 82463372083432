"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionKindString = void 0;
var TransactionKindString;
(function (TransactionKindString) {
    TransactionKindString["DeployModule"] = "deployModule";
    TransactionKindString["InitContract"] = "initContract";
    TransactionKindString["Update"] = "update";
    TransactionKindString["Transfer"] = "transfer";
    TransactionKindString["AddBaker"] = "addBaker";
    TransactionKindString["RemoveBaker"] = "removeBaker";
    TransactionKindString["UpdateBakerStake"] = "updateBakerStake";
    TransactionKindString["UpdateBakerRestakeEarnings"] = "updateBakerRestakeEarnings";
    TransactionKindString["UpdateBakerKeys"] = "updateBakerKeys";
    TransactionKindString["UpdateCredentialKeys"] = "updateCredentialKeys";
    TransactionKindString["BakingReward"] = "bakingReward";
    TransactionKindString["BlockReward"] = "blockReward";
    TransactionKindString["FinalizationReward"] = "finalizationReward";
    TransactionKindString["EncryptedAmountTransfer"] = "encryptedAmountTransfer";
    TransactionKindString["TransferToEncrypted"] = "transferToEncrypted";
    TransactionKindString["TransferToPublic"] = "transferToPublic";
    TransactionKindString["TransferWithSchedule"] = "transferWithSchedule";
    TransactionKindString["UpdateCredentials"] = "updateCredentials";
    TransactionKindString["RegisterData"] = "registerData";
    TransactionKindString["TransferWithMemo"] = "transferWithMemo";
    TransactionKindString["EncryptedAmountTransferWithMemo"] = "encryptedAmountTransferWithMemo";
    TransactionKindString["TransferWithScheduleAndMemo"] = "transferWithScheduleAndMemo";
    TransactionKindString["ConfigureBaker"] = "configureBaker";
    TransactionKindString["ConfigureDelegation"] = "configureDelegation";
    TransactionKindString["StakingReward"] = "paydayAccountReward";
    TransactionKindString["Failed"] = "failed";
})(TransactionKindString = exports.TransactionKindString || (exports.TransactionKindString = {}));

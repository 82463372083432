import * as React from "react";

const Dots = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 3.859}
    height={props.height ? props.height : 15.437}
    viewBox="0 0 3.859 15.437"
    {...props}
  >
    <g fill="#fff">
      <path
        data-name="Trazado 69989"
        d="M1.929 3.859a1.93 1.93 0 1 1 1.93-1.929 1.929 1.929 0 0 1-1.93 1.93"
      />
      <path
        data-name="Trazado 69990"
        d="M1.929 9.648a1.93 1.93 0 1 1 1.93-1.929 1.93 1.93 0 0 1-1.93 1.93"
      />
      <path
        data-name="Trazado 69991"
        d="M1.929 15.437a1.93 1.93 0 1 1 1.93-1.929 1.93 1.93 0 0 1-1.93 1.93"
      />
    </g>
  </svg>
);

export default Dots;

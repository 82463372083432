import React from "react";
import "../../assets/styles/Settings-Chatpage.css";

const FriendList = ({
  allMyFriends,
  userfound,
  onSelect,
  search,
  chatList,
}) => {
  const hide = (MyFriends) => {
    let result = chatList.filter(
      (res) => res.state.members[MyFriends.userName]
    );
    return result.length > 0 ? false : true;
  };
  const myFriends = () => {
    return (
      <>
        {allMyFriends.length > 0 ? (
          allMyFriends.map((MyFriends, index) =>
            hide(MyFriends) ? (
              <div
                tabIndex="1"
                className="C-P-list-Item"
                key={index}
                onClick={() => onSelect(MyFriends)}
                data-bs-dismiss="modal"
              >
                <div className="C-P-list-Item-image-container  ">
                  <img
                    alt="user"
                    className="C-P-list-Item-Image"
                    src={
                      MyFriends.urlPhoto
                        ? MyFriends.urlPhoto
                        : `https://getstream.io/random_svg/?name=${MyFriends.userName}`
                    }
                  />
                </div>

                <div className="C-P-list-Item-txt-container">
                  <div className="C-P-list-Item-txt">
                    <div className="C-P-list-Item-tv">
                      <div className="C-P-list-txt-title">
                        <div className="C-P-list-username">
                          {MyFriends.userName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <i className="far fa-comments H-custom-i"></i>
              </div>
            ) : null
          )
        ) : (
          <div className="dont-have-friends">
            you don't have any friends added yet
          </div>
        )}
      </>
    );
  };

  const friendFound = () => {
    return (
      <>
        {userfound.length > 0 ? (
          userfound.map((Friend, index) => (
            <div
              tabIndex="1"
              className="C-P-list-Item"
              key={index}
              onClick={onSelect}
              data-bs-dismiss="modal"
            >
              <div className="C-P-list-Item-image-container  ">
                <img
                  alt="user"
                  className="C-P-list-Item-Image"
                  src={
                    Friend.urlPhoto
                      ? Friend.urlPhoto
                      : "https://media.istockphoto.com/photos/happy-smiling-africanamerican-child-girl-yellow-background-picture-id1168369629?k=20&m=1168369629&s=612x612&w=0&h=cOqiMS2ibSZPXOTy6OkRZrGJRUNmIKkv89iVO4TjW3M="
                  }
                />
              </div>

              <div className="C-P-list-Item-txt-container  ">
                <div className="C-P-list-Item-txt">
                  <div className="C-P-list-Item-tv">
                    <div className="C-P-list-txt-title">
                      <div className="C-P-list-username">
                        {Friend.userName ? Friend.userName : Friend.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="dont-have-friends">user not found</div>
        )}
      </>
    );
  };

  return (
    <>{userfound.length > 0 && search !== "" ? friendFound() : myFriends()}</>
  );
};
export default FriendList;

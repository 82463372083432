import * as React from "react";

const Accept = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 15.963}
    height={props.height ? props.height : 15.963}
    viewBox="0 0 95.963 95.963"
    {...props}
  >
    <path
      d="M47.982 7.997a39.985 39.985 0 1 0 39.984 39.985A40.051 40.051 0 0 0 47.982 7.997Zm19.115 30.788L44.423 61.456a2.992 2.992 0 0 1-4.238 0L28.869 50.141a3 3 0 0 1 4.238-4.238l9.2 9.2 20.549-20.556a3 3 0 0 1 4.241 4.238Z"
      fill={props.color ? props.color : "#54b947"}
      data-name="vuesax/bold/tick-circle"
    />
  </svg>
);

export default Accept;

import { getData, pathData, postData } from "../apis";

const sendFriendRequest = async (idCognito) => {
  return postData(`/v1/request`, { userId: idCognito });
};

const getAllRequest = async (page, size, type = "pending") => {
  return getData(`/v1/request?page=${page}&size=${size}&type=${type}`);
};

const markAsSeenAll = async () => {
  return pathData("/v1/request/mark-as-seen-all");
};

const markAsSeen = async (requestId) => {
  return pathData(`/v1/request/mark-as-seen${requestId}`);
};

const acceptRequest = async (requestId) => {
  return pathData(`/v1/request/accept/${requestId}`);
};

const rejectRequest = async (requestId) => {
  return pathData(`/v1/request/reject/${requestId}`);
};

const getAllFriend = async (page = 1, size = 25) => {
  // no params?
  return getData(`/v1/request/friends?page=${page}&size=${size}`);
};

const searchFriend = async (username) => {
  return getData(`/v1/request/search/friends?username=${username}`);
};

const cancelRequest = async (requestId) => {
  return pathData(`/v1/request/cancel/${requestId}`);
};
const getNotifications = () => {
  return getData(`/v1/request/notifications?page=1&size=10`);
};

const getStreamToken = async () => {
  const data = await getData("/v1/user/getstream/token");
  if (data.success) {
    localStorage.setItem("streamtokenExpired", data.data.expireAt);
    localStorage.setItem("streamtoken", data.data.token);
  }
  return data;
};

const friendService = {
  sendFriendRequest,
  getAllRequest,
  markAsSeenAll,
  markAsSeen,
  acceptRequest,
  rejectRequest,
  getAllFriend,
  searchFriend,
  cancelRequest,
  getNotifications,
  getStreamToken
};

export default friendService;
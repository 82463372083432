/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import countryAndCity from "../services/countries.service";
import userService from "../services/users";
import "./../assets/styles/RegisterGamerProfile.css";
import S3 from "../services/Aws/s3";
import gameService from "../services/games.service";
import { connect, useDispatch } from "react-redux";
import {
  getGamesPlayed,
  setError,
  setLoading,
  setSuccess,
  updateProfile,
} from "../actions";
import User from "./../assets/user2.jpg";
import { getData } from "../apis";
import axios from "axios";

const RegisterGamerProfile = ({ user, Games, gamerTags }) => {
  const dispatch = useDispatch();
  const imgRef = useRef();
  const [gamePlayedArry, setGamPlayedArry] = useState(gamerTags);
  const hasFetchedOnce = useRef(false);
  const [FormUpdateUser, setFormUpdateUser] = useState({
    userName: user.userName,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    country: user.country,
    city: user.city,
    imgUser: user.urlPhoto,
  });

  const [Countrys, setCountrys] = useState([]);
  const [City, setCity] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);
  const [autosave, setAutoSave] = useState(false);
  const [items, setItems] = useState([]);
  //   verifiable: "",
  //   game: "",
  //   id: "",
  //   tag: "",
  //   // platform: "",
  // });

  const getUsernameOneTap = async (localTags) => {
    try {
      const getUsername = await axios.get(
        `https://api.qorbiworld.com/sphere-one/user/passport/${user.email}`
      );

      if (getUsername && localTags.length < 1) {

        addNewGameTag(getUsername.data.username, localTags)
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const sendPictures3 = async (urlPhoto) => {
    dispatch(setLoading(true));
    try {
      if (urlPhoto !== null) {
        await S3.sendImage(urlPhoto, function (data) {
          if (data) {
            updateUserInformation(data.Location);
          }
        });
      } else {
        if (
          (FormUpdateUser.urlPhoto.length !== 0) &
          (FormUpdateUser.urlPhoto !== undefined)
        ) {
          await S3.sendImage(FormUpdateUser.urlPhoto, function (data) {
            if (data) {
              updateUserInformation(data.Location);
            }
          });
        }
      }
    } catch (error) {
      dispatch(setError("Something went wrong uploading the image."));
      console.error("error con s3", error);
    }
  };

  const getAllCountrys = async () => {
    dispatch(setLoading(true));
    const respgetAllCountrys = await countryAndCity.getCountry();
    if (respgetAllCountrys.success) {
      setCountrys(respgetAllCountrys.data);
      dispatch(setLoading(false));
    } else {
      dispatch(setError(respgetAllCountrys.data.message));
    }
  };

  const getAllCitys = async (country) => {
    dispatch(setLoading(true));
    const respgetAllCitys = await countryAndCity.getCity(country);
    if (respgetAllCitys.success) {
      setCity(respgetAllCitys.data);
      dispatch(setLoading(false));
    } else {
      dispatch(setError(respgetAllCitys.data.message));
    }
  };

  const updateUserInformation = async (urlImages3) => {
    dispatch(setLoading(true));
    const respupdateUserInformation = await userService.updateUser(
      user._id,
      FormUpdateUser,
      urlImages3 ? urlImages3 : ""
    );
    if (respupdateUserInformation.success) {
      dispatch(updateProfile(respupdateUserInformation.data.data));
      dispatch(setSuccess("Updated Player Profile"));
    } else {
      dispatch(setError(respupdateUserInformation.data.message));
    }
  };

  const processImage = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile !== undefined) {
      setFormUpdateUser({
        ...FormUpdateUser,
        urlPhoto: event.target.files[0],
      });

      const imageUrl = URL.createObjectURL(imageFile);
      setFileUrl(imageUrl);
    }
    if (autosave) {
      ChangePhoto(event.target.files[0]);
      setAutoSave(false);
    }
  };

  const ChangePhoto = (urlPhoto = null) => {
    if (FormUpdateUser.urlPhoto !== "") {
      sendPictures3(urlPhoto);
    }
  };

  //get list games
  const games = async () => {
    dispatch(setLoading(true));
    dispatch(getGamesPlayed());
  };

  useEffect(() => {
    games();
    getAllCountrys();
    const currentCountry = FormUpdateUser.country;
    if (currentCountry) {
      getAllCitys(currentCountry);
    }
  }, []);
  useEffect(() => {
    setGamPlayedArry(gamerTags);
    if (hasFetchedOnce.current) {
      getUsernameOneTap(gamerTags)
    }
    hasFetchedOnce.current = true
  }, [gamerTags]);

  const addNewGameTag = async (qorbiTag, localTags) => {

    let isRep = localTags.filter((it) => it.game === '64a4b1c25c70df84b1698d36');

    if (isRep.length > 0) {
      return;
    }

    dispatch(setLoading(true));
    // Validate if the gametag exists in onetap
    // game: "64a4b1c25c70df84b1698d36"
    const res = await gameService.addNewGamerTag('64a4b1c25c70df84b1698d36', qorbiTag);
    if (!res.success) {
      dispatch(
        setError(
          "Something went wrong trying to add the tag. Please try again."
        )
      );
      return;
    }
    dispatch(getGamesPlayed());
    dispatch(setLoading(false));
  };

  const removeGameTag = async (index, id) => { // Do not remove this function
    dispatch(setLoading(true));
    let res = await gameService.deleteGamerTag(id);
    if (res.success) {
      let newarray = gamePlayedArry.slice();
      newarray.splice(index, 1);
      setGamPlayedArry(newarray);
    } else {
      dispatch(setError(res.data.message));
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    const getComments = async () => {
      const res = await getData(`/v1/games/get/all?page=1`);
      if (res) {
        const data1 = res.data.results;
        const res2 = await getData(`/v1/games/get/all?page=2`);
        if (res2) {
          const data2 = res2.data.results;
          const filterGames = [...data1, ...data2];
          const leakedGames = filterGames.filter(function (Games) {
            return Games.toCreateChallenges === true;
          });
          setItems(leakedGames);
        }
      }
    };

    getComments();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="profile-img-container">
            <img
              src={
                fileUrl
                  ? fileUrl
                  : FormUpdateUser.imgUser
                    ? FormUpdateUser.imgUser
                    : User
              }
              alt="profile"
              onClick={() => imgRef.current.click()}
            />
            <input
              id="photo"
              type="file"
              style={{ display: "none" }}
              ref={imgRef}
              onChange={processImage}
              accept="image/*"
            />
            <button
              onClick={() => {
                if (fileUrl === null) {
                  imgRef.current.click();
                  setAutoSave(true);
                } else {
                  ChangePhoto();
                }
              }}
              className="btn-white-trans"
            >
              Update Profile Photo
            </button>
          </div>
          <div className="gamestag">
            {items.length > 0
              ? gamePlayedArry.length > 0
                ? gamePlayedArry.map((item, index) => (
                  <div className="cont" key={index}>
                    <h3>One Tap</h3>
                    <div className="pill">
                      <div>
                        {item.tag}
                      </div>
                      {/* <span
                        onClick={() => removeGameTag(index, item._id)}
                        className="close"
                      >
                        X
                      </span> */}
                    </div>
                  </div>
                ))
                : null
              : null}
          </div>

        </div>
        <div className="col-md-6">
          <div className="field-type2-cont">
            <label htmlFor="UserName">User Name</label>
            <div className="field-type2">
              <input
                disabled
                value={FormUpdateUser.userName}
                id="UserName"
                type="text"
                placeholder="Example User Name"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    userName: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="email">Email</label>
            <div className="field-type2">
              <input
                disabled
                value={FormUpdateUser.email}
                id="email"
                type="text"
                placeholder="Email@Example.com"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    email: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="first name">First Name</label>
            <div className="field-type2">
              <input
                value={FormUpdateUser.firstName}
                id="first name"
                type="text"
                placeholder="First Name"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    firstName: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="last name">Last Name</label>
            <div className="field-type2">
              <input
                value={FormUpdateUser.lastName}
                id="last name"
                type="text"
                placeholder="Last Name"
                className="ST-input-l"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    lastName: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="country">Country</label>
            <div className="field-type2">
              <select
                id="country"
                value={FormUpdateUser.country}
                type="text"
                className="ST-l-Selecte"
                onChange={({ target }) => {
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    country: target.value,
                  });
                  getAllCitys(target.value);
                }}
              >
                <option key={""} value={""}></option>
                {Countrys.map((Country) => (
                  <option key={Country._id} value={Country._id}>
                    {Country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="field-type2-cont">
            <label htmlFor="city">State/Department</label>
            <div className="field-type2">
              <select
                id="city"
                value={FormUpdateUser.city}
                type="text"
                className="ST-l-Selecte"
                onChange={({ target }) =>
                  setFormUpdateUser({
                    ...FormUpdateUser,
                    city: target.value,
                  })
                }
              >
                <option key={"4582946"} value={""}>
                  {FormUpdateUser.city}
                </option>
                {City.states !== undefined
                  ? City.states
                    .filter((city) => city.name !== FormUpdateUser.city)
                    .map((City) => (
                      <option key={City.id} value={City.name}>
                        {City.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <button
            onClick={() => updateUserInformation()}
            className="btn-blue"
            style={{ marginBottom: "20px" }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    navbarSize: state.alerts.navbarSize,
    Games: state.data.games,
    gamerTags: state.data.gamerTags,
  };
};
export default connect(mapStateToProps)(RegisterGamerProfile);

const MarketItems = ({ item }) => {
    return (
        <div className="market-item-container">
            <div className="nft-card">
                <div className="nft-card-image-container">
                    <div
                        className="nft-bg"
                        style={{ backgroundImage: `url(${item.image})` }}
                    ></div>
                </div>
                <div className="nft-card-content">
                    <h4 className="nft-card-title pixelFont">
                        ZK {item.name}
                    </h4>
                    <p className="nft-card-description">
                        {item.description}
                    </p>
                </div>
            </div>
            <div className="nft-card-actions">
                <button
                    className="nft-card-button pixelFont"
                    onClick={() => window.open(item.coinbaseUrl, "_blank")}
                >
                    Mint Now
                </button>
                {/* {item.moreUrl && (
                    <a
                        className="nft-card-button nft-card-link"
                        href={}
                        target="_blank" rel="noreferrer"
                    >
                        Learn More
                    </a>
                )} */}
            </div>
        </div>
    );
};

export default MarketItems;
import React, { useState, useEffect } from "react";
import { getData } from "../apis/index";
import { Markup } from "interweave";
import { useHistory } from "react-router-dom";

export default function TermsPrivacy() {
  const [terms, setTerms] = useState("");
  const history = useHistory();

  useEffect(() => {
    const fetchTerms = async () => {
      const response = await getData("/v1/terms-privacy/get");
      setTerms(response.data[0].content);
    };

    fetchTerms();
  }, []);

  return (
    <div className="containerTermsAndConditions">
      <Markup content={terms} />
      <p className="return" onClick={() => history.push("/")}>
        return to Sign Up Page
      </p>
    </div>
  );
}

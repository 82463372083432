import React from "react";

const Reactions = ({ addReaction }) => {
  return (
    <ul className="reaction">
      <li onClick={() => addReaction("thumb")}>
        <i className="fa fa-thumbs-up thumb" />
      </li>
      <li onClick={() => addReaction("laugh")}>
        <i className="fas fa-laugh-squint face" />
      </li>
      <li onClick={() => addReaction("love")}>
        <i className="fa fa-heart heart" />
      </li>
      <li onClick={() => addReaction("sad")}>
        <i className="fa fa-sad-cry face" />
      </li>
      <li onClick={() => addReaction("suprice")}>
        <i className="fa fa-surprise face" />
      </li>
    </ul>
  );
};

export default Reactions;

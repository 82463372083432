import React, { useState } from "react";
import { TableTXT } from "./TableTXT";
import { useManaWallet } from "../../custom-providers/useWallet";
const greyedOutStyle = {
  filter: "grayscale(25%)",
  opacity: 0.5,
};

export const PanelWallet = () => {
  const [pressedButton, setPressedButton] = useState("transaction");
  const { coinToken } = useManaWallet();
  const handleButton = (buttonName) => {
    setPressedButton(buttonName);
  };

  return (
    <div className="heightTable">
      <div style={{ display: "flex" }}>
        <div
          className="tab-wallet"
          style={{
            marginRight: "20px",
            ...(pressedButton === "transfer" ? greyedOutStyle : {}),
          }}
          onClick={() => handleButton("transaction")}
        >
          Transactions
        </div>
        {coinToken === "ZK" && (
          <div
            className="tab-wallet greyed-out"
            style={{
              marginLeft: "20px",
              ...(pressedButton === "transaction" ? greyedOutStyle : {}),
            }}
            onClick={() => handleButton("transfer")}
          >
            Transfers
          </div>
        )}
      </div>

      <div className="tableInfo">
        <TableTXT pressedButton={pressedButton} />
      </div>
    </div>
  );
};

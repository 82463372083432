// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/service.proto" (package "concordium.v2", syntax proto3)
// tslint:disable
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueriesClient = void 0;
const service_1 = require("./service");
const runtime_rpc_1 = require("@protobuf-ts/runtime-rpc");
/**
 * @generated from protobuf service concordium.v2.Queries
 */
class QueriesClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = service_1.Queries.typeName;
        this.methods = service_1.Queries.methods;
        this.options = service_1.Queries.options;
    }
    /**
     * Return a stream of blocks that arrive from the time the query is made onward.
     * This can be used to listen for incoming blocks.
     *
     * @generated from protobuf rpc: GetBlocks(concordium.v2.Empty) returns (stream concordium.v2.ArrivedBlockInfo);
     */
    getBlocks(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Return a stream of blocks that are finalized from the time the query is
     * made onward. This can be used to listen for newly finalized blocks. Note
     * that there is no guarantee that blocks will not be skipped if the client is
     * too slow in processing the stream, however blocks will always be sent by
     * increasing block height.
     *
     * @generated from protobuf rpc: GetFinalizedBlocks(concordium.v2.Empty) returns (stream concordium.v2.FinalizedBlockInfo);
     */
    getFinalizedBlocks(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Retrieve the information about the given account in the given block.
     *
     * @generated from protobuf rpc: GetAccountInfo(concordium.v2.AccountInfoRequest) returns (concordium.v2.AccountInfo);
     */
    getAccountInfo(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve the list of accounts that exist at the end of the given block.
     *
     * @generated from protobuf rpc: GetAccountList(concordium.v2.BlockHashInput) returns (stream concordium.v2.AccountAddress);
     */
    getAccountList(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get a list of all smart contract modules. The stream will end
     * when all modules that exist in the state at the end of the given
     * block have been returned.
     *
     * @generated from protobuf rpc: GetModuleList(concordium.v2.BlockHashInput) returns (stream concordium.v2.ModuleRef);
     */
    getModuleList(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get a stream of ancestors for the provided block.
     * Starting with the provided block itself, moving backwards until no more
     * ancestors or the requested number of ancestors has been returned.
     *
     * @generated from protobuf rpc: GetAncestors(concordium.v2.AncestorsRequest) returns (stream concordium.v2.BlockHash);
     */
    getAncestors(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the source of a smart contract module.
     *
     * @generated from protobuf rpc: GetModuleSource(concordium.v2.ModuleSourceRequest) returns (concordium.v2.VersionedModuleSource);
     */
    getModuleSource(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get a list of addresses for all smart contract instances. The stream
     * will end when all instances that exist in the state at the end of the
     * given block has been returned.
     *
     * @generated from protobuf rpc: GetInstanceList(concordium.v2.BlockHashInput) returns (stream concordium.v2.ContractAddress);
     */
    getInstanceList(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get info about a smart contract instance as it appears at the end of the
     * given block.
     *
     * @generated from protobuf rpc: GetInstanceInfo(concordium.v2.InstanceInfoRequest) returns (concordium.v2.InstanceInfo);
     */
    getInstanceInfo(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the exact state of a specific contract instance, streamed as a list of
     * key-value pairs. The list is streamed in lexicographic order of keys.
     *
     * @generated from protobuf rpc: GetInstanceState(concordium.v2.InstanceInfoRequest) returns (stream concordium.v2.InstanceStateKVPair);
     */
    getInstanceState(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the value at a specific key of a contract state. In contrast to
     * `GetInstanceState` this is more efficient, but requires the user to know
     * the specific key to look for.
     *
     * @generated from protobuf rpc: InstanceStateLookup(concordium.v2.InstanceStateLookupRequest) returns (concordium.v2.InstanceStateValueAtKey);
     */
    instanceStateLookup(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the best guess as to what the next account sequence number should be.
     * If all account transactions are finalized then this information is reliable.
     * Otherwise this is the best guess, assuming all other transactions will be
     * committed to blocks and eventually finalized.
     *
     * @generated from protobuf rpc: GetNextAccountSequenceNumber(concordium.v2.AccountAddress) returns (concordium.v2.NextAccountSequenceNumber);
     */
    getNextAccountSequenceNumber(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get information about the current state of consensus.
     *
     * @generated from protobuf rpc: GetConsensusInfo(concordium.v2.Empty) returns (concordium.v2.ConsensusInfo);
     */
    getConsensusInfo(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the status of and information about a specific block item (transaction).
     *
     * @generated from protobuf rpc: GetBlockItemStatus(concordium.v2.TransactionHash) returns (concordium.v2.BlockItemStatus);
     */
    getBlockItemStatus(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the cryptographic parameters in a given block.
     *
     * @generated from protobuf rpc: GetCryptographicParameters(concordium.v2.BlockHashInput) returns (concordium.v2.CryptographicParameters);
     */
    getCryptographicParameters(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get information, such as height, timings, and transaction counts for the given block.
     *
     * @generated from protobuf rpc: GetBlockInfo(concordium.v2.BlockHashInput) returns (concordium.v2.BlockInfo);
     */
    getBlockInfo(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get all the bakers at the end of the given block.
     *
     * @generated from protobuf rpc: GetBakerList(concordium.v2.BlockHashInput) returns (stream concordium.v2.BakerId);
     */
    getBakerList(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get information about a given pool at the end of a given block.
     *
     * @generated from protobuf rpc: GetPoolInfo(concordium.v2.PoolInfoRequest) returns (concordium.v2.PoolInfoResponse);
     */
    getPoolInfo(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get information about the passive delegators at the end of a given block.
     *
     * @generated from protobuf rpc: GetPassiveDelegationInfo(concordium.v2.BlockHashInput) returns (concordium.v2.PassiveDelegationInfo);
     */
    getPassiveDelegationInfo(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get a list of live blocks at a given height.
     *
     * @generated from protobuf rpc: GetBlocksAtHeight(concordium.v2.BlocksAtHeightRequest) returns (concordium.v2.BlocksAtHeightResponse);
     */
    getBlocksAtHeight(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get information about tokenomics at the end of a given block.
     *
     * @generated from protobuf rpc: GetTokenomicsInfo(concordium.v2.BlockHashInput) returns (concordium.v2.TokenomicsInfo);
     */
    getTokenomicsInfo(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Run the smart contract entrypoint in a given context and in the state at
     * the end of the given block.
     *
     * @generated from protobuf rpc: InvokeInstance(concordium.v2.InvokeInstanceRequest) returns (concordium.v2.InvokeInstanceResponse);
     */
    invokeInstance(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the registered delegators of a given pool at the end of a given block.
     * In contrast to the `GetPoolDelegatorsRewardPeriod` which returns delegators
     * that are fixed for the reward period of the block, this endpoint returns the
     * list of delegators that are registered in the block. Any changes to delegators
     * are immediately visible in this list.
     * The stream will end when all the delegators has been returned.
     *
     * @generated from protobuf rpc: GetPoolDelegators(concordium.v2.GetPoolDelegatorsRequest) returns (stream concordium.v2.DelegatorInfo);
     */
    getPoolDelegators(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the fixed delegators of a given pool for the reward period of the given block.
     * In contracts to the `GetPoolDelegators` which returns delegators registered
     * for the given block, this endpoint returns the fixed delegators contributing
     * stake in the reward period containing the given block.
     * The stream will end when all the delegators has been returned.
     *
     * @generated from protobuf rpc: GetPoolDelegatorsRewardPeriod(concordium.v2.GetPoolDelegatorsRequest) returns (stream concordium.v2.DelegatorRewardPeriodInfo);
     */
    getPoolDelegatorsRewardPeriod(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the registered passive delegators at the end of a given block.
     * In contrast to the `GetPassiveDelegatorsRewardPeriod` which returns delegators
     * that are fixed for the reward period of the block, this endpoint returns the
     * list of delegators that are registered in the block. Any changes to delegators
     * are immediately visible in this list.
     * The stream will end when all the delegators has been returned.
     *
     * @generated from protobuf rpc: GetPassiveDelegators(concordium.v2.BlockHashInput) returns (stream concordium.v2.DelegatorInfo);
     */
    getPassiveDelegators(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the fixed passive delegators for the reward period of the given block.
     * In contracts to the `GetPassiveDelegators` which returns delegators registered
     * for the given block, this endpoint returns the fixed delegators contributing
     * stake in the reward period containing the given block.
     * The stream will end when all the delegators has been returned.
     *
     * @generated from protobuf rpc: GetPassiveDelegatorsRewardPeriod(concordium.v2.BlockHashInput) returns (stream concordium.v2.DelegatorRewardPeriodInfo);
     */
    getPassiveDelegatorsRewardPeriod(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the current branches of blocks starting from and including the last finalized block.
     *
     * @generated from protobuf rpc: GetBranches(concordium.v2.Empty) returns (concordium.v2.Branch);
     */
    getBranches(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get information related to the baker election for a particular block.
     *
     * @generated from protobuf rpc: GetElectionInfo(concordium.v2.BlockHashInput) returns (concordium.v2.ElectionInfo);
     */
    getElectionInfo(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the identity providers registered as of the end of a given block.
     * The stream will end when all the identity providers have been returned.
     *
     * @generated from protobuf rpc: GetIdentityProviders(concordium.v2.BlockHashInput) returns (stream concordium.v2.IpInfo);
     */
    getIdentityProviders(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the anonymity revokers registered as of the end of a given block.
     * The stream will end when all the anonymity revokers have been returned.
     *
     * @generated from protobuf rpc: GetAnonymityRevokers(concordium.v2.BlockHashInput) returns (stream concordium.v2.ArInfo);
     */
    getAnonymityRevokers(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get a list of non-finalized transaction hashes for a given account. This
     * endpoint is not expected to return a large amount of data in most cases,
     * but in bad network condtions it might. The stream will end when all the
     * non-finalized transaction hashes have been returned.
     *
     * @generated from protobuf rpc: GetAccountNonFinalizedTransactions(concordium.v2.AccountAddress) returns (stream concordium.v2.TransactionHash);
     */
    getAccountNonFinalizedTransactions(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get a list of transaction events in a given block.
     * The stream will end when all the transaction events for a given block have been returned.
     *
     * @generated from protobuf rpc: GetBlockTransactionEvents(concordium.v2.BlockHashInput) returns (stream concordium.v2.BlockItemSummary);
     */
    getBlockTransactionEvents(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get a list of special events in a given block. These are events generated
     * by the protocol, such as minting and reward payouts. They are not directly
     * generated by any transaction. The stream will end when all the special
     * events for a given block have been returned.
     *
     * @generated from protobuf rpc: GetBlockSpecialEvents(concordium.v2.BlockHashInput) returns (stream concordium.v2.BlockSpecialEvent);
     */
    getBlockSpecialEvents(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get the pending updates to chain parameters at the end of a given block.
     * The stream will end when all the pending updates for a given block have been returned.
     *
     * @generated from protobuf rpc: GetBlockPendingUpdates(concordium.v2.BlockHashInput) returns (stream concordium.v2.PendingUpdate);
     */
    getBlockPendingUpdates(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Get next available sequence numbers for updating chain parameters after a given block.
     *
     * @generated from protobuf rpc: GetNextUpdateSequenceNumbers(concordium.v2.BlockHashInput) returns (concordium.v2.NextUpdateSequenceNumbers);
     */
    getNextUpdateSequenceNumbers(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Shut down the node.
     * Return a GRPC error if the shutdown failed.
     *
     * @generated from protobuf rpc: Shutdown(concordium.v2.Empty) returns (concordium.v2.Empty);
     */
    shutdown(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Suggest to a peer to connect to the submitted peer details.
     * This, if successful, adds the peer to the list of given addresses.
     * Otherwise return a GRPC error.
     * Note. The peer might not be connected to instantly, in that case
     * the node will try to establish the connection in near future. This
     * function returns a GRPC status 'Ok' in this case.
     *
     * @generated from protobuf rpc: PeerConnect(concordium.v2.IpSocketAddress) returns (concordium.v2.Empty);
     */
    peerConnect(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Disconnect from the peer and remove them from the given addresses list
     * if they are on it. Return if the request was processed successfully.
     * Otherwise return a GRPC error.
     *
     * @generated from protobuf rpc: PeerDisconnect(concordium.v2.IpSocketAddress) returns (concordium.v2.Empty);
     */
    peerDisconnect(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get a list of banned peers.
     *
     * @generated from protobuf rpc: GetBannedPeers(concordium.v2.Empty) returns (concordium.v2.BannedPeers);
     */
    getBannedPeers(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Ban the given peer.
     * Returns a GRPC error if the action failed.
     *
     * @generated from protobuf rpc: BanPeer(concordium.v2.PeerToBan) returns (concordium.v2.Empty);
     */
    banPeer(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Unban the banned peer.
     * Returns a GRPC error if the action failed.
     *
     * @generated from protobuf rpc: UnbanPeer(concordium.v2.BannedPeer) returns (concordium.v2.Empty);
     */
    unbanPeer(input, options) {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Start dumping packages into the specified file.
     * Only enabled if the node was built with the `network_dump` feature.
     * Returns a GRPC error if the network dump failed to start.
     *
     * @generated from protobuf rpc: DumpStart(concordium.v2.DumpRequest) returns (concordium.v2.Empty);
     */
    dumpStart(input, options) {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Stop dumping packages.
     * Only enabled if the node was built with the `network_dump` feature.
     * Returns a GRPC error if the network dump failed to be stopped.
     *
     * @generated from protobuf rpc: DumpStop(concordium.v2.Empty) returns (concordium.v2.Empty);
     */
    dumpStop(input, options) {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * / Get a list of the peers that the node is connected to
     * / and assoicated network related information for each peer.
     *
     * @generated from protobuf rpc: GetPeersInfo(concordium.v2.Empty) returns (concordium.v2.PeersInfo);
     */
    getPeersInfo(input, options) {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get information about the node.
     * The `NodeInfo` includes information of
     * * Meta information such as the, version of the node, type of the node, uptime and the local time of the node.
     * * NetworkInfo which yields data such as the node id, packets sent/received,
     *   average bytes per second sent/received.
     * * ConsensusInfo. The `ConsensusInfo` returned depends on if the node supports
     *   the protocol on chain and whether the node is configured as a baker or not.
     *
     * @generated from protobuf rpc: GetNodeInfo(concordium.v2.Empty) returns (concordium.v2.NodeInfo);
     */
    getNodeInfo(input, options) {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Send a block item. A block item is either an `AccountTransaction`, which is
     * a transaction signed and paid for by an account, a `CredentialDeployment`,
     * which creates a new account, or `UpdateInstruction`, which is an
     * instruction to change some parameters of the chain. Update instructions can
     * only be sent by the governance committee.
     *
     * Returns a hash of the block item, which can be used with
     * `GetBlockItemStatus`.
     *
     * @generated from protobuf rpc: SendBlockItem(concordium.v2.SendBlockItemRequest) returns (concordium.v2.TransactionHash);
     */
    sendBlockItem(input, options) {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the hash to be signed for an account transaction. The hash returned
     * should be signed and the signatures included as an
     * AccountTransactionSignature when calling `SendBlockItem`. This is provided as
     * a convenience to support cases where the right SDK is not available for
     * interacting with the node. If an SDK is available then it is strongly
     * recommended to compute this hash off-line using it. That reduces the trust
     * in the node, removes networking failure modes, and will perform better.
     *
     * @generated from protobuf rpc: GetAccountTransactionSignHash(concordium.v2.PreAccountTransaction) returns (concordium.v2.AccountTransactionSignHash);
     */
    getAccountTransactionSignHash(input, options) {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the values of chain parameters in effect in the given block.
     *
     * @generated from protobuf rpc: GetBlockChainParameters(concordium.v2.BlockHashInput) returns (concordium.v2.ChainParameters);
     */
    getBlockChainParameters(input, options) {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the summary of the finalization data in a given block.
     *
     * @generated from protobuf rpc: GetBlockFinalizationSummary(concordium.v2.BlockHashInput) returns (concordium.v2.BlockFinalizationSummary);
     */
    getBlockFinalizationSummary(input, options) {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Get the items of a block.
     *
     * @generated from protobuf rpc: GetBlockItems(concordium.v2.BlockHashInput) returns (stream concordium.v2.BlockItem);
     */
    getBlockItems(input, options) {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
}
exports.QueriesClient = QueriesClient;

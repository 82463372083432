import React, { createContext, useContext, useState } from 'react';

// function displayCoinToken(coinToken) {
//   switch (coinToken) {
//     case "MNG":
//       return "MNG";
//     case "CDC":
//       return "CCD";
//     case "MM":
//       return "BNB"
//     default: return coinToken;
//   }
// }

const baseWallet = {
  walletAddress: '',
  setWalletAddress: () => { },
  walletBalance: '',
  setWalletBalance: () => { },
  CDCWalletProps: {},
  setCDCWalletProps: () => { },
  walletConnectionActive: null,
  setWalletConnectionActive: () => { },
  // displayCoinToken: displayCoinToken,
}
const WalletContext = createContext(baseWallet);

const ManaWalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(baseWallet.walletAddress);
  const [walletBalance, setWalletBalance] = useState(baseWallet.walletBalance);
  const [walletConnectionActive, setWalletConnectionActive] = useState(baseWallet.walletConnectionActive);
  const [CDCWalletProps, setCDCWalletProps] = useState(baseWallet.CDCWalletProps);

  return (
    <WalletContext.Provider value={{
      walletAddress, walletBalance, CDCWalletProps, walletConnectionActive,
      setWalletAddress, setWalletBalance, setCDCWalletProps, setWalletConnectionActive,
    }}>
      {children}
    </WalletContext.Provider>
  );
};

export default ManaWalletProvider;

export const useManaWallet = () => useContext(WalletContext);
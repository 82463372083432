import { postData } from "../apis";

const postmessage = async (payload) => {
  return postData("/v1/contact/message", payload);
};

const message = {
  postmessage,
};
export default message;

import * as React from "react";

const PaperClip = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.414}
    height={22}
    {...props}
    viewBox="0 0 22.414 22"
  >
    <path
      d="m20.998 10.376-9.456 8.918a6.443 6.443 0 0 1-8.735 0 5.594 5.594 0 0 1 0-8.238l9.456-8.918a4.3 4.3 0 0 1 5.824 0 3.729 3.729 0 0 1 0 5.492l-9.465 8.917a2.148 2.148 0 0 1-2.912 0 1.865 1.865 0 0 1 0-2.746l8.736-8.229"
      fill="none"
      stroke="#f2f2f2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default PaperClip;

import * as React from "react";

const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 9.573}
    height={props.height ? props.height : 7.178}
    viewBox="0 0 9.573 7.178"
    {...props}
  >
    <path
      d="M4.787 7.178a1.372 1.372 0 0 1-1.061-.51L.368 2.6A1.675 1.675 0 0 1 .161.837 1.4 1.4 0 0 1 1.429 0h6.716a1.4 1.4 0 0 1 1.268.837A1.675 1.675 0 0 1 9.202 2.6L5.844 6.668a1.372 1.372 0 0 1-1.057.51Z"
      fill="#b9b9b9"
    />
  </svg>
);

export default ArrowDown;

import React, { useEffect, useRef, useState } from "react";
import Cards from "./Home-Cards";
import "../assets/styles/Home-items.css";
import { connect } from "react-redux";
import HeaderSection from "./HeaderSection";
import LiveChat from "../assets/LiveChat";
import Dots from "../assets/Dots";
import Cancel from "../assets/Cancel";
import Answer from "../assets/Answer";
import Trash from "../assets/Trash";
import Submit from "../assets/Submit";
import AddFile from "../assets/AddFile";
import moment from "moment";
import Reactions from "./Reactions";
import InfiniteScroll from "react-infinite-scroll-component";
import { getData } from "../apis";
import { useHistory } from "react-router-dom";

const HomeItems = ({
  sendMessage,
  messages,
  loadMore,
  user,
  games_join,
  deleteMessage,
  addReaction,
  replyMessage,
  deleteReaction,
}) => {
  const [message, setMessage] = useState("");
  const [showOptions, setShowOptions] = useState(null);
  const [showReaction, setShowReaction] = useState(null);
  const input = useRef();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [reply, setReply] = useState({ user: "", content: "", id: "" });
  const messageInput = useRef();
  const [showLive, setShowLive] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(2);
  const history = useHistory();

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop === 0) {
      loadMore();
    }
  };
  const getUrl = async (event) => {
    const imageFile = event.target.files[0];
    if (imageFile !== undefined) {
      setFile(event.target.files[0]);
      const imageUrl = URL.createObjectURL(imageFile);
      setFileUrl(imageUrl);
    }
  };
  const submit = () => {
    if (message !== "" || file !== null) {
      if (reply.id !== "") {
        replyMessage(reply, message, file);
      } else {
        sendMessage(message, file);
      }
      setReply({ id: "", user: "", content: "" });
      setMessage("");
      setFile(null);
      setFileUrl(null);
    }
  };
  const submitReaction = (item, type) => {
    setShowReaction(null);
    // get if user already react to the message
    let getIndex = item.latest_reactions.filter(
      (i) => i.user_id === user.userName
    );
    // last params is delete or not the reaction. user can add only one reaction
    addReaction(item.id, type, getIndex);
  };
  const showReactions = (item) => {
    // convert objet to array
    let reactionsArry = Object.entries(item.reaction_counts).map(
      ([key, value]) => ({ type: key, value })
    );
    return reactionsArry.map((reaction, indx) => (
      <li
        key={indx}
        onClick={() => {
          // get if user already react to the message
          let getIndex = item.latest_reactions.filter(
            (i) => i.user_id === user.userName
          );
          // remove reaction or add new one
          if (getIndex.length > 0) {
            // check if user want to update the reaction or just remove it
            if (getIndex[0].type === reaction.type) {
              deleteReaction(getIndex[0].message_id, getIndex[0].type);
            } else {
              addReaction(item.id, reaction.type, getIndex);
            }
          } else {
            addReaction(item.id, reaction.type);
          }
        }}
      >
        <span>
          <i
            className={`fa ${reaction.type === "thumb"
              ? "fa-thumbs-up thumb"
              : reaction.type === "love"
                ? "fa-heart heart"
                : reaction.type === "laugh"
                  ? "fa-laugh-squint face"
                  : reaction.type === "sad"
                    ? "fa-sad-cry face"
                    : "fa-surprise face"
              }`}
          />
          {reaction.value}
        </span>
      </li>
    ));
  };

  useEffect(() => {
    const getComments = async () => {
      const res = await getData(`/v1/games/get/all?page=1`); //${page}
      if (res) {
        setItems(res.data.results.reverse());
      }
    };

    getComments();
  }, []);

  const fetchComments = async () => {
    const res = await getData(`/v1/games/get/all?page=${page}`); //${page}
    return res.data.results;
  };

  const fetchData = async () => {
    const commentsForServe = await fetchComments();
    setItems([...items, ...commentsForServe]);

    if (commentsForServe.length === 0 || commentsForServe.length < 10) {
      setHasMore(false);
    }

    setPage(page + 1);
  };

  return (
    <div className="container-fluid">
      <div className="d-sm-none">
        <div className="buble-popup" onClick={() => setShowLive(!showLive)}>
          {showLive ? <Cancel width="60" height="60" /> : <LiveChat />}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 custom" style={{ marginTop: 20 }} >
          <HeaderSection
            title="Your Favorite Games"
            subtitle="join and enjoy"
          />

          <div className="row  containerCardsHomePage">
            <InfiniteScroll
              style={{ display: "contents" }} //className="styleContainer"
              dataLength={items.length} //This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              loader={<div />}
              endMessage={<div />}
            >
              <div className="row">
                {items.map((game) => {
                  return <Cards key={game._id} games={game} />;
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
        <div className={`col-lg-4 live-chat-mb ${showLive ? "active" : ""}`}>
          <div className="live-chat">
            <div className="header">
              <h3>Live Chat</h3>
              <LiveChat />
            </div>
            <div className="line" />
            <div
              className="messages"
              id="messages-container"
              onScroll={handleScroll}
            >
              {messages && messages.length > 0
                ? messages.map((item, index) => (
                  <div key={index}>
                    {item.activityId ? (
                      <div
                        style={{ cursor: "pointer" }}
                        className="challenge-popup"
                        onClick={() =>
                          item.activityId
                            ? history.push(`/challenge/${item.activityId}`)
                            : null
                        }
                      >
                        New Challenge
                      </div>
                    ) : null}
                    <div
                      className={`content-${user.userName === item.user.id ? "RH" : "LH"
                        } color-${item.isChallenge
                          ? "ligthBlue"
                          : user.userName === item.user.id
                            ? "lightGreen"
                            : "purple"
                        }`}
                      style={{ cursor: item.activityId ? "pointer" : "auto" }}
                    >
                      {/* user image */}
                      {user.userName !== item.user.id ? (
                        <img
                          className="user-profile"
                          src={
                            item.user.image
                              ? item.user.image
                              : `https://getstream.io/random_svg/?name=${item.user.name}`
                          }
                          alt="userProfile"
                        />
                      ) : null}
                      {/* message content */}
                      <div className="message-cont">
                        <div
                          className="buble"
                          onClick={() =>
                            item.activityId
                              ? history.push(`/challenge/${item.activityId}`)
                              : null
                          }
                        >
                          {/* show user name if it not the current user */}
                          {user.userName !== item.user.id ? (
                            <span>{item.user.id}</span>
                          ) : null}
                          {/* show reply for messages */}
                          {item.quoted_message ? (
                            <div className="reply-content">
                              <span>{item.quoted_message.user.id}</span>
                              {item.quoted_message.message}
                            </div>
                          ) : null}
                          {/* show html content */}
                          {item.isChallenge ? (
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: item.message,
                              }}
                            />
                          ) : (
                            // show normal message content
                            <p style={{ marginBottom: item.image ? 5 : 0 }}>
                              {item.message}
                            </p>
                          )}
                          {/* show image message */}
                          {item.image ? (
                            <img src={item.image} alt={`pict-${index}`} />
                          ) : null}
                        </div>
                        {/* show date message */}
                        <div
                          className="date"
                          style={{
                            justifyContent:
                              item.latest_reactions.length > 0
                                ? "space-between"
                                : "end",
                            bottom:
                              item.latest_reactions.length > 0 ? -33 : -20,
                          }}
                        >
                          {/* show reactions */}
                          {item.latest_reactions.length > 0 ? (
                            <div className="current-reaction">
                              <ul>
                                {item.reaction_counts
                                  ? showReactions(item)
                                  : null}
                              </ul>
                            </div>
                          ) : null}
                          {moment(item.created_at).format("MM-DD hh:mm a")}
                        </div>
                        <div
                          onClick={() => {
                            setShowOptions(
                              index === showOptions ? null : index
                            );
                            if (showReaction != null) setShowReaction(null);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Dots />
                        </div>
                        {showOptions === index ? (
                          <div className="second-options">
                            <ul>
                              <li
                                onClick={() => {
                                  setShowOptions(null);
                                  setReply({
                                    user: item.user.id,
                                    content: item.message,
                                    id: item.id,
                                  });
                                  messageInput.current.focus();
                                }}
                              >
                                <span>Answer</span> <Answer />
                              </li>
                              <li
                                onClick={() => {
                                  setShowReaction(index);
                                  setShowOptions(null);
                                }}
                              >
                                <span>Reaction</span>
                                <i className="fa fa-smile" />
                              </li>
                              {/* show remove option only if current user is the one who send the message */}
                              {user.userName === item.user.id ? (
                                <li
                                  onClick={() => {
                                    deleteMessage(item.id);
                                    setShowOptions(null);
                                  }}
                                >
                                  <span style={{ color: "#FF4757" }}>
                                    Remove
                                  </span>
                                  <Trash color="#FF4757" />
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        ) : null}
                        {/* show reaction option */}
                        {showReaction === index ? (
                          <Reactions
                            addReaction={(type) => {
                              submitReaction(item, type);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>
            {/* image preview section */}
            <div
              className="preview"
              style={{ display: fileUrl != null ? "block" : "none" }}
            >
              {fileUrl != null ? <img src={fileUrl} alt="preview" /> : null}
              <i
                className="fas fa-times-circle close"
                onClick={() => {
                  setFileUrl(null);
                  setFile(null);
                }}
              />
            </div>
            {/* show reply message content*/}
            {reply.user !== "" ? (
              <div className="reply-message">
                <i
                  className="fas fa-times-circle close"
                  onClick={() => {
                    setReply({ user: "", content: "" });
                  }}
                />
                <span className="user">{reply.user}</span>
                <p>{reply.content}</p>
              </div>
            ) : null}
            <div className="chat-container">
              <input
                type="file"
                accept="image/*"
                onChange={getUrl}
                ref={input}
                style={{ display: "none" }}
              />
              <button
                className="C-chat-message-btn"
                onClick={() => input.current.click()}
              >
                <AddFile />
              </button>
              <input
                ref={messageInput}
                className="input"
                type="text"
                value={message}
                placeholder="Type message..."
                onChange={({ target }) => setMessage(target.value)}
                onKeyPress={(key) => {
                  if (key.key === "Enter") {
                    submit();
                  }
                }}
              />
              <button
                className="C-chat-message-btn"
                onClick={() => {
                  submit();
                }}
              >
                <Submit />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    games: state.data.games,
    games_join: state.data.games_join,
    sendMessage: ownProps.sendMessage,
    messages: ownProps.messages,
    loadMore: ownProps.loadMore,
  };
};
export default connect(mapStateToProps)(HomeItems);

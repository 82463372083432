import { createContext, useMemo, useCallback, useState } from "react";
import React from "react";

export const AuthContex = createContext();

export default function AuthContexProvider({ children }) {
  const [newTokenn, setNewToken] = useState(false);
  const [navbarSize, setNavbarSize] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    window.localStorage.getItem("User")
  );

  const login = useCallback(() => {
    window.localStorage.setItem("User", true);
    setIsAuthenticated(true);
  }, []);

  const logOut = useCallback(() => {
    window.localStorage.clear();
    setIsAuthenticated(false);
  }, []);

  const NewToken = useCallback(() => {
    setNewToken(true);
  }, []);

  const minimumSizeOfTheNavbar = useCallback(() => {
    setNavbarSize(true);
  }, []);

  const maxSizeOfTheNavbar = useCallback(() => {
    setNavbarSize(false);
  }, []);

  const ErrorToken = useCallback(() => {
    setNewToken(false);
  }, []);

  const value = useMemo(
    () => ({
      login,
      logOut,
      isAuthenticated,
      NewToken,
      newTokenn,
      ErrorToken,
      minimumSizeOfTheNavbar,
      maxSizeOfTheNavbar,
      navbarSize,
    }),
    [
      isAuthenticated,
      login,
      logOut,
      NewToken,
      ErrorToken,
      newTokenn,
      minimumSizeOfTheNavbar,
      maxSizeOfTheNavbar,
      navbarSize,
    ]
  );
  return <AuthContex.Provider value={value}>{children}</AuthContex.Provider>;
}

import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import SignUp from "../components/signUp";
import SignIn from "../components/signIn";
import HomePage from "../pages/HomePage";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute";
import GamesChallange from "../components/GamesChallange";
import PostChallengePage from "../pages/PostChallengePage";
import ChatPage from "../pages/chatPage";
import RegisterGamerProfilePage from "../pages/RegisterGamerProfilePage";
import TermsPrivacy from "../services/Term&Conditions.service";
import CompleteProfile from "../components/CompleteProfile";
import CompleteProfilePage2 from "../components/CompleteProfilePage2";
import WalletPage from "../pages/WalletPage";
import FriendingPage from "../pages/FriendingPage";
import NftPage_New_release from "../pages/NftPage_New_Release";
import VerifiCodeToChangePasswordPage from "../pages/VerifiCodeToChangePasswordPage";
import { setBalance, setError, signIn, signOut, } from "../actions";
import { connect, useDispatch, useSelector } from "react-redux";
import SingleChallenge from "../pages/SingleChallenge";
import ContactUs from "../pages/ContactUs";
import SocketIOClient from "socket.io-client";
import { URL } from "../apis";
import Alerts from "../components/Alerts";
import { useActiveAccount } from "thirdweb/react";
import { getUserEmail } from "thirdweb/wallets/in-app";
import { client } from "../client";
import Shop from "../pages/Shop";
import MyNfts from "../pages/MyNfts";

const AppRouter = ({ loading, isSignedIn, alerts }) => {
  const centinel = useSelector((state) => state.auth.signUp);
  const activeAccount = useActiveAccount();
  const dispatch = useDispatch();
  const useCentinel = activeAccount?.address

  useEffect(() => {
    if (activeAccount?.address) {
      handleLogin();
    } else {
      dispatch(signOut());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCentinel, centinel]);

  const handleLogin = async () => {
    try {
      const emailThirdWeb = await getUserEmail({ client });

      dispatch(signIn(useCentinel, emailThirdWeb));
      const token = localStorage.getItem("token");
      const socket = SocketIOClient(URL, {
        path: "/socket.io",
        transports: ["websocket"],
        auth: {
          token: token,
        },
        forceNew: true,
        reconnectionAttempts: 50000,
        timeout: 2000,
      });
      socket.on("connect", () => {
        console.log("socket connected");
      });
      socket.on("balance", (res) => {
        dispatch(setBalance(res.balance));
      });
      socket.on("notification", (res) => {
        console.log("Notification received", res);
      });
      socket.on("error", (error) => {
        console.log("Socket error:", error);
      });
      socket.on("reconnect", (attempt) => {
        console.log("Reconnected:", attempt);
      });
      socket.on("disconnect", () => {
        console.log("Socket disconnected");
      });
    } catch (error) {
      dispatch(setError("Failed to log in with wallet"));
    }
  };

  return isSignedIn !== null ? (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/" component={SignUp} />
        <PublicRoute
          exact
          path="/Terms-Privacy"
          component={TermsPrivacy}
        />
        <Route path="/CompleteProfile">
          {isSignedIn ? (
            <CompleteProfile />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route
          path="/CompleteProfile2"
          component={isSignedIn ? CompleteProfilePage2 : SignIn}
        />
        <PrivateRoute exact path="/Games" component={HomePage} />
        <PrivateRoute
          exact
          path="/GamesChallange"
          component={GamesChallange}
        />
        <PrivateRoute
          exact
          path="/shop"
          component={Shop}
        />
        <PrivateRoute
          exact
          path="/my-assets"
          component={MyNfts}
        />
        <PrivateRoute
          exact
          path="/challenge/:id"
          component={SingleChallenge}
        />
        <PrivateRoute
          exact
          path="/postchallenge"
          component={PostChallengePage}
        />
        <PrivateRoute exact path="/chatpage" component={ChatPage} />
        {/* <PrivateRoute exact path="/historyofsale" component={HistoryOfSale} /> */}
        <PrivateRoute
          exact
          path="/registergamerprofile"
          component={RegisterGamerProfilePage}
        />
        <PrivateRoute exact path="/Wallet" component={WalletPage} />
        <PrivateRoute
          exact
          path="/Friending"
          component={FriendingPage}
        />
        <PrivateRoute
          exact
          path="/NewRelease"
          component={NftPage_New_release}
        />
        <PrivateRoute
          exact
          path="/VerifiCode"
          component={VerifiCodeToChangePasswordPage}
        />
        <PrivateRoute exact path="/contact-us" component={ContactUs} />
      </Switch>
      {loading.show ? (
        <div className="loading">
          <i className="fas fa-spinner fa-spin" />
          {loading.desc !== "" ? <div>{loading.desc}</div> : null}
        </div>
      ) : null}
      {alerts.error !== null || alerts.success !== null ? (
        <Alerts
          desc={alerts.error !== null ? alerts.error : alerts.success}
          type={alerts.error !== null ? "error" : "success"}
        />
      ) : null}
    </BrowserRouter>
  ) : null;
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    loading: state.alerts.loading,
    alerts: state.alerts,
  };
};
export default connect(mapStateToProps)(AppRouter);

import * as React from "react";

const Price = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 15}
    height={props.height ? props.height : 20}
    viewBox="0 0 168.939 188"
    {...props}
  >
    <g data-name="Grupo 17466">
      <g data-name="Grupo 5">
        <path
          data-name="Uni\xF3n 1"
          d="M57.438 183.3q16.615-31.421 33.235-62.846a4.08 4.08 0 0 0-3.46-5.984l-16.577-.593a5.381 5.381 0 0 1-4.526-7.964L121.845 4.224A8.132 8.132 0 0 1 128.976 0h33.671a6.291 6.291 0 0 1 6.291 6.291v156.247a6.446 6.446 0 0 1-6.445 6.4h-24.14a6.343 6.343 0 0 1-6.343-6.343v-38.58a8.805 8.805 0 0 1 1.946-5.522l24.479-30.405a4.2 4.2 0 0 0-3.287-6.837l-20.326.069a2.8 2.8 0 0 1-2.811-2.8V65.867a1.489 1.489 0 0 0-2.735-.816L116.289 84.87a.579.579 0 0 0 .476.9l25.676.382a5.119 5.119 0 0 1 3.718 8.555q-41.775 46.11-83.559 92.218A3.075 3.075 0 0 1 60.295 188a3.2 3.2 0 0 1-2.857-4.7ZM6.5 168.939a6.5 6.5 0 0 1-6.5-6.5V6.7A6.7 6.7 0 0 1 6.7 0h28.534a8.73 8.73 0 0 1 7.4 4.091l35.617 56.776a3.752 3.752 0 0 1 .114 3.793l-14.1 25.814a3.307 3.307 0 0 1-5.6.324L41.43 66.423a2.745 2.745 0 0 0-4.986 1.585v94.3a6.636 6.636 0 0 1-6.636 6.635Z"
          fill={props.color ? props.color : "#fff"}
        />
      </g>
    </g>
  </svg>
);

export default Price;

import * as React from "react";

const ImagePlaceholder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 194}
    height={props.height ? props.height : 194}
    {...props}
    viewBox="0 0 194 194"
  >
    <g opacity={0.054}>
      <g data-name="Grupo 16746" fill="#fff">
        <path d="M124.132 56.358H69.869a31.251 31.251 0 0 0-31.216 31.216v32.558a31.251 31.251 0 0 0 31.216 31.216h54.263a31.251 31.251 0 0 0 31.221-31.216V87.574a31.251 31.251 0 0 0-31.221-31.216Zm-12.544 36.759a5.835 5.835 0 1 1 0 .117ZM86.09 116.748a4.367 4.367 0 0 1-6.185 0l-6.01-6.01-5.776 5.776a4.367 4.367 0 0 1-6.185 0 4.4 4.4 0 0 1 0-6.185l5.776-5.771-5.6-5.6a4.373 4.373 0 1 1 6.185-6.185l5.6 5.6 5.776-5.776a4.373 4.373 0 0 1 6.182 6.18l-5.773 5.781 6.01 6.01a4.4 4.4 0 0 1 0 6.18Zm19.9-6.243a5.9 5.9 0 0 1-5.893-5.835 5.8 5.8 0 0 1 5.776-5.835h.117a5.835 5.835 0 0 1 0 11.67Zm11.432 11.494a5.8 5.8 0 0 1-5.835-5.776v-.117a5.835 5.835 0 1 1 5.835 5.893Zm11.553-11.494a5.9 5.9 0 0 1-5.893-5.835 5.8 5.8 0 0 1 5.776-5.835h.117a5.835 5.835 0 0 1 0 11.67Z" />
        <path
          data-name="Vector"
          d="m106.57 38.796-.058 5.484a9.565 9.565 0 0 1-9.511 9.394 1.3 1.3 0 0 0-1.4 1.342 1.32 1.32 0 0 0 1.342 1.342h-9.394a9.372 9.372 0 0 1 9.335-10.852 1.352 1.352 0 0 0 1.459-1.342l.058-5.485a4.123 4.123 0 0 1 4.085-4.026h.058a4.06 4.06 0 0 1 4.026 4.143Z"
        />
      </g>
      <g
        data-name="Elipse 235"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
        strokeDasharray="8 8"
      >
        <circle cx={97} cy={97} r={97} stroke="none" />
        <circle cx={97} cy={97} r={96.25} />
      </g>
    </g>
  </svg>
);

export default ImagePlaceholder;

import * as React from "react";

const Trash = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 16}
    height={props.height ? props.height : 16}
    {...props}
    viewBox="0 0 16 16"
  >
    <g
      fill={props.color ? props.color : "#ff4757"}
      data-name="vuesax/bold/trash"
    >
      <path d="M14.047 3.486a73.674 73.674 0 0 0-3.227-.247v-.006l-.147-.867c-.099-.613-.246-1.533-1.806-1.533H7.12c-1.553 0-1.7.88-1.807 1.527l-.14.853c-.62.04-1.24.08-1.86.14l-1.36.133a.5.5 0 1 0 .093.993l1.36-.133a53.152 53.152 0 0 1 10.547.14h.053a.5.5 0 0 0 .5-.453.511.511 0 0 0-.459-.547Z" />
      <path
        data-name="Vector"
        d="M12.82 5.427a.843.843 0 0 0-.607-.26H3.787a.832.832 0 0 0-.607.26.859.859 0 0 0-.228.627l.413 6.84c.073 1.013.167 2.28 2.493 2.28h4.28c2.327 0 2.42-1.26 2.493-2.28l.416-6.834a.864.864 0 0 0-.227-.633Zm-3.713 6.407h-2.22a.5.5 0 0 1 0-1h2.22a.5.5 0 0 1 0 1Zm.56-2.667H6.333a.5.5 0 0 1 0-1h3.334a.5.5 0 0 1 0 1Z"
      />
    </g>
  </svg>
);

export default Trash;

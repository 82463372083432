import React, { useState } from "react";
import "../assets/styles/Settings-Chatpage.css";
import "../assets/styles/Setting-items.css";
import friendService from "./../../src/services/friend.service";
import userImg from "./../assets/user2.jpg";
import { connect, useDispatch } from "react-redux";
import { setError, setLoading, setSuccess } from "../actions";

const ActiveFriends = ({ userList, User, onSelect, max, unSelect }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [userSelected, setUserSelected] = useState([]);

  const addFriend = async (id) => {
    dispatch(setLoading(true));
    const respAddFriend = await friendService.sendFriendRequest(id);
    if (respAddFriend.success) {
      dispatch(setSuccess("Friend Request Sent"));
    } else {
      dispatch(setError(respAddFriend.data.message));
    }
  };
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      if (search !== "") {
        searchUser();
      }
    }
  }
  const searchUser = async () => {
    dispatch(setLoading(true));
    const respSearchUser = await friendService.searchFriend(search);
    dispatch(setLoading(false));
    if (respSearchUser.success) {
      setSearchResult(respSearchUser.data);
    } else {
      dispatch(setError(respSearchUser.data.message));
    }
  };
  const renderUser = (userFound, index) => {
    return (
      <div
        tabIndex="1"
        className={`C-P-list-Item ${
          userSelected.includes(index) ? "active" : null
        } ${userFound._id === unSelect ? "unselect" : ""}`}
        key={index}
        onClick={() => {
          // if is selected then remove
          if (userSelected.includes(index)) {
            const position = userSelected.indexOf(index);
            if (position !== -1) {
              userSelected.splice(position, 1);
              setUserSelected(userSelected);
              onSelect(userFound._id, "remove");
            }
          } else {
            // only allow add new user base on max quantity
            if (userSelected.length + 1 <= max) {
              setUserSelected([...userSelected, index]);
              onSelect(userFound._id, "add");
            }
          }
        }}
      >
        <div className="C-P-list-Item-image-container">
          <img
            alt="User"
            className="C-P-list-Item-Image"
            src={userFound.urlPhoto ? userFound.urlPhoto : userImg}
          />
        </div>

        <div className="C-P-list-Item-txt-container">
          <div className="C-P-list-username">{userFound.userName}</div>
          <i
            className="fas fa-check"
            // onClick={() => addFriend(userFound._id)}
          ></i>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="C-custom-left-container">
        <div className="ST-rigt--input-container">
          <i className="fas fa-search  ST-i" onClick={searchUser}></i>
          <input
            type="text"
            value={search}
            onKeyDown={(event) => {
              if (event.key === "Backspace" && search.length <= 1) {
                setSearchResult([]);
              }
            }}
            onKeyPress={handleKeyPress}
            onChange={({ target }) => setSearch(target.value)}
            className="ST-rigth-input C-input"
            placeholder="Find a Friends..."
          />
        </div>
      </div>
      {searchResult.length > 0 && search !== "" ? (
        searchResult.map((userFound, index) => renderUser(userFound, index))
      ) : userList.length > 0 ? (
        userList
          .filter((user) => user.userName !== User.userName)
          .map((userFound, index) => renderUser(userFound, index))
      ) : userList.length === 0 ? (
        <div className="userNotFound"> User Not Found </div>
      ) : (
        <div className="searchAndFindNewFriends">
          Search And Find New Friends
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    User: state.auth.user,
    Games: state.data.games,
    userList: ownProps.user,
    onSelect: ownProps.onSelect,
    max: ownProps.max,
    unSelect: ownProps.unSelect,
  };
};
export default connect(mapStateToProps)(ActiveFriends);

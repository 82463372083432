import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, setLoading } from "../../actions";
import friendService from "../../services/friend.service";
import FriendList from "./FriendList";

const ModalChats = ({ addChat, chatList, showList, hideList }) => {
  const [search, setSearch] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const [allfriends, setAllFriends] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getFriends();
  }, []);
  const getFriends = async () => {
    dispatch(setLoading(true));
    const respFriends = await friendService.getAllFriend(1, 50);
    if (respFriends.success) {
      setAllFriends(respFriends.data.results);
      dispatch(setLoading(false));
    } else {
      dispatch(setError(respFriends.data.message));
    }
  };
  const searchMyFriends = async () => {
    dispatch(setLoading(true));
    const respsearchMyFriends = await friendService.searchFriend(search);
    if (respsearchMyFriends.success) {
      setSearchResult(respsearchMyFriends.data);
      dispatch(setLoading(false));
    } else {
      dispatch(setError(respsearchMyFriends.data.message));
    }
  };
  //function key up enter
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      if (search !== "") {
        searchMyFriends();
      }
    }
  }
  return (
    <div
      className="modal fade"
      id="exampleModalToggle"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="title" id="exampleModalToggleLabel">
              Friends
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={hideList}
            ></button>
          </div>

          <div className="modal-body">
            <div className=" ST-rigt--input-container  ">
              <i className="fas fa-search  ST-i" onClick={searchMyFriends}></i>
              <input
                type="text"
                onKeyPress={handleKeyPress}
                onChange={({ target }) => setSearch(target.value)}
                className="ST-rigth-input C-input"
                placeholder="Find a Friends..."
              />
            </div>
            {showList ? (
              <FriendList
                allMyFriends={allfriends}
                userfound={searchResult}
                search={search}
                onSelect={(val) => addChat(val)}
                chatList={chatList}
              />
            ) : null}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn-transparent"
              data-bs-dismiss="modal"
              onClick={hideList}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalChats;

/* eslint-disable react-hooks/exhaustive-deps */
import ccdServices from "../services/concordium.nfts.service";
import zkServices from "../services/zkSync.service";
import AlertUser from "../Alerts/userAlerts";
import SideBar from "../components/SideBar";
import NavBar from "../components/NavBar";
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useManaWallet } from "../custom-providers/useWallet";
import { useWalletConnectorSelector } from "@concordium/react-components";
import { pathData } from "../apis";

const CONNECTION_TYPE = "BrowserWallet"; // "WalletConnect";

const MyNfts = ({ user, navbarSize }) => {
  const [zkSyncNfts, setZkSyncNfts] = useState([]);
  const [concordiumNfts, setConcordiumNfts] = useState([]);
  const [loadingZk, setLoadingZk] = useState(false);
  const [loadingCcd, setLoadingCcd] = useState(false);
  const [hasCDC, setHasCDC] = useState(true);

  const fetchZKNFTs = async (address) => {
    try {
      setLoadingZk(true);
      const response = await zkServices.getAssets('0xecf9429fdac853d7729a931043e80a3a703404b7');
      setZkSyncNfts(response);
    } catch (error) {
      console.error("Error fetching zkSync NFTs:", error);
    } finally {
      setLoadingZk(false);
    }
  };

  const fetchCCDNFTs = async (address) => {
    try {
      setLoadingCcd(true);
      const response = await ccdServices.getAssets(address);
      setConcordiumNfts(response);
    } catch (error) {
      console.error("Error fetching zkSync NFTs:", error);
    } finally {
      setLoadingCcd(false);
    }
  };

  //CDC Logic Start
  const { CDCWalletProps, setWalletConnectionActive, } = useManaWallet();
  const { isConnected, isSelected, select } = useWalletConnectorSelector(
    CONNECTION_TYPE,
    CDCWalletProps
  );

  const connectCDC = useCallback(async () => {
    await CDCWalletProps.connectActive();
  }, [isSelected]);

  const disconnectCDC = async () => {
    if (isConnected) {
      await CDCWalletProps.disconnectActive();
    }
  }
  const handleConcordiumData = async (type) => {
    const { activeConnection } = CDCWalletProps;
    setWalletConnectionActive(activeConnection);
    const ccdWallet = CDCWalletProps.activeConnectedAccount;
    if (ccdWallet) {
      await updateUserWallet(type, ccdWallet);
      fetchCCDNFTs(ccdWallet);
    }

    disconnectCDC()
  }
  //CDC Logic End

  useEffect(() => {    
    if (!isSelected) {
      select();
    }
  }, []);

  useEffect(() => {
    //Exclusive CDC
    if (
      CDCWalletProps.activeConnectorError ===
      "Browser Wallet extension not detected"
    ) {
      setHasCDC(false);
    }
    if (isConnected) {
      handleConcordiumData('Concordium')
    }
  }, [isConnected, CDCWalletProps]);

  const updateUserWallet = async (type, wallet) => {
    try {
      const payload = {
        externalWallets: {
          ...user.externalWallets,
          [type.toLowerCase()]: wallet,
        },
      };
      await pathData(`/v1/user/updated/${user._id}`, payload);
    } catch (error) {
      console.error(`Failed to update ${type} wallet:`, error);
    }
  };

  const handleAddWallet = async (type) => {
    if (type === 'Concordium') {
      if (!hasCDC) {
        AlertUser.walletExtentionNotFound(
          "Concordium",
          "https://chrome.google.com/webstore/detail/concordium-wallet/mnnkpffndmickbiakofclnpoiajlegmg"
        );
      } else {
        connectCDC()
      }
    } else if (type === 'ZkSync') {
      const zkWallet = await zkServices.connectMM()
      if (zkWallet) {
        await updateUserWallet(type, zkWallet);
        fetchZKNFTs(zkWallet);
      }
    }
  };

  useEffect(() => {
    if (user?.externalWallets) {
      if (user.externalWallets?.zksync) fetchZKNFTs(user.externalWallets.zksync);
      if (user.externalWallets?.concordium) fetchCCDNFTs(user.externalWallets.concordium);
    }
  }, [user]);

  return (
    <div>
      <NavBar />
      <SideBar />

      <div
        className={
          navbarSize === "expand"
            ? "custom-containerHome"
            : "custom-container2Home"
        }
      >
        <div
          style={{
            marginTop: 50,
            marginLeft: 30,
            marginRight: 30,
            height: "100vh",
          }}
          className="pixelFont"
        >
          <h1 style={{ color: "#8c51e1", fontSize: "40px", fontWeight: "bold" }}>
            Mana Assets
          </h1>

          {/* ZkSync Assets */}
          <h2 style={{ color: "#8c51e1" }}>ZkSync</h2>
          {loadingZk ? (
            <p>Loading ZkSync NFTs...</p>
          ) : user?.externalWallets?.zksync ? (
            zkSyncNfts.length > 0 ? (
              <div className="market-items-grid">
                {zkSyncNfts.map((nft) => (
                  <div key={nft.id} className="nft-item">
                    <img src={nft.image} alt={nft.name} />
                    <p>
                      {nft.name} #{nft.id}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <p>No ZkSync assets found.</p>
            )
          ) : (
            <button className="connect-button-zksync" onClick={() => handleAddWallet("ZkSync")}>
              Add ZkSync Wallet
            </button>
          )}

          {/* Concordium Assets */}
          <h2 style={{ color: "#8c51e1", marginTop: "30px" }}>Concordium</h2>
          {loadingCcd ? (
            <p>Loading Concordium NFTs...</p>
          ) : user?.externalWallets?.concordium ? (
            concordiumNfts.length > 0 ? (
              <div className="market-items-grid">
                {concordiumNfts.map((nft) => (
                  <div key={nft.id} className="nft-item">
                    <img src={nft.display.url} alt={nft.name} />
                    <p>
                      {nft.name} #{nft.id}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <p>No Concordium assets found.</p>
            )
          ) : (
            <button className='connect-button-zksync' onClick={() => handleAddWallet("Concordium")}>
              Add Concordium Wallet
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  navbarSize: state.alerts.navbarSize,
});

export default connect(mapStateToProps)(MyNfts);
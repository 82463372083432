import PropTypes from 'prop-types';

export const convertHash = (hash) => {

    return hash.slice(0, 12) +  '...'  + hash.slice(-6)

}

convertHash.PropTypes = {
    hash: PropTypes.string.isRequired
}
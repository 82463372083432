/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: "us-east-1",
  // aws_cognito_identity_pool_id: "us-east-1_A5uQrjqlm",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_A5uQrjqlm",
  aws_user_pools_web_client_id: "5buk48cl80dmc3u4itqk0gm2t",
  oauth: {
    domain: "mana-dev2.auth.us-east-1.amazoncognito.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "http://localhost:3000/Games",
    redirectSignOut: "http://localhost:3000/Login",
    responseType: "token",
  },
  federationTarget: "COGNITO_USER_POOLS",
};
export default awsmobile;

import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import authReducer from "./authReducer";
import userDataReducer from "./userDataReducer";
import walletReducer from "./walletReducer";
export default combineReducers({
  auth: authReducer,
  alerts: alertReducer,
  data: userDataReducer,
  wallet: walletReducer,
});

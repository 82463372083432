import React from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import MarketItems from "../components/MarketItems";
import { items } from "../assets/ShopItems"
import { connect } from "react-redux";

const Shop = ({ navbarSize }) => {

    return (
        <div>
            <NavBar />
            <SideBar />
            <div
                className={
                    navbarSize === "expand"
                        ? "custom-containerHome"
                        : "custom-container2Home"
                }
            >
                <div style={{ marginTop: 50, marginLeft: 50, marginRight: 50 }}>
                    <h1 style={{ color: '#8c51e1', fontSize: '40px', fontWeight: 'bold', marginTop: 50 }} className="pixelFont">
                        Mana Passports
                    </h1>
                    <div className="no-nfts-message">Items will go to the wallet used to pay!
                        <br></br>
                        <p className="no-nfts-submessage">Limited edition, only available through August 24th! </p>
                    </div>
                    <div className="market-items-grid">
                        {items.map(item => (
                            <MarketItems key={item.name} item={item} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { user: state.auth.user, navbarSize: state.alerts.navbarSize };
};

export default connect(mapStateToProps)(Shop);
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cis0Supports = exports.CIS0 = void 0;
const buffer_1 = require("buffer/");
const serializationHelpers_1 = require("./serializationHelpers");
const json_bigint_1 = require("json-bigint");
const contractHelpers_1 = require("./contractHelpers");
const util_1 = require("./util");
/** Namespace with types for CIS-0 standard contracts */
// eslint-disable-next-line @typescript-eslint/no-namespace
var CIS0;
(function (CIS0) {
    /** Possible response types for a query */
    let SupportType;
    (function (SupportType) {
        /** The standard is not supported */
        SupportType[SupportType["NoSupport"] = 0] = "NoSupport";
        /** The standard is supported */
        SupportType[SupportType["Support"] = 1] = "Support";
        /** The standard is supported by another contract */
        SupportType[SupportType["SupportBy"] = 2] = "SupportBy";
    })(SupportType = CIS0.SupportType || (CIS0.SupportType = {}));
})(CIS0 = exports.CIS0 || (exports.CIS0 = {}));
function serializeSupportIdentifier(id) {
    const buf = buffer_1.Buffer.from(id, 'ascii');
    return (0, serializationHelpers_1.packBufferWithWord8Length)(buf);
}
function serializeSupportIdentifiers(ids) {
    const n = (0, serializationHelpers_1.encodeWord16)(ids.length, true);
    return buffer_1.Buffer.concat([n, ...ids.map(serializeSupportIdentifier)]);
}
const deserializeSupportResult = (0, serializationHelpers_1.makeDeserializeListResponse)((buffer) => {
    const rawType = buffer.readUInt8(0);
    let cursor = 1;
    if (rawType > 2) {
        throw new Error('Unsupported support result type');
    }
    let value;
    if (rawType !== 2) {
        const type = rawType === 0
            ? CIS0.SupportType.NoSupport
            : CIS0.SupportType.Support;
        value = { type };
    }
    else {
        const numAddresses = buffer.readUInt8(cursor);
        cursor += 1;
        const addresses = [];
        for (let i = 0; i < numAddresses; i++) {
            const index = buffer.readBigUInt64LE(cursor);
            cursor += 8;
            const subindex = buffer.readBigUInt64LE(cursor);
            cursor += 8;
            addresses.push({ index, subindex });
        }
        value = {
            type: CIS0.SupportType.SupportBy,
            addresses,
        };
    }
    return { value, bytesRead: cursor };
});
async function cis0Supports(grpcClient, contractAddress, standardIds, blockHash) {
    const instanceInfo = await grpcClient.getInstanceInfo(contractAddress);
    if (instanceInfo === undefined) {
        throw new Error(`Could not get contract instance info for contract at address ${(0, json_bigint_1.stringify)(contractAddress)}`);
    }
    const contractName = (0, contractHelpers_1.getContractName)(instanceInfo);
    if (!instanceInfo.methods.includes(`${contractName}.supports`)) {
        return undefined;
    }
    const parameter = (0, util_1.makeDynamicFunction)(serializeSupportIdentifiers)(standardIds);
    const response = await grpcClient.invokeContract({
        contract: contractAddress,
        parameter,
        method: `${contractName}.supports`,
    }, blockHash);
    if (response === undefined ||
        response.tag === 'failure' ||
        response.returnValue === undefined) {
        throw new Error(`Failed to invoke support for contract at ${(0, json_bigint_1.stringify)(contractAddress)}${response.tag === 'failure' &&
            ` with error ${(0, json_bigint_1.stringify)(response.reason)}`}`);
    }
    const results = deserializeSupportResult(response.returnValue);
    const isListInput = Array.isArray(standardIds);
    const expectedValuesLength = isListInput ? standardIds.length : 1;
    if (results.length !== expectedValuesLength) {
        throw new Error('Mismatch between length of queries in request and values in response.');
    }
    if (isListInput) {
        return results;
    }
    else {
        return results[0];
    }
}
exports.cis0Supports = cis0Supports;

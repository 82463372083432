// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/service.proto" (package "concordium.v2", syntax proto3)
// tslint:disable
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Queries = void 0;
// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/service.proto" (package "concordium.v2", syntax proto3)
// tslint:disable
const types_1 = require("./types");
const types_2 = require("./types");
const types_3 = require("./types");
const types_4 = require("./types");
const types_5 = require("./types");
const types_6 = require("./types");
const types_7 = require("./types");
const types_8 = require("./types");
const types_9 = require("./types");
const types_10 = require("./types");
const types_11 = require("./types");
const types_12 = require("./types");
const types_13 = require("./types");
const types_14 = require("./types");
const types_15 = require("./types");
const types_16 = require("./types");
const types_17 = require("./types");
const types_18 = require("./types");
const types_19 = require("./types");
const types_20 = require("./types");
const types_21 = require("./types");
const types_22 = require("./types");
const types_23 = require("./types");
const types_24 = require("./types");
const types_25 = require("./types");
const types_26 = require("./types");
const types_27 = require("./types");
const types_28 = require("./types");
const types_29 = require("./types");
const types_30 = require("./types");
const types_31 = require("./types");
const types_32 = require("./types");
const types_33 = require("./types");
const types_34 = require("./types");
const types_35 = require("./types");
const types_36 = require("./types");
const types_37 = require("./types");
const types_38 = require("./types");
const types_39 = require("./types");
const types_40 = require("./types");
const types_41 = require("./types");
const types_42 = require("./types");
const types_43 = require("./types");
const types_44 = require("./types");
const types_45 = require("./types");
const types_46 = require("./types");
const types_47 = require("./types");
const types_48 = require("./types");
const types_49 = require("./types");
const types_50 = require("./types");
const types_51 = require("./types");
const types_52 = require("./types");
const types_53 = require("./types");
const types_54 = require("./types");
const types_55 = require("./types");
const types_56 = require("./types");
const types_57 = require("./types");
const runtime_rpc_1 = require("@protobuf-ts/runtime-rpc");
/**
 * @generated ServiceType for protobuf service concordium.v2.Queries
 */
exports.Queries = new runtime_rpc_1.ServiceType("concordium.v2.Queries", [
    { name: "GetBlocks", serverStreaming: true, options: {}, I: types_57.Empty, O: types_56.ArrivedBlockInfo },
    { name: "GetFinalizedBlocks", serverStreaming: true, options: {}, I: types_57.Empty, O: types_55.FinalizedBlockInfo },
    { name: "GetAccountInfo", options: {}, I: types_54.AccountInfoRequest, O: types_53.AccountInfo },
    { name: "GetAccountList", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_51.AccountAddress },
    { name: "GetModuleList", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_50.ModuleRef },
    { name: "GetAncestors", serverStreaming: true, options: {}, I: types_49.AncestorsRequest, O: types_48.BlockHash },
    { name: "GetModuleSource", options: {}, I: types_47.ModuleSourceRequest, O: types_46.VersionedModuleSource },
    { name: "GetInstanceList", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_45.ContractAddress },
    { name: "GetInstanceInfo", options: {}, I: types_44.InstanceInfoRequest, O: types_43.InstanceInfo },
    { name: "GetInstanceState", serverStreaming: true, options: {}, I: types_44.InstanceInfoRequest, O: types_42.InstanceStateKVPair },
    { name: "InstanceStateLookup", options: {}, I: types_41.InstanceStateLookupRequest, O: types_40.InstanceStateValueAtKey },
    { name: "GetNextAccountSequenceNumber", options: {}, I: types_51.AccountAddress, O: types_39.NextAccountSequenceNumber },
    { name: "GetConsensusInfo", options: {}, I: types_57.Empty, O: types_38.ConsensusInfo },
    { name: "GetBlockItemStatus", options: {}, I: types_37.TransactionHash, O: types_36.BlockItemStatus },
    { name: "GetCryptographicParameters", options: {}, I: types_52.BlockHashInput, O: types_35.CryptographicParameters },
    { name: "GetBlockInfo", options: {}, I: types_52.BlockHashInput, O: types_34.BlockInfo },
    { name: "GetBakerList", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_33.BakerId },
    { name: "GetPoolInfo", options: {}, I: types_32.PoolInfoRequest, O: types_31.PoolInfoResponse },
    { name: "GetPassiveDelegationInfo", options: {}, I: types_52.BlockHashInput, O: types_30.PassiveDelegationInfo },
    { name: "GetBlocksAtHeight", options: {}, I: types_29.BlocksAtHeightRequest, O: types_28.BlocksAtHeightResponse },
    { name: "GetTokenomicsInfo", options: {}, I: types_52.BlockHashInput, O: types_27.TokenomicsInfo },
    { name: "InvokeInstance", options: {}, I: types_26.InvokeInstanceRequest, O: types_25.InvokeInstanceResponse },
    { name: "GetPoolDelegators", serverStreaming: true, options: {}, I: types_24.GetPoolDelegatorsRequest, O: types_23.DelegatorInfo },
    { name: "GetPoolDelegatorsRewardPeriod", serverStreaming: true, options: {}, I: types_24.GetPoolDelegatorsRequest, O: types_22.DelegatorRewardPeriodInfo },
    { name: "GetPassiveDelegators", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_23.DelegatorInfo },
    { name: "GetPassiveDelegatorsRewardPeriod", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_22.DelegatorRewardPeriodInfo },
    { name: "GetBranches", options: {}, I: types_57.Empty, O: types_21.Branch },
    { name: "GetElectionInfo", options: {}, I: types_52.BlockHashInput, O: types_20.ElectionInfo },
    { name: "GetIdentityProviders", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_19.IpInfo },
    { name: "GetAnonymityRevokers", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_18.ArInfo },
    { name: "GetAccountNonFinalizedTransactions", serverStreaming: true, options: {}, I: types_51.AccountAddress, O: types_37.TransactionHash },
    { name: "GetBlockTransactionEvents", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_17.BlockItemSummary },
    { name: "GetBlockSpecialEvents", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_16.BlockSpecialEvent },
    { name: "GetBlockPendingUpdates", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_15.PendingUpdate },
    { name: "GetNextUpdateSequenceNumbers", options: {}, I: types_52.BlockHashInput, O: types_14.NextUpdateSequenceNumbers },
    { name: "Shutdown", options: {}, I: types_57.Empty, O: types_57.Empty },
    { name: "PeerConnect", options: {}, I: types_13.IpSocketAddress, O: types_57.Empty },
    { name: "PeerDisconnect", options: {}, I: types_13.IpSocketAddress, O: types_57.Empty },
    { name: "GetBannedPeers", options: {}, I: types_57.Empty, O: types_12.BannedPeers },
    { name: "BanPeer", options: {}, I: types_11.PeerToBan, O: types_57.Empty },
    { name: "UnbanPeer", options: {}, I: types_10.BannedPeer, O: types_57.Empty },
    { name: "DumpStart", options: {}, I: types_9.DumpRequest, O: types_57.Empty },
    { name: "DumpStop", options: {}, I: types_57.Empty, O: types_57.Empty },
    { name: "GetPeersInfo", options: {}, I: types_57.Empty, O: types_8.PeersInfo },
    { name: "GetNodeInfo", options: {}, I: types_57.Empty, O: types_7.NodeInfo },
    { name: "SendBlockItem", options: {}, I: types_6.SendBlockItemRequest, O: types_37.TransactionHash },
    { name: "GetAccountTransactionSignHash", options: {}, I: types_5.PreAccountTransaction, O: types_4.AccountTransactionSignHash },
    { name: "GetBlockChainParameters", options: {}, I: types_52.BlockHashInput, O: types_3.ChainParameters },
    { name: "GetBlockFinalizationSummary", options: {}, I: types_52.BlockHashInput, O: types_2.BlockFinalizationSummary },
    { name: "GetBlockItems", serverStreaming: true, options: {}, I: types_52.BlockHashInput, O: types_1.BlockItem }
]);

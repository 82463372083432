import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "../assets/styles/DragAndDrop.css";
import ImagePlaceholder from "../assets/ImagePlaceholder";

const DragAndDrop = (props) => {
  const { clear } = props;

  //states of the forms
  const [form, setForm] = useState({
    image: [],
  });

  function clearImage() {
    props.onSetImage([]);
    setForm({ ...form, image: [] });
  }

  const [fileUrl, setFileUrl] = useState(null);

  function processImage() {
    if (form.image.length > 0) {
      const imageUrl = URL.createObjectURL(form.image[0]);
      setFileUrl(imageUrl);
    }
  }

  useEffect(() => {
    processImage();

    if (form.image.length === 0) {
      setFileUrl(() => null);
    }
  }, [form]);

  const handleDropImageThum = useCallback((acceptedFile) => {
    if (acceptedFile) {
      setForm({ ...form, image: acceptedFile });
      if (props.onSetImage) {
        props.onSetImage(acceptedFile);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: handleDropImageThum,
    accept: "image/*",
  });

  useEffect(() => {
    if (clear !== null) {
      setForm({ ...form, image: clear });
    }
  }, [clear]);

  return (
    <>
      <div className="container-dragAndDrop" {...getRootProps()}>
        {/* {fileUrl !== null ? ( */}
        {true ? (
          <img className="container-dragAndDrop" src={'https://bucket-mana.s3.amazonaws.com/partner/onetap.png'} alt="pict" />
        ) : (
          <ImagePlaceholder />
        )}
        {/* <input {...getInputProps()} /> */}
      </div>
      {/* <div>
        {" "}
        {form.image.length !== 0 ? (
          <div className="centerConten">
            <p className="stylestext" numberoflines={1}>
              {form.image[0].name}
              <i onClick={() => clearImage()}></i>
            </p>
          </div>
        ) : (
          <div className="centerConten">
            <p className="stylestext" numberoflines={1}>
              Drag or click to insert a Image
            </p>
          </div>
        )}
      </div> */}
    </>
  );
};
export default DragAndDrop;

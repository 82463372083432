import * as React from "react";

const SelectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 7.427}
    height={props.height ? props.height : 15.818}
    viewBox="0 0 10.427 18.818"
    {...props}
  >
    <g data-name="Grupo 16514" fill="#767676">
      <path
        d="M5.213 18.818a1.494 1.494 0 0 1-1.155-.556l-3.656-4.43a1.824 1.824 0 0 1-.226-1.92A1.529 1.529 0 0 1 1.556 11h7.315a1.529 1.529 0 0 1 1.381.912 1.824 1.824 0 0 1-.226 1.92l-3.657 4.43a1.494 1.494 0 0 1-1.156.556Z"
        opacity={0.475}
      />
      <path
        data-name="arrow-down-fill"
        d="M5.214 0a1.494 1.494 0 0 0-1.155.556L.402 4.986a1.824 1.824 0 0 0-.226 1.92 1.529 1.529 0 0 0 1.381.912h7.315a1.529 1.529 0 0 0 1.381-.912 1.824 1.824 0 0 0-.226-1.92L6.37.556A1.494 1.494 0 0 0 5.214 0Z"
        opacity={0.475}
      />
    </g>
  </svg>
);

export default SelectIcon;

/* eslint-disable react-hooks/exhaustive-deps */
import "../assets/styles/Nav-bar.css";
import User from "./../assets/user2.jpg";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import friendService from "../services/friend.service";
import Notifications from "./Notifications";
import Home from "../assets/Home";
import ArrowDown from "../assets/ArrowDown";
import S3 from "../services/Aws/s3";
import userService from "../services/users";
import React, { useEffect, useState, useRef } from "react";
import { getData } from "../apis";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {
    setError,
    setNavBarSize,
    setLoading,
    setSuccess,
    updateProfile,
} from "../actions";
import { useManaWallet } from "../custom-providers/useWallet";

import { useDisconnect, useActiveWallet } from "thirdweb/react";
const NavBar = ({ user, navbarSize }) => {
    const dispatch = useDispatch();
    const [modalNotifications, setModalNotifications] = useState(false);
    const [respNotifications, setrespNotifications] = useState([]);
    const [newNotify, setNewNotify] = useState(false);
    const [isCopySuccess, setIsCopySuccess] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [autosave, setAutoSave] = useState(false);

    const { walletAddress, walletBalance, setWalletAddress, setWalletBalance } = useManaWallet();

    const { disconnect } = useDisconnect();
    const wallet = useActiveWallet();

    const imgRef = useRef();
    const processImage = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile !== undefined) {
            setFormUpdateUser({
                ...FormUpdateUser,
                urlPhoto: event.target.files[0],
            });
            const imageUrl = URL.createObjectURL(imageFile);
            setFileUrl(imageUrl);
        }
        if (autosave) {
            ChangePhoto(event.target.files[0]);
            setAutoSave(false);
        }
    };
    const sendPictures3 = async (urlPhoto) => {
        dispatch(setLoading(true));
        try {
            if (urlPhoto !== null) {
                await S3.sendImage(urlPhoto, function (data) {
                    if (data) {
                        updateUserInformation(data.Location);
                    }
                });
            } else {
                if (
                    (FormUpdateUser.urlPhoto.length !== 0) &
                    (FormUpdateUser.urlPhoto !== undefined)
                ) {
                    await S3.sendImage(
                        FormUpdateUser.urlPhoto,
                        function (data) {
                            if (data) {
                                updateUserInformation(data.Location);
                            }
                        }
                    );
                }
            }
        } catch (error) {
            dispatch(setError("Something went wrong uploading the image."));
        }
    };
    const ChangePhoto = (urlPhoto = null) => {
        if (FormUpdateUser.urlPhoto !== "") {
            sendPictures3(urlPhoto);
        }
    };

    const [FormUpdateUser, setFormUpdateUser] = useState({
        imgUser: user.urlPhoto,
    });

    const updateUserInformation = async (urlImages3) => {
        dispatch(setLoading(true));
        const respupdateUserInformation = await userService.updateUser(
            user._id,
            FormUpdateUser,
            urlImages3 ? urlImages3 : ""
        );
        if (respupdateUserInformation.success) {
            dispatch(updateProfile(respupdateUserInformation.data.data));
            dispatch(setSuccess("Updated Player Profile"));
        } else {
            dispatch(setError(respupdateUserInformation.data.message));
        }
    };

    const openModalNotifications = async () => {
        setModalNotifications(true);
    };

    //This function get the notifications that friendService.getNotifications sends.
    const getNoti = async () => {
        let res = await friendService.getNotifications();

        if (res.success) {
            const modifiedResults = res.data.results.map((notification) => {
                return { ...notification };
            });
            const filteredArray = modifiedResults.filter((item) => {
                return item.toUserId === user._id;
            });
            setrespNotifications(filteredArray);
            const allViewed = filteredArray.every(
                (notification) => notification.view === true
            );
            setNewNotify(!allViewed);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(walletAddress)
            .then(() => {
                setIsCopySuccess(true);
                setTimeout(() => {
                    setIsCopySuccess(false);
                }, 1500);
            })
            .catch((error) => {
                // Handle error, if any.
            });
    };

    const getWalletData = React.useCallback(async () => {
        const resp = await getData("/v1/wallet?coinToken=MNG");
        if (resp) {
            setWalletAddress(resp.data.address);
            setWalletBalance(parseFloat(resp.data.balance).toFixed(2));
        }
    });

    useEffect(() => {
        getNoti();
        getWalletData()
    }, []);

    return (
        <>
            {/*----------------------------------------NAV-BAR---------------------------------------*/}
            <nav className="navbar navbar-expand-lg navbar-dark top-bar">
                <div className="container-fluid cont">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasExample"
                        aria-controls="offcanvasExample"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="top-bar-center-logo"> </div>
                    <div
                        className="collapse navbar-collapse box "
                        id="navbarSupportedContent"
                    >
                        <div className="col-md-8   left-container">
                            <div className="top-bar-left-button">
                                <div className="top-bar-icon">
                                    <div
                                        onClick={() =>
                                            dispatch(
                                                setNavBarSize(
                                                    navbarSize === "small"
                                                        ? "expand"
                                                        : "small"
                                                )
                                            )
                                        }
                                    >
                                        <Home height={40} width={40} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  rigth-container">
                            <div className="top-bar-right-info">
                                <div className="d-flex">
                                    <div className={`wallet-address ${isCopySuccess ? "success" : ""}`} onClick={copyToClipboard}>
                                        {walletAddress.slice(0, 6) + "..." + walletAddress.slice(-4)}
                                        <FileCopyOutlinedIcon className="clipboard-icon" />
                                    </div>
                                    <div className="wallet-balance">
                                        {walletBalance}{" MNG"}
                                    </div>
                                </div>
                            </div>
                            <div className="top-bar-rigth-button-icon">
                                <div className="top-bar-icon">
                                    <NotificationsActiveOutlinedIcon
                                        onClick={openModalNotifications}
                                    />
                                    {newNotify ? (
                                        <div className="Circle1"></div>
                                    ) : null}
                                    {modalNotifications === true ? (
                                        <Notifications
                                            data={respNotifications}
                                            error={() =>
                                                dispatch(
                                                    setError(
                                                        "Something went wrong. Please try again"
                                                    )
                                                )
                                            }
                                            update={() => getNoti()}
                                            close={async (state) => {
                                                setModalNotifications(state);
                                                await friendService.markAsSeenAll();
                                                await getNoti();
                                                setNewNotify(false);
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <UncontrolledDropdown>
                                <DropdownToggle className="DropdownTogglestyles">
                                    {/* HERE IS THE WALLET DATA SHOWING, DON'T LOSE IT */}
                                    <img
                                        src={
                                            user.urlPhoto ? user.urlPhoto : User
                                        }
                                        className="top-bar-rigth-button-image"
                                        alt="userPicture"
                                    />
                                    <div className="top-bar-rigth-button-text">
                                        Hi, {user.userName}
                                    </div>
                                    <ArrowDown />
                                </DropdownToggle>
                                <DropdownMenu className="colormenu">
                                    <DropdownItem className="colormenu">
                                        <input
                                            id="photo"
                                            type="file"
                                            style={{ display: "none" }}
                                            ref={imgRef}
                                            onChange={processImage}
                                            accept="image/*"
                                        />
                                        <i
                                            className="ChangePasswordItem"
                                            onClick={() => {
                                                if (fileUrl === null) {
                                                    try {
                                                        imgRef.current.click();
                                                    } catch (error) {
                                                        console.error(error);
                                                    }

                                                    setAutoSave(true);
                                                } else {
                                                    ChangePhoto();
                                                }
                                            }}
                                        >
                                            Change Profile Picture
                                        </i>
                                    </DropdownItem>
                                    <DropdownItem className="colormenu">
                                        <i
                                            className="ChangePasswordItem"
                                            onClick={() => disconnect(wallet)}
                                        >
                                            Log Out
                                        </i>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    <button
                        className="navbar-toggler  "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-caret-down"></i>
                    </button>
                </div>
            </nav>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        navbarSize: state.alerts.navbarSize,
    };
};
export default connect(mapStateToProps)(NavBar);

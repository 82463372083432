import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setError, setLoading, setSuccess } from "../actions";
import HeaderSection from "../components/HeaderSection";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import message from "../services/contact.service";

const ContactUs = ({ navbarSize, user }) => {
  const [fields, setFields] = useState({
    user: user._id,
    email: user.email,
    message: "",
  });
  const dispatch = useDispatch();
  const submit = async () => {
    dispatch(setLoading(true));
    let res = await message.postmessage(fields);
    if (res.success && res.data.status) {
      dispatch(setSuccess("Message sent"));
      setFields({ message: "" });
    } else {
      dispatch(setError(res.data.message));
    }
    dispatch(setLoading(false));
  };
  return (
    <>
      <NavBar />
      <SideBar />
      <div
        className={
          navbarSize === "small" ? "custom-container2" : "custom-container"
        }
      >
        <div style={{ paddingLeft: 20 }}>
          <HeaderSection title="Contact" />
        </div>
        <div className="row" style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="col-12">
            <div className="containerlabel">
              <div className="ST-inputs-title">Email</div>
            </div>
            <div className="ST-inputs-container">
              <input
                type="text"
                placeholder="Email"
                value={fields.email}
                onChange={({ target }) =>
                  setFields({
                    ...fields,
                    email: target.value,
                  })
                }
                className="ST-input-l"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="containerlabel">
              <div className="ST-inputs-title">Message</div>
            </div>
            <div className="ST-inputs-container2">
              <textarea
                type="text"
                placeholder="Message"
                className="ST-input-l-c2"
                value={fields.message}
                onChange={({ target }) =>
                  setFields({
                    ...fields,
                    message: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-12" style={{ marginTop: 20, marginBottom: 20 }}>
            <button
              className="ST-c-l-bt"
              onClick={() =>
                fields.email !== "" && fields.message !== ""
                  ? submit()
                  : dispatch(setError("All fields are required!"))
              }
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    navbarSize: state.alerts.navbarSize,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(ContactUs);

import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './EditorComponent.css';

const EditorComponent = ({ onValueChange }) => {
  const [editorValue, setEditorValue] = useState('');
  const editorRef = useRef(null);
  const characterLimit = 250;

  const handleChange = (newValue) => {
    if (newValue.length <= characterLimit) {
      setEditorValue(newValue);
      onValueChange(newValue);
    } else {
      editorRef.current.getEditor().history.undo();
    }
  };

  const remainingCharacters = characterLimit - editorValue.length;

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }], 
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline'],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
      ],
    },
  };

  const formats = [
    'font',
    'list',
    'bullet',
    'align',
    'bold',
    'italic',
    'underline',
    'link',
    'color',
    'background',
  ];

  return (
    <div>
      <ReactQuill
        ref={editorRef}
        value={editorValue}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
      {remainingCharacters <= 50 && (
        <div className="character-count">
          Characters remaining: {remainingCharacters}
        </div>
      )}
     
    </div>
  );
};

export default EditorComponent;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RejectReasonTag = void 0;
/*
 * An enum containing all the possible reject reasons that can be
 * received from a node as a response to a transaction submission.
 *
 * This should be kept in sync with the list of reject reasons
 * found here: https://github.com/Concordium/concordium-base/blob/main/haskell-src/Concordium/Types/Execution.hs
 */
var RejectReasonTag;
(function (RejectReasonTag) {
    // Module not "well formed", meaning it is not a valid smart contract
    RejectReasonTag["ModuleNotWF"] = "ModuleNotWF";
    RejectReasonTag["ModuleHashAlreadyExists"] = "ModuleHashAlreadyExists";
    RejectReasonTag["InvalidAccountReference"] = "InvalidAccountReference";
    RejectReasonTag["InvalidInitMethod"] = "InvalidInitMethod";
    RejectReasonTag["InvalidReceiveMethod"] = "InvalidReceiveMethod";
    RejectReasonTag["InvalidModuleReference"] = "InvalidModuleReference";
    RejectReasonTag["InvalidContractAddress"] = "InvalidContractAddress";
    RejectReasonTag["RuntimeFailure"] = "RuntimeFailure";
    RejectReasonTag["AmountTooLarge"] = "AmountTooLarge";
    RejectReasonTag["SerializationFailure"] = "SerializationFailure";
    RejectReasonTag["OutOfEnergy"] = "OutOfEnergy";
    RejectReasonTag["RejectedInit"] = "RejectedInit";
    RejectReasonTag["RejectedReceive"] = "RejectedReceive";
    RejectReasonTag["NonExistentRewardAccount"] = "NonExistentRewardAccount";
    RejectReasonTag["InvalidProof"] = "InvalidProof";
    RejectReasonTag["AlreadyABaker"] = "AlreadyABaker";
    RejectReasonTag["NotABaker"] = "NotABaker";
    RejectReasonTag["InsufficientBalanceForBakerStake"] = "InsufficientBalanceForBakerStake";
    RejectReasonTag["StakeUnderMinimumThresholdForBaking"] = "StakeUnderMinimumThresholdForBaking";
    RejectReasonTag["BakerInCooldown"] = "BakerInCooldown";
    RejectReasonTag["DuplicateAggregationKey"] = "DuplicateAggregationKey";
    RejectReasonTag["NonExistentCredentialID"] = "NonExistentCredentialID";
    RejectReasonTag["KeyIndexAlreadyInUse"] = "KeyIndexAlreadyInUse";
    RejectReasonTag["InvalidAccountThreshold"] = "InvalidAccountThreshold";
    RejectReasonTag["InvalidCredentialKeySignThreshold"] = "InvalidCredentialKeySignThreshold";
    RejectReasonTag["InvalidEncryptedAmountTransferProof"] = "InvalidEncryptedAmountTransferProof";
    RejectReasonTag["InvalidTransferToPublicProof"] = "InvalidTransferToPublicProof";
    RejectReasonTag["EncryptedAmountSelfTransfer"] = "EncryptedAmountSelfTransfer";
    RejectReasonTag["InvalidIndexOnEncryptedTransfer"] = "InvalidIndexOnEncryptedTransfer";
    RejectReasonTag["ZeroScheduledAmount"] = "ZeroScheduledAmount";
    RejectReasonTag["NonIncreasingSchedule"] = "NonIncreasingSchedule";
    RejectReasonTag["FirstScheduledReleaseExpired"] = "FirstScheduledReleaseExpired";
    RejectReasonTag["ScheduledSelfTransfer"] = "ScheduledSelfTransfer";
    RejectReasonTag["InvalidCredentials"] = "InvalidCredentials";
    RejectReasonTag["DuplicateCredIDs"] = "DuplicateCredIDs";
    RejectReasonTag["NonExistentCredIDs"] = "NonExistentCredIDs";
    RejectReasonTag["RemoveFirstCredential"] = "RemoveFirstCredential";
    RejectReasonTag["CredentialHolderDidNotSign"] = "CredentialHolderDidNotSign";
    RejectReasonTag["NotAllowedMultipleCredentials"] = "NotAllowedMultipleCredentials";
    RejectReasonTag["NotAllowedToReceiveEncrypted"] = "NotAllowedToReceiveEncrypted";
    RejectReasonTag["NotAllowedToHandleEncrypted"] = "NotAllowedToHandleEncrypted";
    RejectReasonTag["MissingBakerAddParameters"] = "MissingBakerAddParameters";
    RejectReasonTag["FinalizationRewardCommissionNotInRange"] = "FinalizationRewardCommissionNotInRange";
    RejectReasonTag["BakingRewardCommissionNotInRange"] = "BakingRewardCommissionNotInRange";
    RejectReasonTag["TransactionFeeCommissionNotInRange"] = "TransactionFeeCommissionNotInRange";
    RejectReasonTag["AlreadyADelegator"] = "AlreadyADelegator";
    RejectReasonTag["InsufficientBalanceForDelegationStake"] = "InsufficientBalanceForDelegationStake";
    RejectReasonTag["MissingDelegationAddParameters"] = "MissingDelegationAddParameters";
    RejectReasonTag["InsufficientDelegationStake"] = "InsufficientDelegationStake";
    RejectReasonTag["DelegatorInCooldown"] = "DelegatorInCooldown";
    RejectReasonTag["NotADelegator"] = "NotADelegator";
    RejectReasonTag["DelegationTargetNotABaker"] = "DelegationTargetNotABaker";
    RejectReasonTag["StakeOverMaximumThresholdForPool"] = "StakeOverMaximumThresholdForPool";
    RejectReasonTag["PoolWouldBecomeOverDelegated"] = "PoolWouldBecomeOverDelegated";
    RejectReasonTag["PoolClosed"] = "PoolClosed";
})(RejectReasonTag = exports.RejectReasonTag || (exports.RejectReasonTag = {}));

import React from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import PostChallanger from "../components/Settings-Items-Post-Challenge";
import { connect } from "react-redux";

const PostChallengePage = ({ navbarSize }) => {
  return (
    <>
      <NavBar />
      <SideBar />
      <div
        className={
          navbarSize === "expand" ? "custom-container" : "custom-container2"
        }
      >
        <PostChallanger />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return { navbarSize: state.alerts.navbarSize };
};
export default connect(mapStateToProps)(PostChallengePage);

import React, { useState, useEffect } from "react";
import useAuthContex from "../hooks/UseAuthContext";
import { useHistory } from "react-router-dom";
import "../assets/styles/ChangePassword.css";
import { Auth } from "aws-amplify";
import AlertUser from "../Alerts/userAlerts/index";

const VerifiCodeToChangePassword = () => {
  const { navbarSize, logOut } = useAuthContex();
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");

  const changePassword = async () => {
    if ((code !== "") & (newPassword !== "") & (confirmNewPassword !== "")) {
      if (newPassword === confirmNewPassword) {
        try {
          const resp = await Auth.forgotPasswordSubmit(
            username,
            code,
            newPassword
          );
          if (resp === "SUCCESS") {
            logOut();
            history.push("/signIn");
          }
        } catch (error) {
          AlertUser.invalidCode(error.message);
        }
      } else {
        AlertUser.differentpasswords();
      }
    } else {
      AlertUser.allFieldsRequired();
    }
  };

  useEffect(() => {
    setUsername(window.localStorage.getItem("email-verify"));
  }, []);

  return (
    <div className="Auth-pages-container-complete-profile-Change-Password">
      <div
        className={
          navbarSize === "small"
            ? "ST-custom-container2"
            : "ST-custom-container"
        }
      >
        <div className="row">
          <div className="containerChangePassword">
            <div className="Auth-pages-top-bar21">
              <div className="changePasswordtitle">
                <h4>Change Password</h4>
              </div>

              <div className="Auth-pages-left-inputs-container">
                <div className="containerCode">
                  <div className="icon-user">
                    <i className="fas fa-eye"></i>
                  </div>
                  <input
                    className="top-input-emaill"
                    type="text"
                    placeholder="Insert Your Email Code"
                    value={code}
                    onChange={({ target }) => setCode(target.value)}
                  />
                </div>

                <div className="containerNewPasswordd">
                  <div className="icon-user-New-Password">
                    <i className="far fa-eye-slash "></i>
                  </div>
                  <input
                    className="top-input-firstNamee"
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={({ target }) => setnewPassword(target.value)}
                  />
                </div>

                <div className="containerConfirmNewPassword">
                  <div className="icon-user-Confirm-New-Password">
                    <i className="far fa-eye-slash "></i>
                  </div>
                  <input
                    className="top-input-firstNamee"
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={({ target }) =>
                      setconfirmNewPassword(target.value)
                    }
                  />
                </div>
                <button className="signUp2" onClick={changePassword}>
                  Change Password
                </button>

                <div className="container-Dont-Received">
                  <div className="resendMail">Don’t Received?</div>
                  <div
                    className="resendMaill"
                    onClick={() => history.push("/ChangePassword")}
                  >
                    Resend
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiCodeToChangePassword;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkParameterLength = exports.getContractName = void 0;
const CONTRACT_PARAM_MAX_LENGTH = 1024;
/**
 * Gets the contract name from an {@link InstanceInfo} object.
 *
 * @throws If name is not structured as expected
 */
const getContractName = ({ name }) => {
    if (!name.startsWith('init_')) {
        throw new Error('Could not get name from contract instance info.');
    }
    return name.substring(5);
};
exports.getContractName = getContractName;
/**
 * Checks if a buffer is larger than what is accepted for smart contract parameters
 *
 * @param {Buffer} buffer - The buffer to check
 *
 * @returns {void} nothing.
 *
 * @throws If buffer exceeds max length allowed for smart contract parameters
 */
const checkParameterLength = (buffer) => {
    if (buffer.length > CONTRACT_PARAM_MAX_LENGTH) {
        throw new Error(`Serialized parameter exceeds max length of smart contract parameter (${CONTRACT_PARAM_MAX_LENGTH} bytes)`);
    }
};
exports.checkParameterLength = checkParameterLength;

import React from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Friendingg from "../components/Settings-Items-Friending";

export default function FriendingPage() {
  return (
    <>
      {/*------- NAV-BA------- */}
      <NavBar />
      {/*------- NAV-BA------- */}

      {/*------- left-bar ------- */}
      <SideBar />
      {/*------- left-bar ------- */}
      {/*------- Page-Content ------- */}
      <Friendingg />
      {/*------- Page-Content ------- */}
    </>
  );
}

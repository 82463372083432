import React from "react";
import "../assets/styles/NewRelease.css";
import { NFT, NewRelease } from "../routes/routes";
import useAuthContex from "../hooks/UseAuthContext";
import { NavLink } from "react-router-dom";

const New_Release_NFT = () => {
  // const { newTokenn, NewToken, navbarSize } = useAuthContex();
  const { navbarSize } = useAuthContex();
  return (
    <>

      <div
        className={
          navbarSize === "small"
            ? "NFT-I-custom-container2"
            : "NFT-I-custom-container"
        }
      >
        <div className="     NFT-I-top-container   container-fluid   ">
          <div className="   row">
            <div className="   col-md-9   NFT-I-top-container-l">
              <select name="cars" className="   NFT-I-custom-select">
                <option value="audi">Categories</option>
              </select>

              <NavLink
                activeclassname="NFT-I-custom-bt-c-active"
                to={NFT}
                className="NFT-I-custom-bt-r"
              >
                Recommended
              </NavLink>

              <NavLink
                activeclassname="NFT-I-custom-bt-c-active"
                className="NFT-I-custom-bt-c"
                to={NewRelease}
              >
                New Releases
              </NavLink>
            </div>
            <div className="   col-md-3   NFT-I-top-container-r">
              <button className="   NFT-I-custom-bt-icon">
                <i className="   fas fa-qrcode" />
              </button>
              <button className="   NFT-I-custom-bt-icon">
                <i className="   fas fa-ellipsis-v"></i>
              </button>
            </div>
          </div>

          <div className="   row">
            <div> <div className="Comming-Soon-Settings ">coming soon</div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default New_Release_NFT;

import React from "react";
import NavBar from "../components/NavBar";
import SettingsItemsRegisterGamerProfile from "../components/Settings-Items-register-gamer-profile";
import SideBar from "../components/SideBar";

export default function RegisterGamerProfilePage() {
  return (
    <>
      {/*------- NAV-BA------- */}
      <NavBar />
      {/*------- NAV-BA------- */}

      {/*------- left-bar ------- */}
      <SideBar />
      {/*------- left-bar ------- */}
      {/*------- Page-Content ------- */}
      <SettingsItemsRegisterGamerProfile />
      {/*------- Page-Content ------- */}
    </>
  );
}

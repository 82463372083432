import React from "react";
import "../assets/styles/Setting-items.css";
import PostChallange from "./PostChallange";
import Subnavigation from "./Subnavigation";
import HeaderSection from "./HeaderSection";

const PostChallanger = () => {
  const location = {
    location: "Post Challange",
    description: "INVITE YOUR FRIENDS AND",
    title: "Set Up A Challenge",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <HeaderSection
              title={location.title}
              subtitle={location.description}
            />
          </div>
        </div>
        <Subnavigation />
        <PostChallange />
      </div>
    </>
  );
};
export default PostChallanger;

import * as React from "react";

const Answer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 16}
    height={props.height ? props.height : 16}
    {...props}
    viewBox="0 0 16 16"
  >
    <g data-name="vuesax/bold/message-text">
      <path
        data-name="Vector"
        d="M10.666 1.333H5.333q-4 0-4 4V14a.669.669 0 0 0 .667.667h8.666q4 0 4-4V5.333q0-4-4-4Zm-1.333 8.833H4.666a.5.5 0 0 1 0-1h4.667a.5.5 0 0 1 0 1Zm2-3.333H4.666a.5.5 0 1 1 0-1h6.667a.5.5 0 0 1 0 1Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Answer;

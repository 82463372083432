import * as React from "react";

const Home = (props) => (
  <svg
    data-name="Componente 32 \u2013 3"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 48.007}
    height={props.height ? props.height : 48.007}
    viewBox="0 0 48.007 48.007"
    {...props}
  >
    <g data-name="Trazado 68649" fill="#141414">
      <path d="M24 0A24 24 0 1 1 0 24 24 24 0 0 1 24 0Z" />
      <path
        d="M24.003.5a23.425 23.425 0 0 0-16.62 6.884 23.425 23.425 0 0 0-6.883 16.62 23.425 23.425 0 0 0 6.884 16.62 23.426 23.426 0 0 0 16.62 6.883 23.426 23.426 0 0 0 16.62-6.884 23.426 23.426 0 0 0 6.883-16.62 23.426 23.426 0 0 0-6.884-16.62A23.425 23.425 0 0 0 24.003.501m0-.5C37.26 0 48.007 10.747 48.007 24.003c0 13.257-10.747 24.004-24.004 24.004C10.747 48.007 0 37.26 0 24.003 0 10.747 10.747 0 24.003 0Z"
        fill="rgba(255,255,255,0.03)"
      />
    </g>
    <g data-name="menu (3)" fill="#b9b9b9">
      <path
        data-name="Trazado 68685"
        d="M20.666 23.352h-4.4a2.69 2.69 0 0 1-2.685-2.687v-4.4a2.69 2.69 0 0 1 2.687-2.685h4.4a2.69 2.69 0 0 1 2.685 2.687v4.4a2.69 2.69 0 0 1-2.687 2.685Zm-4.398-8.472a1.386 1.386 0 0 0-1.387 1.387v4.4a1.386 1.386 0 0 0 1.387 1.382h4.4a1.386 1.386 0 0 0 1.382-1.384v-4.4a1.386 1.386 0 0 0-1.384-1.385Z"
      />
      <path
        data-name="Trazado 68686"
        d="M31.741 23.352h-4.4a2.69 2.69 0 0 1-2.685-2.687v-4.4a2.69 2.69 0 0 1 2.687-2.685h4.4a2.69 2.69 0 0 1 2.687 2.687v4.4a2.69 2.69 0 0 1-2.689 2.685Zm-4.4-8.469a1.386 1.386 0 0 0-1.385 1.384v4.4a1.386 1.386 0 0 0 1.384 1.384h4.4a1.386 1.386 0 0 0 1.384-1.384v-4.4a1.386 1.386 0 0 0-1.383-1.387Z"
      />
      <path
        data-name="Trazado 68687"
        d="M29.542 34.427a4.886 4.886 0 1 1 4.886-4.886 4.892 4.892 0 0 1-4.886 4.886Zm0-8.469a3.583 3.583 0 1 0 3.583 3.583 3.587 3.587 0 0 0-3.583-3.586Z"
      />
      <path
        data-name="Trazado 68688"
        d="M20.666 34.427h-4.4a2.69 2.69 0 0 1-2.685-2.687v-4.4a2.69 2.69 0 0 1 2.687-2.685h4.4a2.69 2.69 0 0 1 2.687 2.687v4.4a2.69 2.69 0 0 1-2.689 2.685Zm-4.4-8.469a1.386 1.386 0 0 0-1.385 1.384v4.4a1.386 1.386 0 0 0 1.384 1.384h4.4a1.386 1.386 0 0 0 1.384-1.384v-4.4a1.386 1.386 0 0 0-1.383-1.387Z"
      />
    </g>
  </svg>
);

export default Home;

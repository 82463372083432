import * as React from "react";

const Submit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 35}
    height={props.height ? props.height : 35}
    viewBox="0 0 35 35"
    {...props}
  >
    <path
      d="M27.301 11.877 13.48 4.981C4.197.341.386 4.143 5.036 13.408l1.4 2.8a2.93 2.93 0 0 1 0 2.594l-1.4 2.787c-4.65 9.264-.856 13.067 8.444 8.427l13.82-6.9c6.2-3.087 6.2-8.146.001-11.239Zm-5.215 6.831h-8.719a1.208 1.208 0 1 1 0-2.417h8.718a1.208 1.208 0 1 1 0 2.417Z"
      fill="#fff"
      data-name="vuesax/bold/send"
    />
  </svg>
);

export default Submit;

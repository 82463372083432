import React, { useEffect, useState } from "react";
import "../assets/styles/Side-bar.css";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import User from "./../assets/user2.jpg";
import { useStyles } from "../hooks/useStyles";
import {
  homeGame,
  Challange,
  detailChallenge,
  ContactUs,
  Shop,
  Nfts,
} from "../routes/routes";
import { connect } from "react-redux";
import { Notification } from "../hooks/NotificationStream";

const SideBar = ({ user, navbarSize }) => {
  const { iconActive } = useStyles();
  const { pathname } = useLocation();
  const [chatNot, setChatNot] = useState(false);
  const history = useHistory();
  useEffect(() => {
    Notification.get().subscribe((res) => {
      setChatNot(res);
    });
  }, []);
  return (
    <>
      <div
        className={
          navbarSize === "expand"
            ? "offcanvas offcanvas-start sidebar-nav"
            : "offcanvas offcanvas-start sidebar-navv"
        }
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div
          className={
            navbarSize === "small"
              ? "container-fluid user-image-container2"
              : "container-fluid user-image-container"
          }
        >
          <img
            className="left-bar-image"
            src={user.urlPhoto ? user.urlPhoto : User}
            alt="User"
          ></img>
        </div>
        <div
          className={
            navbarSize === "small"
              ? "container-fluid sidebar-txt-user2 "
              : "container-fluid sidebar-txt-user "
          }
        >
          {user.userName}
        </div>
        {/* {navbarSize === "small" ? null : (
          <div className="container-fluid sidebar-txt-profile">profile</div>
        )} */}
        {navbarSize === "small" ? null : (
          <div className="container-fluid menu-txt-container">
            <div className="menu-T-container">menu</div>
          </div>
        )}

        <div className="container-fluid menu-options-Top-container  ">
          <div className="menu-i-container">
            {" "}
            <span>
              <i
                style={pathname === Challange ? iconActive : null}
                onClick={() => history.push("/GamesChallange")}
                className="fas fa-trophy"
              ></i>
            </span>
          </div>
          <NavLink
            activeclassname="activeClass"
            to="/GamesChallange"
            className="menu-i-container"
            id="GamesChallange"
          >
            Challenges
          </NavLink>
        </div>
        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            {" "}
            <span>
              <i
                style={pathname === homeGame ? iconActive : null}
                onClick={() => history.push("/Games")}
                className="fas fa-gamepad"
              ></i>
            </span>
          </div>
          <NavLink
            activeclassname="activeClass"
            to={homeGame}
            className="menu-i-container"
            id="home"
          >
            Games
          </NavLink>
        </div>
        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            {" "}
            <span>
              <i
                style={
                  pathname === Shop
                    ? iconActive
                    : null
                }
                onClick={() => history.push("/shop")}
                className="fas fa-shopping-cart"
              ></i>
            </span>
          </div>
          <NavLink
            activeclassname="activeClass"
            to={Shop}
            className="menu-i-container"
            id="shop"
          >
            Shop
          </NavLink>
        </div>
        {/* My Assets */}
        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            {" "}
            <span>
              <i
                style={
                  pathname === Nfts
                    ? iconActive
                    : null
                }
                onClick={() => history.push("/my-assets")}
                className="fas fa-images"
              ></i>
            </span>
          </div>
          <NavLink
            activeclassname="activeClass"
            to={Nfts}
            className="menu-i-container"
            id="nfts"
          >
            My Assets
          </NavLink>
        </div>
        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            {" "}
            <span>
              <i
                onClick={() => window.open('https://quest.playmana.gg')}
                className="fas fa-chess-rook"
              ></i>
            </span>
          </div>
          <a
            activeclassname="activeClass"
            className="menu-i-container"
            target="blank"
            href="https://quest.playmana.gg"
          >
            Quest
          </a>
        </div>
        {navbarSize === "small" ? null : (
          <div className="container-fluid menu-txt-container">
            <div className="menu-T-container">general</div>
          </div>
        )}
        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            <span>
              <i
                style={pathname === "/postchallenge" ? iconActive : null}
                onClick={() => history.push("/postchallenge")}
                className="fas fa-circle "
              ></i>
            </span>
          </div>
          <NavLink
            style={pathname === "/postchallenge" ? iconActive : null}
            activeclassname="activeClass"
            to="/postchallenge"
            className="menu-i-container"
          >
            Create Challenge
          </NavLink>
        </div>

        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            <span>
              <i
                style={pathname === "/registergamerprofile" ? iconActive : null}
                onClick={() => history.push("/registergamerprofile")}
                className="fas fa-user"
              ></i>
            </span>
          </div>
          <NavLink
            style={pathname === "/registergamerprofile" ? iconActive : null}
            activeclassname="activeClass"
            to="/registergamerprofile"
            className="menu-i-container"
          >
            Profile
          </NavLink>
        </div>

        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            <span>
              <i
                style={pathname === "/Friending" ? iconActive : null}
                onClick={() => history.push("/Friending")}
                className="fas fa-users"
              ></i>
            </span>
          </div>
          <NavLink
            style={pathname === "/Friending" ? iconActive : null}
            activeclassname="activeClass"
            to="/Friending"
            className="menu-i-container"
          >
            Friends
          </NavLink>
        </div>

        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            <span style={{ position: "relative" }}>
              <i
                style={pathname === "/chatpage" ? iconActive : null}
                onClick={() => history.push("/chatpage")}
                className="fas fa-comment"
              ></i>
              {chatNot ? (
                <div
                  className="Circle1"
                  style={{
                    position: "absolute",
                    top: -5,
                    left: -5,
                    width: 0.3,
                    height: 0.3,
                  }}
                />
              ) : null}
            </span>
          </div>
          <NavLink
            style={pathname === "/chatpage" ? iconActive : null}
            activeclassname="activeClass"
            to="/chatpage"
            className="menu-i-container"
          >
            Chat
          </NavLink>
        </div>

        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            <span>
              <i
                style={pathname === "/Wallet" ? iconActive : null}
                onClick={() => history.push("/Wallet")}
                className="fas fa-wallet"
              ></i>
            </span>
          </div>
          <NavLink
            style={pathname === "/Wallet" ? iconActive : null}
            activeclassname="activeClass"
            to="/Wallet"
            className="menu-i-container"
          >
            Wallet
          </NavLink>
        </div>

        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            <span>
              <i
                style={pathname === "/contact-us" ? iconActive : null}
                onClick={() => history.push("/contact-us")}
                className="fas fa-envelope"
              ></i>
            </span>
          </div>
          <NavLink
            style={pathname === "/contact-us" ? iconActive : null}
            activeclassname="activeClass"
            to="/contact-us"
            className="menu-i-container"
          >
            Contact Us
          </NavLink>
        </div>
        {/*crean*/}
        <div className="container-fluid menu-options-container  ">
          <div className="menu-i-container">
            <span>
              <i
                style={pathname === ContactUs ? iconActive : null}
                onClick={() => history.push("#")}
                className=""
              ></i>
            </span>
          </div>
          <NavLink
            style={pathname === Challange ? iconActive : null}
            activeclassname="activeClass"
            to="#"
            className="menu-i-container"
          ></NavLink>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return { user: state.auth.user, navbarSize: state.alerts.navbarSize };
};
export default connect(mapStateToProps)(SideBar);

import React from "react";
import "../assets/styles/Home-items.css";

export default function Cards({ games }) {

  return (
    <>
      {games.toCreateChallenges === true && games.order > 5 ?
        <div className="col-md-6 col-lg-6 col-xl-4" >
          <a href={games.site}
            target="_blank"
            rel="noreferrer" >


            <div
              className="custom-game-image"
              style={{ backgroundImage: `url(${games.urlImage})` }}
            >
              <div className="custom-game-card-info">
                <div className="custom-game-card-info-title">
                  <div className="card-txt">{games.gameName}</div>
                </div>
                <div className="custom-game-card-info-platform-participants">
                  <div
                    className="card-bt"
                    href={games.site}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Join Now
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div> : null}
    </>
  )

}

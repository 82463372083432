import * as React from "react";

const AddFile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 35}
    height={props.height ? props.height : 35}
    {...props}
    viewBox="0 0 35 35"
  >
    <g data-name="vuesax/bold/close-circle">
      <path
        data-name="Sustracci\xF3n 63"
        d="M17.5 33A15.52 15.52 0 0 1 2 17.5 15.518 15.518 0 0 1 17.5 2 15.516 15.516 0 0 1 33 17.5 15.518 15.518 0 0 1 17.5 33Zm-6.2-16.665a1.164 1.164 0 0 0-1.162 1.163 1.16 1.16 0 0 0 .34.822 1.143 1.143 0 0 0 .822.34h5.041v5.041a1.158 1.158 0 0 0 .34.822 1.141 1.141 0 0 0 .822.34 1.177 1.177 0 0 0 1.162-1.163v-5.041h5.041a1.177 1.177 0 0 0 1.162-1.162 1.177 1.177 0 0 0-1.162-1.163h-5.041v-5.041a1.177 1.177 0 0 0-1.162-1.163 1.177 1.177 0 0 0-1.162 1.163v5.041Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default AddFile;

import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import Answer from "../assets/Answer";
import Dots from "../assets/Dots";
import "../assets/styles/Settings-Chatpage.css";
import Trash from "../assets/Trash";
import Reactions from "./Reactions";

export default function MessageBox({
  chats,
  currentImage,
  loadMore,
  username,
  deleteMessage,
  addReaction,
  deleteReaction,
  Reply,
}) {
  const [showReaction, setShowReaction] = useState(null);
  const [showOptions, setShowOptions] = useState(null);
  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop === 0) {
      loadMore();
    }
  };
  useEffect(() => {
    console.log(chats);
  }, []);
  const submitReaction = (item, type) => {
    setShowReaction(null);
    // get if user already react to the message
    let getIndex = item.latest_reactions.filter((i) => i.user_id === username);
    // last params is delete or not the reaction. user can add only one reaction
    addReaction(item.id, type, getIndex);
  };
  const showReactions = (item) => {
    // convert objet to array
    let reactionsArry = Object.entries(item.reaction_counts).map(
      ([key, value]) => ({ type: key, value })
    );
    return reactionsArry.map((reaction, indx) => (
      <li
        key={indx}
        onClick={() => {
          // get if user already react to the message
          let getIndex = item.latest_reactions.filter(
            (i) => i.user_id === username
          );
          // remove reaction or add new one
          if (getIndex.length > 0) {
            // check if user want to update the reaction or just remove it
            if (getIndex[0].type === reaction.type) {
              deleteReaction(getIndex[0].message_id, getIndex[0].type);
            } else {
              addReaction(item.id, reaction.type, getIndex);
            }
          } else {
            addReaction(item.id, reaction.type);
          }
        }}
      >
        <span>
          <i
            className={`fa ${
              reaction.type === "thumb"
                ? "fa-thumbs-up thumb"
                : reaction.type === "love"
                ? "fa-heart heart"
                : reaction.type === "laugh"
                ? "fa-laugh-squint face"
                : reaction.type === "sad"
                ? "fa-sad-cry face"
                : "fa-surprise face"
            }`}
          />
          {reaction.value}
        </span>
      </li>
    ));
  };
  return (
    <>
      <div
        className="C-chat-messages"
        id="messages-container"
        onScroll={handleScroll}
      >
        {chats.length > 0
          ? chats.map((data, index) => (
              <div
                className={
                  data.user.id !== username ? "content-LH" : "content-RH"
                }
                key={index}
              >
                {index > 0 ? (
                  chats[index - 1].user.id !== data.user.id ? (
                    <img
                      alt="User"
                      className="C-P-list-Item-Image-chat"
                      src={
                        data.user.id !== username
                          ? currentImage
                          : data.user.image
                          ? data.user.image
                          : `https://getstream.io/random_svg/?name=${data.user.name}`
                      }
                    />
                  ) : (
                    <div className="img-space" />
                  )
                ) : (
                  <img
                    alt="User"
                    className="C-P-list-Item-Image-chat"
                    src={
                      data.user.id !== username
                        ? currentImage
                        : data.user.image
                        ? data.user.image
                        : `https://getstream.io/random_svg/?name=${data.user.name}`
                    }
                  />
                )}
                <div className="message-cont">
                  <div className={data.user !== username ? "LH" : "RH"}>
                    {/* show reply for messages */}
                    {data.quoted_message ? (
                      <div className="reply-content">
                        <span>{data.quoted_message.user.id}</span>
                        {data.quoted_message.message}
                      </div>
                    ) : null}
                    {/* show image message */}
                    {data.image ? (
                      <img src={data.image} alt={`pict-${index}`} />
                    ) : null}
                    {data.message}
                    {/* show date message */}
                    <div
                      className="date"
                      style={{
                        justifyContent:
                          data.latest_reactions.length > 0
                            ? "space-between"
                            : "end",
                        bottom: data.latest_reactions.length > 0 ? -33 : -20,
                      }}
                    >
                      {/* show reactions */}
                      {data.latest_reactions.length > 0 ? (
                        <div className="current-reaction">
                          <ul>
                            {data.reaction_counts ? showReactions(data) : null}
                          </ul>
                        </div>
                      ) : null}
                      {moment(data.created_at).format("MM-DD hh:mm a")}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setShowOptions(index === showOptions ? null : index);
                      if (showReaction != null) setShowReaction(null);
                    }}
                    style={{ cursor: "pointer", marginLeft: 10 }}
                  >
                    <Dots />
                  </div>
                  {showOptions === index ? (
                    <div className="second-options">
                      <ul>
                        <li
                          onClick={() => {
                            setShowOptions(null);
                            Reply({
                              user: data.user.id,
                              content: data.message,
                              id: data.id,
                            });
                          }}
                        >
                          <span>Answer</span> <Answer />
                        </li>
                        <li
                          onClick={() => {
                            setShowReaction(index);
                            setShowOptions(null);
                          }}
                        >
                          <span>Reaction</span>
                          <i className="fa fa-smile" />
                        </li>
                        {/* show remove option only if current user is the one who send the message */}
                        {username === data.user.id ? (
                          <li
                            onClick={() => {
                              deleteMessage(data.id);
                              setShowOptions(null);
                            }}
                          >
                            <span style={{ color: "#FF4757" }}>Remove</span>
                            <Trash color="#FF4757" />
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  ) : null}
                  {/* show reaction option */}
                  {showReaction === index ? (
                    <Reactions
                      addReaction={(type) => {
                        submitReaction(data, type);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ))
          : null}
      </div>
    </>
  );
}

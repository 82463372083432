import React, { useEffect, useState } from "react";
import "../assets/styles/Wallet.css";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { PanelWallet } from "./wallet/PanelWallet";
import { FaCopy } from "react-icons/fa";
// import { IoMdAddCircle } from "react-icons/io";
// import { AiFillMinusCircle } from "react-icons/ai";
import { getData } from "../apis";
import { useManaWallet } from "../custom-providers/useWallet";
const Wallet = () => {
  const [dataManaWallet, setdataManaWallet] = useState();
  const [dataQorbiWallet, setdataQorbiWallet] = useState();
  const { walletAddress: navbarAddress } = useManaWallet();

  const dataWallet = async () => {
    const manaResp = await getData("/v1/wallet?coinToken=MNG");
    if (manaResp) {
      setdataManaWallet(manaResp.data);
    }
    const qorbiResp = await getData("/v1/wallet?coinToken=QORBI");
    if (qorbiResp) {
      setdataQorbiWallet(qorbiResp.data);
    }
  };

  useEffect(() => {
    dataWallet();
  }, []);

  return (
    <div className="Container">
      <div className="row ">
        <div
          className="col-sm-12 col-md-12 col-lg-5 d-flex justify-content-center"
          style={{ flexDirection: "column" }}
        >
          <Card className="card-wallet">
            <Card.Body>
              <div className="card-address-btns">
                <div className="address">
                  <span id="address" className="addressAccount" style={{ gap: 5 }}>
                    {dataManaWallet?.address || 'Loading Mana Wallet...'}
                  </span>
                  <FaCopy
                    onClick={() => navigator.clipboard.writeText(navbarAddress)}
                    className="icon-copy"
                  />
                </div>
                <div className="containerCurrentBalance">
                  <div className="currenceBalanceC">
                    <p className="currenceBalance">MNG Balance</p>
                    <div className="flow">
                      <p className="flowBalance">
                        MNG = {dataManaWallet?.balance || '$$$'}
                      </p>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="containerCurrentBalance">
                  <div className="currenceBalanceC">
                    <p className="currenceBalance">QORBI Balance</p>
                    <div className="flow">
                      <p className="flowBalance">
                        QORBI = {dataQorbiWallet?.balance || '$$$'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-7">
          <PanelWallet />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { wallet: state.wallet };
};
export default connect(mapStateToProps)(Wallet);

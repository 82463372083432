import React, { useEffect, useState } from "react";
import userAvatar from "../../assets/user2.jpg";

export default function SettingsItemFriending({
  user,
  option,
  buttonsFunction = {
    buttonA: () => {},
    buttonB: () => {},
  },
}) {
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    setDisable(user?.isFriendOrPendingRequest ? true : false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);
  return (
    <div className={`friends-item-list ${option}`}>
      <div className="friends-item-list-data-container">
        {option !== "pending" ? (
          <img
            className="friends-item-list-image"
            src={user.urlPhoto ? user.urlPhoto : userAvatar}
            alt=""
          />
        ) : (
          <img
            className="friends-item-list-image"
            src={user?.user?.urlPhoto ? user.user.urlPhoto : userAvatar}
            alt=""
          />
        )}
        <div>
          {option !== "pending" ? (
            <div className="friends-item-list-name">
              {window.screen.width < 376
                ? user.userName
                  ? user.userName.slice(0, 12)
                  : user.email
                : user.userName
                ? user.userName
                : user.email}
              {/* {user.userName ? user.userName : user.email} */}
            </div>
          ) : (
            <div className="friends-item-list-name">
              {user.user
                ? user.user.userName
                  ? user?.user?.userName
                  : user?.user?.email
                : user.userName
                ? user.userName
                : user.email}
            </div>
          )}
          {option === "pending" && (
            <div className="friends-item-list-status">
              incoming friend request
            </div>
          )}
        </div>
      </div>
      {option !== "all" ? (
        <div>
          {option === "pending" && user.iSent ? null : (
            <button
              onClick={() => buttonsFunction.buttonA()}
              className="friends-item-list-button"
            >
              <i
                className={
                  option === "online"
                    ? "fas fa-comment-alt"
                    : "fas fa-check-circle"
                }
              />
            </button>
          )}
          <button
            onClick={() => buttonsFunction.buttonB()}
            className="friends-item-list-button"
          >
            <i
              className={
                option === "online"
                  ? "fas fa-align-justify"
                  : "fas fa-times-circle"
              }
            />
          </button>
        </div>
      ) : (
        <div>
          <button
            className="friends-item-list-button-invite"
            onClick={() => {
              setDisable(true);
              buttonsFunction.buttonA();
            }}
            disabled={disable}
          >
            {disable ? "Invitation Sent" : "Invite Friend"}
          </button>
        </div>
      )}
    </div>
  );
}

import { ERROR, LOADING, NAVBARSIZE, SUCCESS } from "../actions/types";

/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  error: null,
  loading: { show: false, desc: "" },
  navbarSize: "expand",
  success: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload, error: null, success: null };
    case ERROR:
      return { ...state, error: action.payload, loading: false, success: null };
    case SUCCESS:
      return { ...state, success: action.payload, loading: false, error: null };
    case NAVBARSIZE:
      return { ...state, navbarSize: action.payload };
    default:
      return state;
  }
};

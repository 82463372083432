import axios from "axios";
import { getData, pathData, URL } from "../../apis";

const SignUp = async (idCognito, email, phone, ip) => {
  const payload = {};

  if (idCognito !== "") {
    payload.idCognito = idCognito;
    payload.email = email;
    payload.phone = phone;
  }

  if (ip !== "") {
    payload.iP = ip;
  }
  payload.noHaveEmailAndPhone = true;

  const { data } = await axios({
    method: "POST",
    url: URL + "/v1/user/auth",
    data: payload,
  });
  return data;
};

const SignIn = async (idCognito) => {
  const { data } = await axios({
    method: "GET",
    url: URL + "/v1/user/signin/" + idCognito,
  });
  return data;
};

const getUserInformation = async () => {
  const cognitoid = window.localStorage.getItem("cognitoId");
  const { data } = await axios({
    method: "GET",
    url: `${URL}/v1/user/signin/${cognitoid}`,
  });
  return data;
};

const getAllUser = async (
  page = 1,
  size = 10,
  lookingFor = "allUsers",
  username = ""
) => {
  if (username === "") {
    return getData(
      `/v1/user?page=${page}&size=${size}&lookingFor=${lookingFor}`
    );
  } else {
    return getData(
      `/v1/user?page=${page}&size=${size}&lookingFor=${lookingFor}&username=${username}`
    );
  }
};

const refreshToken = async (Token) => {
  const { data } = await axios({
    method: "post",
    url: URL + "/v1/user/auth/refreshToken",
    data: { refreshToken: window.localStorage.getItem("refreshToken") },
  });
  if (data.access.token) {
    window.localStorage.setItem("token", data.access.token);
    window.localStorage.setItem("refreshToken", data.refresh.token);
    return data;
  }
};

const searchUserByNickName = async (User) => {
  return getData(`/v1/user/search/by/${User}`);
};

const updateUser = async (userId, FormUpdateUser, urlImages3) => {
  const payload = {};
  if (FormUpdateUser.userName) {
    payload.userName = FormUpdateUser.userName;
  }
  if (FormUpdateUser.firstName) {
    payload.firstName = FormUpdateUser.firstName;
  }
  if (FormUpdateUser.lastName) {
    payload.lastName = FormUpdateUser.lastName;
  }
  if (FormUpdateUser.phone) {
    payload.phone = FormUpdateUser.phone;
  }

  if (urlImages3) {
    payload.urlPhoto = urlImages3;
  }

  if (FormUpdateUser.email && FormUpdateUser.phone) {
    payload.noHaveEmailAndPhone = false;
  } else {
    payload.noHaveEmailAndPhone = true;
  }
  if (FormUpdateUser.country) {
    payload.country = FormUpdateUser.country;
  }
  if (FormUpdateUser.city) {
    payload.city = FormUpdateUser.city;
  }
  return pathData(`/v1/user/updated/${userId}`, payload);
};

const CompleteProfile = async ({
  userId,
  username = "",
  firstName = "",
  lastName = "",
  country = "",
  city = "",
  photoURL = "",
}) => {
  const payload = {};
  if (username !== "") {
    payload.userName = username;
  }
  if (firstName !== "") {
    payload.firstName = firstName;
  }
  if (lastName !== "") {
    payload.lastName = lastName;
  }
  if (country !== "") {
    payload.country = country;
  }
  if (city !== "") {
    payload.city = city;
  }
  if (photoURL !== "") {
    payload.urlPhoto = photoURL;
  }
  return pathData(`/v1/user/updated/${userId}`, payload);
};

const userService = {
  SignUp,
  SignIn,
  refreshToken,
  searchUserByNickName,
  updateUser,
  CompleteProfile,
  getUserInformation,
  getAllUser,
};

export default userService;

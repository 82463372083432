/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
// import { viewdetailTXT } from "../../utils/networks/networkFlow";

// import { convertHash } from "./utils/convertHash";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { getData } from "../../apis";
import { convertHash } from "./convertHash";
import { useManaWallet } from "../../custom-providers/useWallet";
import axios from "axios";
import swal from "sweetalert";
import { utils } from "ethers";
import TransactionsTable from "./TransactionsTable";
import TransfersTable from "./TransfersTable";
import Pagination from "./Pagination";
const { formatEther } = utils;

export const TableTXT = ({ pressedButton }) => {
  const { coinToken, walletAddress } = useManaWallet();
  const [transactions, settransactions] = useState();
  const [page, setPage] = useState(1);
  const [pageTransfer, setPageTransfer] = useState(1);
  const [zkTransactions, setZkTransactions] = useState(null);
  const [zkTransfer, setZkTransfer] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesTransfer, setTotalPagesTransfer] = useState(0);
  const actualDate = new Date();
  const isoDate = actualDate.toISOString();
  const urlTxZk = "https://block-explorer-api.mainnet.zksync.io/transactions";
  const urlTransZk = `https://block-explorer-api.mainnet.zksync.io/address/${walletAddress}/transfers`;

  const weiToeth = (wei) => {
    return formatEther(wei);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      swal({
        title: "Copied!",
        text: "Text copied to clipboard.",
        icon: "success",
        timer: 1500,
        buttons: false,
      });
    });
  };
  useEffect(() => {
    if (pressedButton === "transaction") {
      const params = {
        // address: "0x587AE7AaFFA9001dfdf0a01AA84A9a146d7DF300",
        address: walletAddress,
        pageSize: 10,
        page,
        toDate: isoDate,
      };
      axios
        .get(urlTxZk, { params })
        .then((response) => {
          setZkTransactions(response.data.items);
          setTotalPages(response.data.meta.totalPages);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [page, walletAddress, pressedButton]);

  useEffect(() => {
    if (pressedButton === "transfer" && coinToken === "ZK") {
      const params = {
        toDate: isoDate,
        pageSize: 10,
        page: pageTransfer,
        type: "mint",
      };

      axios
        .get(urlTransZk, { params })
        .then((response) => {
          setZkTransfer(response.data.items);
          setTotalPagesTransfer(response.data.meta.totalPages);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [pageTransfer, walletAddress, pressedButton]);

  const getTransactions = async () => {
    const resp = await getData("/v1/user/get/manaCoinHistory?page=1&size=50");
    if (resp) {
      settransactions(resp.data.results);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <div className="table-responsive">
      <div className="sizeTable">
        {coinToken === "ZK" && pressedButton === "transaction" && (
          <>
            <TransactionsTable
              transactions={zkTransactions}
              handleCopy={handleCopy}
              weiToeth={weiToeth}
            />
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={setPage}
              items={zkTransactions}
            />
          </>
        )}
        {coinToken === "ZK" && pressedButton === "transfer" && (
          <>
            <TransfersTable transfers={zkTransfer} handleCopy={handleCopy} />
            <Pagination
              currentPage={pageTransfer}
              totalPages={totalPagesTransfer}
              onPageChange={setPageTransfer}
              items={zkTransfer}
            />
          </>
        )}
        {coinToken !== "ZK" && (
          <Table className="table-wallet " bordered>
            <thead className="pb-4">
              <tr>
                <th
                  style={{ width: "30px" }}
                  id="start-item-txt"
                  className="text-center title-txt font-weight-bold"
                >
                  #
                </th>
                <th
                  style={{ width: "100px" }}
                  className="text-center title-txt font-weight-bold"
                >
                  Date
                </th>
                <th
                  style={{ width: "150px" }}
                  className="text-center title-txt font-weight-bold"
                >
                  Hash
                </th>
                <th
                  style={{ width: "100px" }}
                  className="text-center title-txt font-weight-bold"
                >
                  Interactions
                </th>
                <th
                  id="finally-item-txt"
                  style={{ width: "100px" }}
                  className="text-center title-txt font-weight-bold"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="mt-4 tableBody">
              {transactions ? (
                transactions.length > 0 ? (
                  transactions.map((item, index) => (
                    <tr key={index} className="tableRow ">
                      <td className="text-center  m-4 paddingTable">
                        {index + 1}
                      </td>
                      <td className="text-center  m-4 paddingTable">
                        {moment(item.createdAt).format("DD MM YYYY ")}
                      </td>

                      {item.blockchainDetails ? (
                        <td className="text-center  m-4 paddingTable">
                          <p
                            id="view-txt"
                            className=""
                            target="_blank"
                            rel="noreferrer"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                item.blockchainDetails.hash
                              )
                            }
                          >
                            {convertHash(item.blockchainDetails.hash)}
                          </p>
                        </td>
                      ) : (
                        <td className="text-center  m-4 paddingTable">
                          <p
                            id="view-txt"
                            className=""
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: "1.2rem" }}
                          >
                            -
                          </p>
                        </td>
                      )}

                      <td className="text-center  m-4 paddingTable">
                        {item.usedIn}
                      </td>
                      {item.blockchainDetails ? (
                        <td className="text-center  m-4 paddingTable">
                          <span
                            className={
                              item.blockchainDetails.confirmations !== 0
                                ? "badge-success-td"
                                : "badge-error-td"
                            }
                          >
                            {item.blockchainDetails.confirmations !== 0
                              ? "success"
                              : "pending"}
                          </span>
                        </td>
                      ) : item.challenge !== null ? (
                        <td className="text-center  m-4 paddingTable">
                          <span className={"badge-success-td"}>success</span>
                        </td>
                      ) : (
                        <div className="default-">-</div>
                      )}
                    </tr>
                  ))
                ) : (
                  <div className="withoutTransactions">
                    No transactions found
                  </div>
                )
              ) : null}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

import React from 'react';
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import VerifiCodeToChangePassword from '../components/VerifiCodeToChangePassword';


const VerifiCodeToChangePasswordPage = () => {
    return (
        <>
             {/*------- NAV-BA------- */}
      <NavBar />
      {/*------- NAV-BA------- */}

      {/*------- left-bar ------- */}
      <SideBar />
      {/*------- left-bar ------- */}
      {/*------- Page-Content ------- */}
      <VerifiCodeToChangePassword />
      {/*------- Page-Content ------- */}
        </>
    )
}

export default VerifiCodeToChangePasswordPage

import * as React from "react";

const Game = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 14.085}
    height={props.height ? props.height : 14.085}
    viewBox="0 0 84.085 84.085"
    {...props}
  >
    <g data-name="Grupo 17467" fill={props.color ? props.color : "#00b894"}>
      <path d="M61.592 15.639h-39.1A22.518 22.518 0 0 0 0 38.132v23.46a22.518 22.518 0 0 0 22.493 22.492h39.1a22.518 22.518 0 0 0 22.492-22.493V38.132a22.518 22.518 0 0 0-22.493-22.493Zm-9.039 26.487a4.2 4.2 0 1 1 0 .084ZM34.181 59.153a3.146 3.146 0 0 1-4.457 0l-4.33-4.33-4.162 4.162a3.147 3.147 0 0 1-4.457 0 3.172 3.172 0 0 1 0-4.457l4.162-4.162L16.9 46.33a3.152 3.152 0 0 1 4.457-4.457l4.036 4.036 4.162-4.162a3.152 3.152 0 1 1 4.457 4.457l-4.162 4.162 4.33 4.33a3.172 3.172 0 0 1 .001 4.457Zm14.337-4.5a4.253 4.253 0 0 1-4.246-4.2 4.181 4.181 0 0 1 4.162-4.2h.084a4.205 4.205 0 1 1 0 8.409Zm8.24 8.282a4.181 4.181 0 0 1-4.2-4.162v-.084a4.2 4.2 0 1 1 4.2 4.246Zm8.324-8.282a4.253 4.253 0 0 1-4.246-4.2A4.181 4.181 0 0 1 65 46.246h.084a4.205 4.205 0 1 1 0 8.409Z" />
      <path
        data-name="Vector"
        d="m48.938 2.985-.042 3.952a6.892 6.892 0 0 1-6.853 6.769.938.938 0 0 0-1.009.967.951.951 0 0 0 .967.967h-6.769a6.753 6.753 0 0 1 6.727-7.82.974.974 0 0 0 1.051-.967l.038-3.953A2.971 2.971 0 0 1 45.995 0h.042a2.926 2.926 0 0 1 2.901 2.985Z"
      />
    </g>
  </svg>
);

export default Game;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonRpcClient = void 0;
const buffer_1 = require("buffer/");
const types_1 = require("./types");
const accountAddress_1 = require("./types/accountAddress");
const serialization_1 = require("./serialization");
const ccdAmount_1 = require("./types/ccdAmount");
const moduleReference_1 = require("./types/moduleReference");
const util_1 = require("./util");
function transformJsonResponse(jsonString, reviver, transformer) {
    if (transformer) {
        const transformedJson = transformer(jsonString);
        return JSON.parse(transformedJson, reviver);
    }
    return JSON.parse(jsonString, reviver);
}
/**
 * @deprecated This has been deprecated in favor of the {@link ConcordiumNodeClient} that uses version 2 of the concordium gRPC API
 */
class JsonRpcClient {
    constructor(provider) {
        this.provider = provider;
    }
    async getNextAccountNonce(accountAddress) {
        const response = await this.provider.request('getNextAccountNonce', {
            address: accountAddress.address,
        });
        const bigIntPropertyKeys = ['nonce'];
        const res = transformJsonResponse(response, (0, util_1.buildJsonResponseReviver)([], bigIntPropertyKeys), (0, util_1.intToStringTransformer)(bigIntPropertyKeys));
        return res.result;
    }
    async getTransactionStatus(transactionHash) {
        const response = await this.provider.request('getTransactionStatus', {
            transactionHash: transactionHash,
        });
        // TODO avoid code duplication with nodejs client
        const bigIntPropertyKeys = [
            'cost',
            'energyCost',
            'index',
        ];
        const res = transformJsonResponse(response, (0, util_1.buildJsonResponseReviver)([], bigIntPropertyKeys), (0, util_1.intToStringTransformer)(bigIntPropertyKeys));
        return res.result;
    }
    /**
     * @param serializedTransaction the transaction serialized as a base64-encoded string.
     */
    async sendRawTransaction(serializedTransaction) {
        const res = await this.provider.request('sendTransaction', {
            transaction: serializedTransaction,
        });
        return JSON.parse(res).result || false;
    }
    async sendAccountTransaction(accountTransaction, signatures) {
        const serializedAccountTransaction = buffer_1.Buffer.from((0, serialization_1.serializeAccountTransactionForSubmission)(accountTransaction, signatures));
        return this.sendRawTransaction(serializedAccountTransaction.toString('base64'));
    }
    async sendCredentialDeployment(credentialDetails) {
        const serializedDetails = (0, serialization_1.serializeSignedCredentialDeploymentDetailsForSubmission)(credentialDetails);
        return this.sendRawTransaction(serializedDetails.toString('base64'));
    }
    async getConsensusStatus() {
        const response = await this.provider.request('getConsensusStatus');
        // TODO Avoid code duplication with nodejs client
        const datePropertyKeys = [
            'blockLastReceivedTime',
            'blockLastArrivedTime',
            'genesisTime',
            'currentEraGenesisTime',
            'lastFinalizedTime',
        ];
        const bigIntPropertyKeys = [
            'epochDuration',
            'slotDuration',
            'bestBlockHeight',
            'lastFinalizedBlockHeight',
            'finalizationCount',
            'blocksVerifiedCount',
            'blocksReceivedCount',
            'protocolVersion',
        ];
        const res = transformJsonResponse(response, (0, util_1.buildJsonResponseReviver)(datePropertyKeys, bigIntPropertyKeys), (0, util_1.intToStringTransformer)(bigIntPropertyKeys));
        if (!res.result) {
            throw new Error('Nothing was returned when trying to get the consensus status.');
        }
        return res.result;
    }
    /**
     * Retrieve information about a given smart contract instance.
     * @param blockHash the block hash to get the smart contact instances at
     * @param address the address of the smart contract
     * @returns A JSON object with information about the contract instance
     */
    async getInstanceInfo(address, blockHash) {
        if (!blockHash) {
            const consensusStatus = await this.getConsensusStatus();
            blockHash = consensusStatus.lastFinalizedBlock;
        }
        else if (!(0, util_1.isValidHash)(blockHash)) {
            throw new Error('The input was not a valid hash: ' + blockHash);
        }
        const response = await this.provider.request('getInstanceInfo', {
            index: address.index,
            subindex: address.subindex,
            blockHash,
        });
        const result = JSON.parse(response).result;
        if (!result) {
            return undefined;
        }
        // TODO: Avoid code duplication with nodejs client
        const common = {
            amount: new ccdAmount_1.CcdAmount(BigInt(result.amount)),
            sourceModule: new moduleReference_1.ModuleReference(result.sourceModule),
            owner: new accountAddress_1.AccountAddress(result.owner),
            methods: result.methods,
            name: result.name,
        };
        switch (result.version) {
            case 1:
                return {
                    version: 1,
                    ...common,
                };
            case undefined:
            case 0:
                return {
                    version: 0,
                    ...common,
                    model: buffer_1.Buffer.from(result.model, 'hex'),
                };
            default:
                throw new Error('InstanceInfo had unsupported version: ' +
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    result.version);
        }
    }
    /**
     * Retrieves the account info for the given account. If the provided block
     * hash is in a block prior to the finalization of the account, then the account
     * information will not be available.
     * A credential registration id can also be provided, instead of an address. In this case
     * the node will return the account info of the account, which the corresponding credential
     * is (or was) deployed to.
     * @param accountAddress base58 account address (or a credential registration id) to get the account info for
     * @param blockHash the block hash to get the account info at
     * @returns the account info for the provided account address, undefined is the account does not exist
     */
    async getAccountInfo(accountAddress, blockHash) {
        if (!blockHash) {
            const consensusStatus = await this.getConsensusStatus();
            blockHash = consensusStatus.lastFinalizedBlock;
        }
        else if (!(0, util_1.isValidHash)(blockHash)) {
            throw new Error('The input was not a valid hash: ' + blockHash);
        }
        let address;
        if (typeof accountAddress === 'string') {
            address = accountAddress;
        }
        else if ('address' in accountAddress) {
            address = accountAddress.address;
        }
        else if ('credId' in accountAddress) {
            address = accountAddress.credId;
        }
        else {
            throw new Error('Invalid accountAddress input');
        }
        const response = await this.provider.request('getAccountInfo', {
            blockHash,
            address,
        });
        const datePropertyKeys = ['timestamp', 'effectiveTime'];
        const bigIntPropertyKeys = [
            'accountAmount',
            'accountNonce',
            'accountIndex',
            'startIndex',
            'total',
            'amount',
            'stakedAmount',
            'bakerId',
            'newStake',
            'epoch',
        ];
        const res = transformJsonResponse(response, (0, util_1.buildJsonResponseReviver)(datePropertyKeys, bigIntPropertyKeys), (0, util_1.intToStringTransformer)(bigIntPropertyKeys));
        return res.result;
    }
    /**
     * Retrieves the global cryptographic parameters on the blockchain at
     * the provided block.
     * @param blockHash the block to get the cryptographic parameters at
     * @returns the global cryptographic parameters at the given block, or undefined it the block does not exist.
     */
    async getCryptographicParameters(blockHash) {
        if (!blockHash) {
            const consensusStatus = await this.getConsensusStatus();
            blockHash = consensusStatus.lastFinalizedBlock;
        }
        else if (!(0, util_1.isValidHash)(blockHash)) {
            throw new Error('The input was not a valid hash: ' + blockHash);
        }
        const response = await this.provider.request('getCryptographicParameters', {
            blockHash,
        });
        const res = transformJsonResponse(response);
        return res.result;
    }
    /**
     * Retrieves the source of the given module at
     * the provided block.
     * @param moduleReference the module's reference, which is the hex encoded hash of the source.
     * @param blockHash the block to get the cryptographic parameters at
     * @returns the source of the module as raw bytes.
     */
    async getModuleSource(moduleReference, blockHash) {
        if (!blockHash) {
            const consensusStatus = await this.getConsensusStatus();
            blockHash = consensusStatus.lastFinalizedBlock;
        }
        else if (!(0, util_1.isValidHash)(blockHash)) {
            throw new Error('The input was not a valid hash: ' + blockHash);
        }
        const response = await this.provider.request('getModuleSource', {
            moduleReference: moduleReference.moduleRef,
            blockHash,
        });
        return buffer_1.Buffer.from(JSON.parse(response).result, 'base64');
    }
    /**
     * Invokes a smart contract.
     * @param context the collection of details used to invoke the contract. Must include the address of the contract and the method invoked.
     * @param blockHash the block hash at which the contract should be invoked at. The contract is invoked in the state at the end of this block.
     * @returns If the node was able to invoke, then a object describing the outcome is returned.
     * The outcome is determined by the `tag` field, which is either `success` or `failure`.
     * The `usedEnergy` field will always be present, and is the amount of NRG was used during the execution.
     * If the tag is `success`, then an `events` field is present, and it contains the events that would have been generated.
     * If invoking a V1 contract and it produces a return value, it will be present in the `returnValue` field.
     * If the tag is `failure`, then a `reason` field is present, and it contains the reason the update would have been rejected.
     * If either the block does not exist, or then node fails to parse of any of the inputs, then undefined is returned.
     */
    async invokeContract(contractContext, blockHash) {
        if (!blockHash) {
            const consensusStatus = await this.getConsensusStatus();
            blockHash = consensusStatus.lastFinalizedBlock;
        }
        else if (!(0, util_1.isValidHash)(blockHash)) {
            throw new Error('The input was not a valid hash: ' + blockHash);
        }
        const invoker = (0, types_1.buildInvoker)(contractContext.invoker);
        const context = {
            ...contractContext,
            invoker,
            amount: contractContext.amount && contractContext.amount.microCcdAmount,
            parameter: contractContext.parameter &&
                contractContext.parameter.toString('hex'),
        };
        const response = await this.provider.request('invokeContract', {
            blockHash,
            context,
        });
        const bigIntPropertyKeys = ['usedEnergy', 'index', 'subindex'];
        const res = transformJsonResponse(response, (0, util_1.buildJsonResponseReviver)([], bigIntPropertyKeys), (0, util_1.intToStringTransformer)(bigIntPropertyKeys));
        return res.result;
    }
}
exports.JsonRpcClient = JsonRpcClient;

import React from "react";
import { connect } from "react-redux";
import "../assets/styles/Setting-items.css";
import RegisterGamerProfile from "./RegisterGamerProfile";

import Subnavigation from "./Subnavigation";

const SettingsItemsRegisterGamerProfile = ({ navbarSize }) => {
  const location = {
    location: "Post Challange",
    description: "MANA GAMES",
    title: "Set up your gamer profile",
  };
  return (
    <>
      <div
        className={
          navbarSize === "small"
            ? "ST-custom-container2"
            : "ST-custom-container"
        }
      >
        <div className="container-fluid   ST-custom-Heigt all-bg">
          <div className="row">
            <div className="col-md-12  ">
              <div className="ST-top-title-txt-a">{location.description}</div>
              <div className="ST-top-title-txt-b">{location.title}</div>
            </div>
          </div>
          <Subnavigation />
          <RegisterGamerProfile />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return { navbarSize: state.alerts.navbarSize };
};
export default connect(mapStateToProps)(SettingsItemsRegisterGamerProfile);

export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const LOADING = "LOADING";
export const NAVBARSIZE = "NAVBARSIZE";
export const GAMES = "GAMES";
export const GAMES_JOIN = "GAMES_JOIN";
export const CHALLENGES = "CHALLENGES";
export const SEARCH_CHALLENGES = "SEARCH_CHALLENGES";
export const CHALLENGE_CATEGORIES = "CHALLENGE_CATEGORIES";
export const ADDRESS = "ADDRESS";
export const BALANCE = "BALANCE";
export const ACTION_WALLET = "ACTION_WALLET";
export const WALLET_IS_CONNECT = "WALLET_IS_CONNECT";
export const GAMERTAGS = "GAMERTAGS";
// export const WALLET_PROPS = "WALLET_PROPS";
// export const WALLET_TOKEN = "WALLET_TOKEN";
import React from "react";

const HeaderSection = ({ subtitle, title }) => {
  return (
    <div className="row">
      <div className="col-md-12 header-tab-title">
        <h4 className="subtitle">{subtitle}</h4>
        <h2 className="title">{title}</h2>
      </div>
    </div>
  );
};

export default HeaderSection;

import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange, items }) => {
  // Mostrar la paginación solo si hay elementos y más de una página
  if (!items || items.length === 0 || totalPages <= 1) {
    return null;
  }

  return (
    <div className="pagination">
      <button
        className="pagination-button"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 1}
      >
        &lt;
      </button>
      <button
        className="pagination-button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;

import { getData } from "../apis";
import {
    CHALLENGES,
    ERROR,
    GAMES,
    GAMES_JOIN,
    LOADING,
    NAVBARSIZE,
    SEARCH_CHALLENGES,
    SIGN_IN,
    SIGN_OUT,
    CHALLENGE_CATEGORIES,
    SUCCESS,
    BALANCE,
    ADDRESS,
    GAMERTAGS,
    ACTION_WALLET,
    WALLET_IS_CONNECT,
} from "./types";

// AUTHENTICATION AND USER MANAGE
export const signIn = (cognitoId, userEmail) => {
  return async (dispatch) => {
    const response = await getData(`/v1/user/signin/${cognitoId}/${userEmail}`);
    if (response.data.message === "User Not Found") {
      // dispatch({ type: ERROR, payload: 'Hello there, you need a username first!' });
      return;
    }
    dispatch({ type: LOADING, payload: false });
    localStorage.setItem("token", response?.data?.tokens?.access?.token);
    localStorage.setItem(
      "refreshToken",
      response?.data.tokens?.refresh.token
    );
    if (response.success) {
      dispatch({ type: SIGN_IN, payload: response.data });
    } else {
      dispatch({ type: ERROR, payload: response.data });
    }
  };
};
export const getUserInfo = (cognitoId) => {
  return async (dispatch) => {
    const response = await getData(`/v1/user/get/user/${cognitoId}`);

    dispatch({ type: LOADING, payload: false });
    if (response.success) {
      dispatch({ type: SIGN_IN, payload: response.data });
    } else {
      dispatch({ type: ERROR, payload: response.data });
    }
  };
};
export const updateProfile = (payload) => {
    return { type: SIGN_IN, payload: payload };
};
// GAMES
export const getGamesPlayed = () => {
    return async (dispatch) => {
        const response = await getData("/v1/games/get/gamer-tags");
        dispatch({ type: LOADING, payload: false });
        if (response.success) {
            dispatch({ type: GAMERTAGS, payload: response.data });
        } else {
            dispatch({ type: ERROR, payload: response.data });
        }
    };
};
export const getGamesJoin = (page, size) => {
  return async (dispatch) => {
    const response = await getData(`/v1/games/get/all?page=${page}`);
    dispatch({ type: LOADING, payload: false });
    if (response.success) {
      dispatch({ type: GAMES_JOIN, payload: response.data });
    } else {
      dispatch({ type: ERROR, payload: response.data });
    }
  };
};
export const getAllGames = () => {
    return async (dispatch) => {
        const response = await getData("/v1/games/get/all");
        dispatch({ type: LOADING, payload: false });
        if (response.success) {
            dispatch({ type: GAMES, payload: response.data.results });
        } else {
            dispatch({ type: ERROR, payload: response.data });
        }
    };
};
// CHALLENGES
export const getChallenges = (page, size, status = null) => {
    return async (dispatch) => {
        let url =
            status !== null
                ? `/v1/challenge/my-challenges-and-received?page=${page}&size=${size}&status=${status}`
                : `/v1/challenge/my-challenges-and-received?page=${page}&size=${size}`;
        const response = await getData(url);
        dispatch({ type: LOADING, payload: false });
        if (response.success) {
            dispatch({ type: CHALLENGES, payload: response.data });
        } else {
            dispatch({ type: ERROR, payload: response.data });
        }
    };
};
export const getChallengeCategories = () => {
    return async (dispatch) => {
        const response = await getData("/v1/challenge/all/categories");
        dispatch({ type: LOADING, payload: false });
        if (response.success) {
            dispatch({ type: CHALLENGE_CATEGORIES, payload: response.data });
        } else {
            dispatch({ type: ERROR, payload: response.data.message });
        }
    };
};
export const searchChallenges = (search) => {
  return async (dispatch) => {
    const response = await getData(`/v1/challenge/search?name=${search}`);
    dispatch({ type: LOADING, payload: false });
    if (response.success) {
      dispatch({
        type: SEARCH_CHALLENGES,
        payload: response.data.results,
      });
    } else {
      dispatch({ type: ERROR, payload: response.data });
    }
  };
};
// ALERTS, LOADING, NAVBAR
export const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("streamtoken");
    localStorage.removeItem("streamtokenExpired");
    return {
        type: SIGN_OUT,
    };
};
export const setLoading = (show, desc = "") => {
    return { type: LOADING, payload: { show, desc } };
};
export const setError = (error) => {
    return { type: ERROR, payload: error };
};
export const setSuccess = (success) => {
    return { type: SUCCESS, payload: success };
};
export const setNavBarSize = (size) => {
    return { type: NAVBARSIZE, payload: size };
};
// WALLET
export const setBalance = (balance) => {
    return { type: BALANCE, payload: balance };
};
export const setAddress = (address, message) => {
    return { type: ADDRESS, payload: { address, message } };
};
export const actionWallet = (action) => {
    return { type: ACTION_WALLET, payload: action };
};
export const setWalletState = (
    connected,
    connecting,
    address,
    balance,
    connectionActive
) => {
    return {
        type: WALLET_IS_CONNECT,
        payload: { connected, connecting, address, balance, connectionActive },
    };
};
// export const setWalletProps = (props) => {
//   return { type: WALLET_PROPS, payload: props };
// };
// export const setWalletToken = (walletToken) => {
//   return { type: WALLET_TOKEN, payload: walletToken };
// };

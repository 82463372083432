import React, { useEffect, useState } from "react";
import SelectIcon from "../assets/SelectIcon";

const SelectInput = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  options,
  optionValue,
  optionLabel,
  searchByName,
}) => {
  const [currentVal, setCurrentVal] = useState(value);
  useEffect(() => {
    // select the right value base in the label
    if (searchByName) {
      let res = options.filter((item) => item[optionLabel] === value);
      if (res.length > 0) setCurrentVal(res[0]._id);
      return;
    }
    setCurrentVal(value);
  }, [value]);
  return (
    <div className="field-type2-cont">
      <label htmlFor="country">{label}</label>
      <div className="field-type2">
        <select
          id={name}
          value={currentVal}
          onChange={({ target }) => onChange(target.value)}
        >
          <option key={""} value={""}>
            {placeholder}
          </option>
          {options.map((Country, index) => (
            <option key={`name-${index}`} value={Country[optionValue]}>
              {Country[optionLabel]}
            </option>
          ))}
        </select>
        <SelectIcon />
      </div>
    </div>
  );
};

export default SelectInput;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../assets/styles/Settings-friending.css";
import friendService from "../services/friend.service";
import userService from "../services/users";
import SettingsItemFriending from "./items/Settings-item-friending";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError, setLoading, setSuccess } from "../actions";

const Friending = () => {
  const [option, setOption] = useState("online");
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const [friendsOnline, setFriendsOnline] = useState({
    results: [],
    totalResults: 0,
    page: 0,
    totalPages: 0,
  });
  const [allUsers, setAllUsers] = useState({
    results: [],
    totalResults: 0,
    page: 0,
    totalPages: 0,
  });
  const [RequestPending, setRequestPending] = useState({
    results: [],
    totalResults: 0,
    page: 0,
    totalPages: 0,
  });
  const [searchResult, setSearchResults] = useState({
    results: [],
    length: 0,
  });
  const [tabShow, setTabShow] = useState("online");

  let sizeOfPages = 30;
  const searchMyFriend = async (value) => {
    const res = await friendService.searchFriend(value);
    if (res.success) {
      setSearchResults({
        results: res.data,
        length: res.data.length,
      });
    }
  };

  const getFirtsPeople = async () => {
    dispatch(setLoading(true));
    const resAllFriend = await friendService.getAllFriend(1, sizeOfPages);
    const resAllUser = await userService.getAllUser(1, sizeOfPages);
    const resAllRequest = await friendService.getAllRequest(1, sizeOfPages);

    if (resAllFriend.success) {
      setFriendsOnline({
        results: resAllFriend.data.results,
        totalResults: resAllFriend.data.totalResults,
        page: 1,
        totalPages: resAllFriend.data.totalPages,
      });
    }
    if (resAllUser.success) {
      setAllUsers({
        results: resAllUser.data.results,
        totalResults: resAllUser.data.totalResults,
        page: 1,
        totalPages: resAllUser.data.totalPages,
      });
    }
    if (resAllRequest.success) {
      setRequestPending({
        results: resAllRequest.data.results,
        totalResults: resAllRequest.data.totalResults,
        page: 1,
        totalPages: resAllRequest.data.totalPages,
      });
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getFirtsPeople();
  }, []);

  const sendRequest = async (idCognito) => {
    try {
      dispatch(setLoading(true));
      let res = await friendService.sendFriendRequest(idCognito);
      if (res.success) {
        dispatch(setSuccess("Request sent!!!"));
        getFirtsPeople();
      } else {
        dispatch(setError(res.data.message));
      }
    } catch (error) {
      dispatch(setError("Something went wrong. Please try again."));
    }
    dispatch(setLoading(false));
  };
  const accept = async (requestId) => {
    try {
      dispatch(setLoading(true));
      let res = await friendService.acceptRequest(requestId);
      if (res.success) {
        dispatch(setSuccess("Request accepted!!!"));
        getFirtsPeople();
      } else {
        dispatch(setError(res.data.message));
      }
    } catch (error) {
      dispatch(setError("Something went wrong. Please try again."));
    }
  };
  const reject = async (requestId) => {
    try {
      dispatch(setLoading(true));
      const res = await friendService.rejectRequest(requestId);
      if (res.success) {
        dispatch(setSuccess("Request rejected!!!"));
        getFirtsPeople();
      } else {
        dispatch(setError(res.data.message));
      }
    } catch (error) {
      dispatch(setError("Something went wrong. Please try again."));
    }
    dispatch(setLoading(false));
  };

  const cancelSendRequest = async (idCognito) => {
    try {
      dispatch(setLoading(true));
      let res = await friendService.cancelRequest(idCognito);
      if (res.success) {
        dispatch(setSuccess("Request cancelled!!!"));
        getFirtsPeople();
      } else {
        dispatch(setError(res.data.message));
      }
    } catch (error) {
      dispatch(setError("Something went wrong. Please try again."));
    }
    dispatch(setLoading(false));
  };
  const searchAllUser = async (type, value) => {
    const res = await userService.getAllUser(1, 10, type, value);
    if (res.success) {
      setSearchResults({
        results: res.data.results,
        length: res.data.totalResults,
      });
    }
  };

  return (
    <>
      <div>
        <div className="tab">
          <div className="tab-head">
            <ul className="tabTitles">
              <li
                id="tabTitle-1"
                className={`${tabShow === "online" ? "show" : "hidden"}`}
              >
                <a
                  href="#"
                  className={
                    option === "online" ? "itemSelected" : "itemNoSelected"
                  }
                  onClick={() => {
                    setOption("online");
                    setSearchValue("");
                    setSearchResults({ results: [], length: 0 });
                    setTabShow("online");
                  }}
                >
                  My Friends
                </a>
              </li>
              <li
                id="tabTitle-2"
                className={`${tabShow === "all" ? "show" : "hidden"}`}
              >
                <a
                  href="#"
                  className={
                    option === "all" ? "itemSelected" : "itemNoSelected"
                  }
                  onClick={() => {
                    setOption("all");
                    setSearchValue("");
                    setSearchResults({ results: [], length: 0 });
                    setTabShow("all");
                  }}
                >
                  All Users
                </a>
              </li>
              <li
                id="tabTitle-3"
                className={`${tabShow === "pending" ? "show" : "hidden"}`}
              >
                <a
                  className={
                    option === "pending" ? "itemSelected" : "itemNoSelected"
                  }
                  onClick={() => {
                    setOption("pending");
                    setSearchValue("");
                    setSearchResults({ results: [], length: 0 });
                    setTabShow("pending");
                  }}
                >
                  My Friends Pending
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (e.target.value.length > 3) {
                switch (option) {
                  case "online":
                    searchMyFriend(e.target.value);
                    break;
                  case "all":
                    searchAllUser("unKnown", e.target.value);
                    break;
                  case "pending":
                    searchAllUser("pending", e.target.value);
                    break;
                  default:
                    break;
                }
              }
            }}
            value={searchValue}
          />
          <button className="search-button">
            <SearchIcon />
          </button>
        </div>
        <div className="options-total-peoples">
          <a className="title-total-peoples ">
            {option === "online" &&
              "My Friends - " + friendsOnline.totalResults}
            {option === "all" && "All User - " + allUsers.totalResults}
            {option === "pending" &&
              "My Friends Pending - " + RequestPending.totalResults}
          </a>
        </div>
        {searchResult.results.length > 0 && searchValue !== "" ? (
  <ul className="friends-list">
    {searchResult.results.map((user) => {
      const { _id, name, age } = user;

      const handleButtonAClick = () => {
        sendRequest(_id);
      };

      const handleButtonBClick = () => {
        console.log("btnB search");
      };

      return (
        <li key={_id}>
          <SettingsItemFriending
            user={user}
            option={option}
            buttonsFunction={{
              buttonA: handleButtonAClick,
              buttonB: handleButtonBClick,
            }}
          />
        </li>
      );
    })}
  </ul>
) : null}
                
          <ul className="friends-list">
            {tabShow === "online"
              ? friendsOnline.results.map((user, index) => (
                  <li key={index + "_" + option}>
                    <SettingsItemFriending
                      user={user}
                      option={option}
                      buttonsFunction={{
                        buttonA: () => {
                          history.push("/chatpage", { user });
                        },
                        buttonB: () => {
                          console.log("buttonB", friendsOnline);
                        },
                      }}
                    />
                  </li>
                ))
              : tabShow === "all"
              ? allUsers.results.map((user, index) => (
                  <li key={index + "_" + option} className="alll">
                    <SettingsItemFriending
                      user={user}
                      option={option}
                      buttonsFunction={{
                        buttonA: () => {
                          sendRequest(user._id);
                        },
                      }}
                    />
                  </li>
                ))
              : RequestPending.results.map((user, index) => (
                  <li key={index + "_" + option}>
                    <SettingsItemFriending
                      user={user}
                      option={option}
                      buttonsFunction={{
                        buttonA: () => {
                          accept(user._id);
                        },
                        buttonB: () => {
                          if (user.iSent) {
                            cancelSendRequest(user._id);
                          } else {
                            reject(user._id);
                          }
                        },
                      }}
                    />
                  </li>
                ))}
          </ul>
        
      </div>
    </>
  );
};

export default Friending;

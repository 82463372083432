import * as React from "react";

const LiveChat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 24}
    height={props.height ? props.height : 24}
    {...props}
    viewBox="0 0 24 24"
  >
    <g fill="#d20056">
      <path
        data-name="Vector"
        d="M14.38 12A2.38 2.38 0 1 1 12 9.62 2.38 2.38 0 0 1 14.38 12ZM20 18.75a.76.76 0 0 1-.45-.15.75.75 0 0 1-.15-1.05A9.169 9.169 0 0 0 21.25 12a9.169 9.169 0 0 0-1.85-5.55.75.75 0 1 1 1.2-.9A10.624 10.624 0 0 1 22.75 12a10.624 10.624 0 0 1-2.15 6.45.739.739 0 0 1-.6.3ZM4 18.75a.739.739 0 0 1-.6-.3A10.624 10.624 0 0 1 1.25 12 10.624 10.624 0 0 1 3.4 5.55a.75.75 0 1 1 1.2.9 9.25 9.25 0 0 0 0 11.1.75.75 0 0 1-.15 1.05.738.738 0 0 1-.45.15ZM16.8 16.35a.76.76 0 0 1-.45-.15.75.75 0 0 1-.15-1.05A5.168 5.168 0 0 0 17.25 12a5.168 5.168 0 0 0-1.05-3.15.75.75 0 1 1 1.2-.9A6.73 6.73 0 0 1 18.75 12a6.709 6.709 0 0 1-1.35 4.05.739.739 0 0 1-.6.3ZM7.2 16.35a.739.739 0 0 1-.6-.3 6.75 6.75 0 0 1 0-8.1.75.75 0 0 1 1.2.9A5.168 5.168 0 0 0 6.75 12a5.168 5.168 0 0 0 1.05 3.15.75.75 0 0 1-.15 1.05.738.738 0 0 1-.45.15Z"
      />
    </g>
  </svg>
);

export default LiveChat;
